import React, { Component,useState } from 'react';
import { StyleSheet, Text, View, ActivityIndicator, Dimensions } from 'react-native';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';


const Loading =({show,title}:any)=> {
    const [Title,setTitle] = useState(title?title:'正在加载...')
    //console.log(show)
    return (
        show==true?
            <View style={styles.LoadingPage}>
                <View style={{
                        width: 160,
                        height: 140,
                        backgroundColor: "rgba(0,0,0,0.6)",
                        opacity: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius:7
                    }}>
                <Spinner size="lg" color="warning.500" />
                <Text style={{ marginLeft: 10,color:"#FFF",marginTop:10 }}>{Title}</Text>
                </View>
            </View>
        :null
    )
}

export default Loading;
const styles = StyleSheet.create({
    LoadingPage: {
        position: "absolute",
        left: 0,
        top: 0,
        right:0,
        bottom:0,
        backgroundColor: "rgba(0,0,0,0)",
        justifyContent: "center",
        alignItems: "center",
    },
});
