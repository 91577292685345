import * as React from 'react';
import { Linking,
    //Image,
    Dimensions, Text, View,TouchableOpacity } from 'react-native';

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

import {Cell} from '@/Components/Cell.Ad2';

function Index() {
    const width = Dimensions.get('window').width;
    const Ad:any = useSelector((state:any) => state.user.HomeAd2);

    const render = (item:any , index:any) => {
        //console.log(item)
        return(//<Cell key={index}item={item}/>
        <TouchableOpacity key={'renderAD'+index} onPress={() =>Click(item)} style={ {
            marginTop:10,
            alignItems:'center',
            width:'25%',
            justifyContent:'center',
            }}>
            <View style={{width:45,height:45}}>
                <Cell item={item}/>
             </View>
            <Text style={{marginVertical:4,fontSize:13,color:'#000'}}>{item.AdvertTitle}</Text>

        </TouchableOpacity>
        )
    }

    const List = Ad.Data.map((item:any, index:any) => render(item , index))

    
    const Click =  (data:any) => {
        try{
            Linking.openURL(data.AdvertUrl) 
        }catch
        {
        }
    }

    return (
        <View style={{ 
            //flex:1,
            marginTop:6,
            marginBottom:6,
            borderRadius: 8,
            backgroundColor:'#fff',
            flexDirection:'row',
            flexWrap:'wrap',}}>
            {List}
        </View>
    )
}

export default Index;