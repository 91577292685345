import React, {useEffect,useLayoutEffect,useRef,useState} from 'react';
import {Alert,Linking,Button,Image,SafeAreaView,RefreshControl, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as WebBrowser from 'expo-web-browser';
//import Alipay from '@uiw/react-native-alipay';
import Animated from 'react-native-reanimated';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import { showToast } from '@/Components/Toast';
 //api
import {API_Pay,API_Pay_VIP} from '@/API/Index';
import Loading from "@/Components/Loading";
import {isAndRoid,isIOS,isWeb} from "@/Utils/Index";


import Grid from "./Grid";
import Cell_Pay from "./Cell_Pay";
import Cell_Monney from "./Cell_Monney";
import Cell_Modal from "./Modal";


function VIP({navigation,route}:any) {
  const settingInfo:any = useSelector((state:any) => state.user.settingInfo); //后台配置
  const user:any = useSelector((state:any) => state.user.userdata); //后台配置
  const [LoadingShow, setLoadingShow] = useState<any>(false) 
  const [Refreshing,setRefreshing] = useState(false)
  const MonneyRef = useRef<any>()
  const PayRef = useRef<any>()

  const [MonneyList, setMonneyList] = useState([])  
  const [CurMonney, setCurMonney] = useState(null)     
  const [CurMonneyIndex, setCurMonneyCurMonneyIndex] = useState(0)    //初始选项    

  const [PayList, setPayList] = useState([])  
  const [CurPay, setCurPay] = useState(null)                          //支付列表选中项

  const [isShow, setisShow] = useState(false)  
  const [PayObj,setPayObj] = useState<any>(null)


  const loadData = async () => {
    setLoadingShow(true)
    try {
        const data:any = await API_Pay_VIP()
        //console.log(data[0].Data[data[0].RecommandIndex])
        setMonneyList(data[0].Data)
        setCurMonneyCurMonneyIndex(data[0].RecommandIndex)
        setCurMonney(data[0].Data[data[0].RecommandIndex])
        setPayList(data)

    } catch (e) {
        setTimeout(() => {
            loadData()
        }, 3000);
    }
    setLoadingShow(false)
  };

  useEffect(() => {
    loadData()
  },[]);


 const onConfirm = async () => {
  setLoadingShow(true)
  try {
      const the1:any = MonneyRef.current?.selectedData();
      const the2:any = PayRef.current?.selectedData();
      if(the1 ==null) return alert('请选择套餐！');
      if(the2 ==null) return alert('请选择支付通道！');
      const ret:any = await API_Pay(
          {
              Id:user.Id,
              ChannelName:the2.Channel,
              Price:the1.Price,
              PackageId:the1.Id,
              Type:0
          }
      )
      setCurMonney(the1)
      setCurPay(the2)
      setPayObj(ret)
      setisShow(true)
  } catch (e) {
      showToast("error:异常，请更换其它通道重试！")
  }
  setLoadingShow(false)
}
  const GotoPay = async () => {
    if(PayObj.pay_url){
      //const result =  WebBrowser.openBrowserAsync(PayObj.pay_url);
      Linking.openURL(PayObj.pay_url)
      .catch(err =>{
          if(err.message)
          {
              if(err.message.indexOf('alipays://') >=0)
              {
                showToast("支付失败，该手机没有安装支付宝！!")
              }
          }   
      });
    }else if(PayObj.html)
    {
        navigation.navigate("WebView",{title:'支付跳转中..',html:PayObj.html})
    }
    else if(PayObj.weburl)
    {
        navigation.navigate("WebView",{title:'支付跳转中..',url:PayObj.weburl})

    }
    else if(PayObj.alipay)
    {
        //const resule = await Alipay.alipay(PayObj.alipay);
        navigation.navigate("我的")
    }
    else{
      showToast("error:异常，请更换其它通道重试！")
    }
  };

  const renderMonneyList=()=>{
    return(
      <>
        <Grid
          ref={MonneyRef}
          selectedItem={CurMonneyIndex}
          data={MonneyList}
          maxPerRow={1}
          height={60}
          selectedStyle={[
            styles.UnItem,
            styles.InItem
          ]}
          unselectedStyle={styles.UnItem}
          selectedRender={item => (
            <Cell_Monney Item={item} Select={true}/>
          )}
          unselectedRender={item =>(
            <Cell_Monney Item={item} Select={false}/>
          )}

          onSelect={Index => 
            setCurMonney(MonneyList[Index])
          }
        /> 
      </>
    )
  }
  const renderPayList=()=>{
      return(
        <>
        <Grid
          ref={PayRef}
          selectedItem={0}
          data={PayList}
          maxPerRow={2}
          height={60}
          selectedStyle={[
            styles.UnItem,
            styles.InItem
          ]}
          unselectedStyle={styles.UnItem}
          selectedRender={item => (
            <Cell_Pay Item={item} Select={true}/>
          )}
          unselectedRender={item =>(
            <Cell_Pay Item={item} Select={false}/>
          )}

          onSelect={selectedData => 
            setMonneyList(PayList[selectedData].Data)
          }
        />
        </>
      )
  }
  const renderBottom=()=>{
    return(
      <View style={{
        position:'absolute',
        bottom:0,
        zIndex:100,
        width:"100%", 
        }}>
        <View style={{flex:1, backgroundColor:"blue",flexDirection:'row',borderTopWidth:0.3,borderColor:'#ff7500'}}>  
              
          <View style={{backgroundColor:"#fff",flex:0.65,flexDirection:'row',alignItems:'center',justifyContent:'center'}}>  
              {
                CurMonney && CurMonney?.Price ?
                  <View style={{flex:1,flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                  <Text style={{color:'#000',fontSize:18}}>充值金额</Text>  
                  <Text> </Text>  
                  <Text style={{fontWeight:'bold',fontSize: 18,color:'#ff7500'}}>¥{CurMonney?.Price}</Text> 
                  <Text> </Text> 
                
                  </View> 
                  :null
              }
          </View> 

          <TouchableOpacity
              activeOpacity={1}
              onPress={() => onConfirm()}
              style={{backgroundColor:"#ff7500",flex:0.35,height:56,flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
          
              
                  <Text style={{color:'#fff',fontSize:18}}>确认支付</Text>  
              
          </TouchableOpacity>   
        </View>
      </View> 
    )
     
  }

  return (
    <View style={{flex:1}}>
      {
        PayList.length>0 &&(
          <View style={{position:'absolute',left:0,top:0, right:0,bottom:0}}>
          <ScrollView style={{flex:1,marginHorizontal:10}}>
            {renderMonneyList()}
            {renderPayList()}
            <Text style={{paddingTop:100}}></Text>
          </ScrollView>
          </View>
        )
      }
       {renderBottom()}
      <Cell_Modal Item={CurMonney} isShow={isShow} setisShow={setisShow} onConfirm={GotoPay}/>
      <Loading show={LoadingShow} />
    </View>
  );
}

export default VIP;
const styles = StyleSheet.create(
    {
      UnItem:{
        marginTop:12,
        backgroundColor:'#fff',
         //让安卓拥有灰色阴影
        shadowOffset: {width: 0, height: 5},
        shadowOpacity: 1.5,
        shadowRadius: 5,
        shadowColor:'#ddd',
        borderRadius:8,
        margin:2
      },
      InItem:{
        elevation: 4,
        borderWidth:1,
        borderColor:'red'

      }
    }
  
)