import React, {useEffect,useState,useContext} from 'react';
import {Zocial,Ionicons,Entypo,Fontisto,FontAwesome } from '@expo/vector-icons';

import { Button, Text,
  //Image, 
  TextInput, View } from 'react-native';
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useColorScheme } from "react-native";
import {Image} from 'expo-image'

import Main from "../src/View/Main/Index";
import Special from '../src/View/Special/Index';
import ShortVideo from '../src/View/ShortVideo/index';
import Me from '../src/View/Me/Index';
import Pay from '../src/View/newPay/VIP';


const BottomTab = createBottomTabNavigator();

function PayScreen({ navigation }) {
  useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', (e) => {
      e.preventDefault();
    });
  return unsubscribe;
}, [navigation]);
}

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const [IsShortVideo, setIsShortVideo] = useState(false);
  const Colors = '#ff7500'

  const PostMessage = (Msg) =>{
    if(window.ReactNativeWebView){
      window.ReactNativeWebView.postMessage(JSON.stringify({route:Msg}));
    }
  }
  return (

    <BottomTab.Navigator
      initialRouteName="首页"
      screenOptions={{ 
        headerTitleAlign:'center',
        tabBarLabelStyle: {
          fontSize: 13,
        },
        tabBarActiveTintColor:Colors //Colors[colorScheme].tint 
      }}
    >
      <BottomTab.Screen
        name="首页"
        component={Main}
        options={{
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon1 name="home" color={color} />,
        }}
        listeners={({ navigation, route }) => ({
          focus: () =>  PostMessage("首页"),
        })}

      />
      <BottomTab.Screen
        name="专题"
        component={Special}
        listeners={({ navigation, route }) => ({
          focus: () =>  PostMessage("专题"),
        })}
        options={{
          headerShown: true,
          tabBarIcon: ({ color }) => (
            <TabBarIcon3 name="buffer" color={color} />
          ),
        }}
      />
      
      <BottomTab.Screen
        name="充值"
        component={Pay}
        listeners={({ navigation, route }) => ({
          tabPress: (e) => {
            PostMessage("充值")
            e.preventDefault();
            navigation.navigate("Pay",{title:"vip"})
          },
        })}
        options={{
          //headerShown: false,
          tabBarIcon: ({ color }) => 
          <View style={{marginBottom:14}}>
          <Image
            recyclingKey={'fuc'}
              style={{
                borderRadius:30,width: 40, height: 40
               
              }}
              source={require('../assets/icon/icons8-us-dollar-circled.gif')}
              contentFit='fill'
          />
          </View>
        }}

      />

      
      <BottomTab.Screen
        name="ShortVideo"
        component={ShortVideo}
        listeners={({ navigation, route }) => ({
          focus: () =>  PostMessage("短视频"),
        })}
        options={{
          
          tabBarLabel: '短视频',
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon2 name="video-camera" color={color} />,
        }}
      />
      <BottomTab.Screen
        name="Me"
        component={Me}
        listeners={({ navigation, route }) => ({
          focus: () =>  PostMessage("我的"),
        })}
        options={{
          title: '我的',
          tabBarIcon: ({ color }) => <TabBarIcon2 name="user-circle" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon1(props) {
  return <Ionicons size={26} style={{  }} {...props} />;
}
function TabBarIcon2(props) {
  return <FontAwesome size={26} style={{  }} {...props} />;
}
function TabBarIcon3(props) {
  return <Zocial size={26} style={{  }} {...props} />;
}
function TabBarIcon4(props) {
  return <Entypo size={26} style={{  }} {...props} />;
}
