 import React, {useEffect,useState,useContext} from 'react';
 import { TouchableOpacity, Text, View } from 'react-native';
 import { Store } from '../../../Utils/store';
 import List from './List'

 export default function({navigation, route}) {
    //const navigation1 = useNavigation();
    const [CurTab,setCurTab] = useState('');
    const {removeVideoAllFollow} = useContext(Store);
    //console.log(VideoHistoryList)
    const _onPress = () => {
        removeVideoAllFollow();
    }

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerBackTitleVisible:false,
            title:"我的收藏",
            headerRight: () => (
                <TouchableOpacity onPress={() =>_onPress()} style={{marginTop:6, marginRight:18}}>
                    <Text style={{color:'#000'}}>全部删除</Text>
                </TouchableOpacity>
              ),
        });
    }, []);
    
    
    return (<List navigation={navigation}/>)
 }
