import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View,
  Platform 
} from 'react-native';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';

import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

import {DnsServerList} from '../../Utils/Dns';

import {updateServerTime,updateToken, updateNotific,updateSearchInfo ,updateUser,updateSettingInfo,updateRunAd, updateLoginStatus,updateAdvertInfo,updateNavigatorListInfo, updateServerIP, updateDnsServer} from '../../../src/Actions/userActions';


import Info from './Info';
import AES from '../../Utils/Aes';
import { setToken,setUser,setApiBase,getUser_UUID } from '../../../src/Utils/storage';
import { isAndRoid,isWeb,isIOS,GetUID,SetUID,GetUniqueId} from '../../../src/Utils/Index';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';

import {Image} from 'expo-image'
import Constants from 'expo-constants';
import * as Clipboard from 'expo-clipboard';
import * as Device from 'expo-device';

//api
import {API_User_Login} from '../../../src/API/Index'
import { osName,getUA,browserName,BrowserView, MobileView, isBrowser, isMobile,isChrome,isAndroid,isIOS as ISIOS,isSafari,isMacOs,isMobileSafari,isMobileOnly } from 'react-device-detect';



const LinkSleep = 200;

export default function (){
  const Config:any = Constants;
  const [SplashImage,setSplashImage] = useState(require('../../../assets/images/splash.png'))
  const dispatch = useDispatch();
  const { getItem, setItem } = useAsyncStorage('@storage_settingInfos');
  const [CustomerServiceUrl,setCustomerServiceUrl]= useState(null)

  const List:any = useSelector((state:any) => state.user.ServerIP);

  
  const ListView = List.map((data:any,index:any) =>{
  //console.log(1111, DnsServerList)
  return <View key={index}  style={{
    margin:4
  }}>
      <Info data={data} index={index}/>
    </View>
  })

    
  const GetCustomerServiceUrl= async()=>{
    const ret:any = await getItem()
    const settingInfo:any = JSON.parse(ret);
    if(settingInfo && settingInfo.CustomerServiceUrl){
      setCustomerServiceUrl(settingInfo.CustomerServiceUrl)
    }
  }

  const GetClipboard = async () =>{
    try {
      return await Clipboard.getStringAsync();
    } catch (error) {
      return ""
    }
  }

  const GetIsIOS =() =>{
    if(isWeb){
      if(window.navigator.standalone == true){
        return "ios"
      }
    }
  }
  function setURLParameter(key:any, value:any) {
    // 创建URL对象
    const searchParams = new URLSearchParams(window.location.search);
   
    // 设置参数
    searchParams.set(key, value);
   
    // 创建新的URL对象
    const newUrl = new URL(window.location.href);
   
    // 替换或添加查询字符串
    newUrl.search = searchParams.toString();
   
    // 返回新的URL字符串
    return newUrl.toString();
  }
  const setUserParameter = (key:any, value:any) => {
    if(isWeb){
      const url = setURLParameter(key, value);
      window.history.pushState({path:url},'',url)
    }
  }

  const GetUserParameter =()=>{
    if(isWeb){
      const searchParams = new URLSearchParams(window.location.search);
      const param = searchParams.get('UserId');
      return param;
    }
  }

  const doLogin = async () =>{
    try {
      if(!CustomerServiceUrl){
        GetCustomerServiceUrl()
      }  
      //console.log('CustomerServiceUrl:',CustomerServiceUrl)
      //console.log('进入服务器')
      let BindType=''
      let extensionCode=''
      let text:any = await GetClipboard();; 


      let parts = text.split("=")
      if(parts.length ==2){
        if(parts[0]=="a" || parts[0]=="u"||parts[0]=="bind"){
          BindType=parts[0]
          extensionCode= parts[1]
        }
       
      }

      let uid = await GetUniqueId() 
      if(uid == null || uid == "unknown"){
        uid = await GetUID() //Crypto.randomUUID();
      }

      const MemUUid = await getUser_UUID()
      //console.log('MemUUid:',MemUUid)
      if(MemUUid){
        //alert(MemUUid.MechineCode)
      }
   
      if(MemUUid){
        uid = MemUUid.MechineCode
      }

      //return
  
      if(BindType == "bind" && extensionCode != ""){
        uid = extensionCode;
      }

      if(Config.expoConfig.extra.AgentId != "0"){
        BindType="a"
        extensionCode=Config.expoConfig.extra.AgentId
      }
      //console.log(uid)
      //alert( GetIsIOS())
      //alert(JSON.stringify(Device))
      //alert( osName + "/" + getUA + "/" + browserName + "/" + isBrowser+ "/" +isMobile+ "/" +isChrome+ "/" +isAndroid +"/" +ISIOS)
      const data:any =  await API_User_Login({
        mechineCode:uid,//DeviceInfo.getUniqueId(),
        osName: Platform.OS == "web" ? browserName : Device.osName,
        platform: GetIsIOS() || Platform.OS,
        BindType:BindType,
        extensionCode:extensionCode,
        UserId:GetUserParameter()
        })
        console.log(data)
        //设置用户到浏览器地址栏参数
        setUserParameter("UserId",data.userInfo.Id)
        let NavigatorList:any = []
        data.NavigatorList.forEach((element:any) => {
          //console.log(element.Area,element.Area != '专题')
          if(element.Area != '专题'){
            element.key = element.Area
            element.title = element.Area
            NavigatorList.push(element)
          }
          //console.log(element)
        })
        var User = JSON.stringify(data.userInfo);
        setUser(User)
        SetUID(data.userInfo.MechineCode)
        setToken(data.Token)
        updateToken(data.Token)
        //console.log()
        dispatch(updateServerTime(data.ServerTime.toString()))//服务器时间
        
        dispatch(updateUser(data.userInfo));
        dispatch(updateSearchInfo(data.searchInfos));  //搜索列表
        dispatch(updateSettingInfo(data.settingInfos));//后台设置
        setItem( JSON.stringify(data.settingInfos))
        dispatch(updateNotific(data.noticeInfos))
        dispatch(updateNavigatorListInfo(NavigatorList))

        let JumpRunAd = true;
        data.advertInfos.forEach((element:any) => {
          switch (element.Title) {
            case '启动页':
              dispatch(updateAdvertInfo(element,1))
              JumpRunAd = false
              break;
            case '首页':
              dispatch(updateAdvertInfo(element,2))
              break;
            case '首页-横幅':
              dispatch(updateAdvertInfo(element,22))
              break;
            case '播放器':
              dispatch(updateAdvertInfo(element,7))
              break;
            case '短视频':
              dispatch(updateAdvertInfo(element,8))
              break;
            case '专题':
              dispatch(updateAdvertInfo(element,12))
              break;
          } 
        }); 

        if(JumpRunAd){//跳过启动页广告
          dispatch(updateLoginStatus(3));
        }else{
          dispatch(updateLoginStatus(2));
        }
        
        return
      
    } catch (error) {
      dispatch(updateLoginStatus(0));
        //console.log("error:",error)
        // setErrorCount(ErrorCount+1)
        // setIndex(0)
        // SetState(0)
    }
  }
  useEffect(() => {
    //console.log(List)
    let count = 0
    let ipaddr = null;
    let netms = 99999;

    List.map((data:any,index:any)=>{
      if(data.Link){
        count = count + 1
      }
      if(data.State = 2 && data.Time > 0 && data.Time <= netms){
        netms = data.Time;
        ipaddr = data.Addr
      }
    })
    if(count >= List.length)
      {
        if(ipaddr){
          //ipaddr= "http://10.10.10.101:5000"
          console.log(new Date(),"线路:",ipaddr,netms)
          
          setApiBase(ipaddr)
          setTimeout(() => {
            doLogin()
          }, LinkSleep);
         
        }else{
          
          setTimeout(() => {
            dispatch(updateLoginStatus(0));
          }, 3000);

        }
      }

  }, [List])


    return (
      <View style={{flex:1,
        //backgroundColor:'#ff7500'
        }}>
          <Image
            recyclingKey={"RunImage"}
              style={{
                flex:1,
                width: null as any,
                height: null as any,
              }}
            source={SplashImage}
            contentFit='cover'
          />
           <View style={{position:'absolute',left:0,right:0,top:0,bottom:0,justifyContent:'center',alignItems:'center',}}>
            <View style={{backgroundColor:'rgba(0,0,0,0.6)',padding:22,borderRadius:8}}>
               <Spinner style={{marginBottom:12}} size="lg" color="#fff" />
               {ListView}
            </View>
        </View>
      </View>
    );
}

const styles = StyleSheet.create({
  container: {
    marginTop:10,
    flexDirection:'column',
  },
});
