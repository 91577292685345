import React, {useEffect,useState,useCallback,useRef} from 'react';
import {PermissionsAndroid, Platform,Image,RefreshControl,ActivityIndicator,Dimensions,Button,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity, ImageBackground, Alert} from 'react-native';
import Shadow from './Shadow';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import * as Clipboard from 'expo-clipboard';
import { showToast } from '@/Components/Toast';

export default function ({onPress}:any) {
    const user:any = useSelector((state:any) => state.user.userdata); //后台配置
    const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);  //后台配置
    console.log(settingInfo,user)
    const CopyUrl= async () => {
        settingInfo.ShareUrl + user.ExtensionCode

    }
    async function Copy() {
        const str = settingInfo.ShareContent + settingInfo.ShareUrl + user.ExtensionCode
        await Clipboard.setStringAsync(str)
        showToast("复制成功!")
      }

    return (
        <View style={{
            alignItems:'center',
            justifyContent:'center',
            }}>
            
            <View style={{flexDirection:'row'}}>
            <Shadow>
            <TouchableOpacity onPress={() => {
                    Copy();
                }}
                style={{
                    backgroundColor:'red',
                    borderRadius:20,
                }}>
                    <Text style={{marginHorizontal:16,marginVertical:8,color:'#fff',fontWeight:'bold',fontSize:18}}>复制链接分享</Text>
            </TouchableOpacity>
            </Shadow>
            <Shadow>
            <TouchableOpacity onPress={() => {
                   onPress?.();
                }}
                style={{
                    //marginLeft:20,
                    backgroundColor:'#ff7500',
                    borderRadius:20,
                    //marginBottom:12
                }}>
                    <Text style={{marginHorizontal:16,marginVertical:8,color:'#fff',fontWeight:'bold',fontSize:18}}>{Platform.OS == 'web' ? '截图发送给朋友' : '保存图片分享'} </Text>
            </TouchableOpacity>
            </Shadow>
            </View>

            <View style={{marginTop:20,height:40,
                //backgroundColor:'#fff', 
                flexDirection:'row',justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                <Text style={{fontSize:16, fontWeight:'bold'}}>邀请1人下载注册，送1天</Text>
                <Text style={{marginBottom:2, fontSize:22,fontWeight:'bold', color:'red',textAlign:'center'}}>VIP会员</Text>
            </View>
            
            <View style={{height:30,
                //backgroundColor:'#fff', 
                flexDirection:'row',justifyContent:'center',alignItems:'center',alignSelf:'center'}}>
                <Text style={{fontSize:16,fontWeight:'bold'}}>可</Text>
                <Text style={{marginBottom:2, fontSize:22,fontWeight:'bold', color:'red',textAlign:'center'}}>无限叠加</Text>
                <Text style={{fontSize:16,fontWeight:'bold'}}>哦～</Text>
            </View>
            
        </View>
    )
}

