import React, {useEffect} from 'react';
import {TouchableWithoutFeedback,Image,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';


function Cell_Pay({Item,Select, navigation,route}:any) {

    const PayImage = (Item.Title.indexOf("支付宝") >=0) ? require("./imgs/app_topup_img_alipay.png") : (Item.Title.indexOf("微信") >=0 ? require("./imgs/app_topup_img_wechat.png") : require("./imgs/icon_me_service.png")) 

    return(
        <View style={{width:"100%",height:"100%", flexDirection:'row',alignItems:'center'}}>
          
            <View style={{
                //position:'absolute',
                    left:16,
                    flexDirection:'row',
                    alignItems:'center'
                    }}>
                <Image source={PayImage} style={{
                    
                    width:24,
                    height:24,
                    //marginLeft:6,
                    borderRadius:12,
                }}/>
                <Text numberOfLines={1} style={{marginLeft:8, color:'#000',fontWeight:'bold',fontSize:12}}>{Item.Title}</Text>
            </View>

            
        </View>
    )
}

export default Cell_Pay;
