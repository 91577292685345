import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View,
  Platform,
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control'
import { StackActions } from '@react-navigation/native';

import {updateVideo} from '@/Actions/userActions';
import {Recycler} from '@/Components/Recycler/Index';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';
//api
import {API_Video_GetRandList} from '@/API/Index';
 //Redux
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

import Info from './Info'
import {Cell} from '@/Components/Cell'
import { viewHeight } from '@/Utils/Index';



export default function ({navigation}:any){
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [IsEnd, setIsEnd] = useState(false);
  const [IsRestAPI, setIsRestAPI] = useState(false);
  const AdList = useSelector((state:any) => state.user.PlayerAd);


  const fetchData = async () => {
    try {
        const result:any = await API_Video_GetRandList({Page:page,Count:20})
        if(page==1){
            const Init:any =[{Type:"HEADER"}]
            setData(Init.concat(result));
        }else{
            setData(data.concat(result));
        }
        if(result.length <= 0){
          setIsEnd(true)
        }
        
    } catch (error) {
        setTimeout(() => {
            setIsRestAPI(true)
        }, 5000);
    }
  };

  const renderHeader = () => {
    return (
      <View style={{flex:1, marginTop:10}}>
        <Info/>
      </View>
    );
  };

  useEffect(() => {
    fetchData()
  }, [page])


  useEffect(() => {
    if(IsRestAPI){
      setIsRestAPI(false)
      fetchData()
    }
  }, [IsRestAPI])

  const Next =()=>{
    if(!IsEnd){
      setPage(page+1)
    }
  }

  const onPress = (data:any) => {
    dispatch(updateVideo(data))
    navigation.replace("Player",{Data: data})
  }

  const items = useMemo(() => {
    return data;
  }, [page,data]);

  const reloadLines = React.useCallback(() => {
    setRefreshing(true)
    setIsEnd(false)
    setData([])
    setPage(1)
    setTimeout(() => {
      fetchData()
      setRefreshing(false)
  }, 10);
    
  }, [])

  const renderItem=({data}:any)=>{
    // <Text>1</Text>
     return <Cell item={data} Click={onPress} />
  }

  const renderFooter = () => {
    //console.log('IsEnd:',IsEnd)
    if(IsEnd==true){
      return(
        <View style={{alignItems:'center',marginBottom:30}}>
          <Text style={{margin:10}}>到底部啦！</Text>
        </View>
      )
    }
      return (<Spinner size="lg" color="warning.500" />);
    };

  return (
    <View style={{
      //height:viewHeight
      backgroundColor:'#fff',
      paddingBottom: Platform.OS !== 'web' ? 10 : 0,
      // web端需要减去标题高度
      height: Platform.OS === 'web' ? viewHeight - 42 : viewHeight,
    }}>
      {/* <StatusBar style="auto" /> */}
      {
        items.length>0?
        <Recycler items={items} Next={Next} Click={onPress}
        numColumns={2}
        headerHeight={AdList.Data ? 200:70}
        itemHeight={160}
        renderHeader={renderHeader}
        renderItem={renderItem}
        renderFooter={renderFooter}
        gap={6}
        scrollViewProps={{
          refreshControl: (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                reloadLines()
              }}
            />
          )
        }}
         />
        :null
      }
    </View>
  );
}
