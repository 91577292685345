import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View 
} from 'react-native';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';

import {API_User_GetUserData,API_GetServerTime,API_User_GetServerTime,API_GetDnsServerInfo} from '../../API/Index';
import {addDnsServer,updateDnsServer} from '../../Actions/userActions';

//api
//import {API_Video_GetAreaVideo} from '@/API/Index';
 //Redux
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import axios from 'axios';



export default function ({data,index}:any){
  const dispatch = useDispatch();
  const [Data, setData] = useState(data);
  const [Error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);

  const fetchData = async (tmp:any) => {
    try {
        setLoading(true)
       
        const startTime = new Date().getTime()      
        const ret = await axios.get(tmp.url +'&timestamp='+Date.now(),{
          timeout:1000,
          headers: {
          //'Cache-Control': 'no-cache'
          }
        })
        //const ret = await API_GetDnsServerInfo(tmp.url+'&timestamp='+Date.now())
        const EndTime = new Date().getTime() - startTime     
        tmp.data =(ret.data.Answer)
        tmp.link = true
        tmp.Time = EndTime
        //Add(tmp)
        dispatch(addDnsServer(tmp));
        //console.log(tmp)

    } catch (error) {
        tmp.link = true
        setError(true)
        dispatch(addDnsServer(tmp));
    }
    setLoading(false)
  };



  useEffect(() => {
    fetchData(data)
  }, [])


  return (
    <View style={styles.container}>
        {
            Data?
            <>
            <Text style={styles.txt}>线路{index+1} ----> </Text>
            {
            Loading ?

            <Spinner style={{marginLeft:4}}  color="#fff" />
            :
                Error == true?
                <Text style={styles.txt}> 异常 </Text>
                : <Text style={styles.txt}> 正常{Data.Time}Ms</Text>
            }
            </>
            :null
        }
        
          
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection:'row'
  },
  txt:{
    fontSize:13,
    fontWeight:'bold',
    color:'#fff'
  }
});
