import { useState,useEffect } from 'react';
import { Alert,Button, Image as RnImage, View, StyleSheet, Pressable,Text } from 'react-native';
import { useAsyncStorage } from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Modal } from 'native-base';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import AES from '../../../Utils/Aes';
import {useDispatch} from 'react-redux';
import {updateLoginStatus} from '../../../Actions/userActions';
import jsQR from 'jsqr';
import {API_User_RetrieveAccount} from '../../../API/Index';


export default function ({ navigation,route}:any) {
  const [image, setImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [OCR_Image, setOCR_Image] = useState(false);
  const { getItem, setItem } = useAsyncStorage('@User_UUID');
  const dispatch = useDispatch();

  const readQRCode = (imageUrl:any) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context:any = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        const imageData = context.getImageData(0, 0, img.width, img.height);
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          resolve(code.data);
        } else {
          reject(new Error("Unable to read the QR code in the image."));
        }
      };
      img.onerror = () => {
        reject(new Error("Failed to load image."));
      };
      img.src = imageUrl;
    });
  };

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result:any = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64:true
    });
    setOCR_Image(true)
    //alert(result)
    //console.log(result.assets[0]);
    //alert(result.assets[0].uri);
    if (!result.canceled) {
      setImage(result.assets[0].uri);
      
      //let r = await BarCodeScanner.scanFromURLAsync(result.assets[0].uri)
      //alert("r:"+r)
      //console.log('rrr:',r)
    }
  };

  useEffect(() => {
    if(image){
      
      const ImageInit = async () => {
        try {
          const qrCodeData:any = await readQRCode(image);
          const ret:any = AES.Decrypt(qrCodeData)
          const data = JSON.parse(ret)
          if(data.Id){
            const TheUser = await API_User_RetrieveAccount(data)
            console.log('TheUser:',TheUser)
             //alert(qrCodeData)
            console.log('qrCodeData:',data)
            setItem(qrCodeData)
            console.log(qrCodeData)
            setOCR_Image(false)
            alert('恭喜成功找回账号！')
            setTimeout(() => {
              window.location.reload();
            }, 200);
            //window.location.reload();


          }
         
        } catch (error) {
          setOCR_Image(false)
          alert('找回失败：\n'+error)
          navigation.goBack()

        }
      };
      ImageInit();
    }
  }, [image]);


  const onScanSuccess = async (qrCodeData:any)=>{
    try {
      setModalVisible(false)
      const ret:any = AES.Decrypt(qrCodeData)
      const data = JSON.parse(ret)
      if(data.Id){
        const TheUser = await API_User_RetrieveAccount(data)
        console.log('TheUser:',TheUser)
          //alert(qrCodeData)
        console.log('qrCodeData:',data)
        setItem(qrCodeData)
        console.log(qrCodeData)
        setOCR_Image(false)
        alert('恭喜成功找回账号！')
        setTimeout(() => {
          window.location.reload();
        }, 200);
        //window.location.reload();


          }


      //const ret:any = AES.Decrypt(data)
      //alert(123)
      //alert(data)
      // setItem(data)
      // alert('恭喜成功找回账号！')
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      
      //dispatch(updateLoginStatus(0))//刷新，重新进APP
    } catch (error) {
        setModalVisible(false)
        alert('找回失败：\n'+error)
        navigation.goBack()
    }
   
  }

  return (
    <View style={styles.container}>


        <Pressable onPress={()=>pickImage()}
          style={{
            //marginTop:30,
            backgroundColor:'#ff7500',
            shadowOpacity: 0.3,
            shadowRadius: 5,
            shadowColor:'#000',
            //让安卓拥有灰色阴影
            elevation: 4,
            //height:30,
            borderRadius:10,justifyContent: 'center',alignItems: 'center',}}>
            <Text style={{margin:10, color:'#fff',fontSize:14,fontWeight:'bold'}}>相册找回</Text>
        </Pressable>

        <Pressable onPress={()=>setModalVisible(true)}
          style={{
            marginTop:20,
            backgroundColor:'#ff7500',
            shadowOpacity: 0.3,
            shadowRadius: 5,
            shadowColor:'#000',
            //让安卓拥有灰色阴影
            elevation: 4,
            //height:30,
            borderRadius:10,justifyContent: 'center',alignItems: 'center',}}>
            <Text style={{margin:10,color:'#fff',fontSize:14,fontWeight:'bold'}}>扫一扫二维码</Text>
        </Pressable>
      {image && <RnImage source={{ uri: image }} style={styles.image} />}
      {OCR_Image && <Text style={{fontSize:13,color:'red'}}>图片识别中...</Text>}
     
      <Modal isOpen={modalVisible} >
        {/* <Html5QrcodePlugin/> */}
        {/* <View style={{position:'absolute',left:0,top:0,right:0,bottom:0}}> */}
        <View style={{position:'absolute',width:'100%',height:'100%'}}>
        <Html5QrcodePlugin
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          onScanSuccess={onScanSuccess}
        />
         </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
   
  },
  button: {
    marginBottom:8,
    borderRadius:10,
    backgroundColor:'red',
    paddingHorizontal:10,
    paddingVertical:8
    
    //width: 200,
    //height: 200,
  },
  image: {
    width: 200,
    height: 200,
  },
  root: {
    backgroundColor: 'white',
    borderRadius: 16,
    overflow: 'hidden',
    alignItems:'center',
    justifyContent:'center'
  },

});
