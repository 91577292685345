import * as React from 'react';
import { Linking,
    //Image,
    Dimensions, Text, View,TouchableOpacity } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {Image} from 'expo-image'
//import { CachedImage} from '@/Components/Image-Cache';

import {Cell} from '@/Components/Cell.Ad';

//import Toast from "../../../../common/Toast";

function Index() {
    const width = Dimensions.get('window').width;
    const Ad = useSelector((state) => state.user.HomeAd);

    //console.log("Ad:",Ad)
    
    const Click =  (data) => {
        try{
            Linking.openURL(data.AdvertUrl) 
            //Toast.add('复制成功！')
        }catch
        {
            //Toast.add('未知错误：失败！')
        }
    }
    return (
        <View style={{ flex: 1 ,
        //backgroundColor:'red'
        marginBottom:4,
        }}>
            <Carousel
                loop
                width={width-20}
                //height={width / 2}
                //style={{ width: '100%', height: 160 }}
                autoPlay={true}
                //data={[...new Array(6).keys()]}
                data={Ad.Data}
                autoPlayInterval={Ad.Space*1000}
                scrollAnimationDuration={1000}
                //onSnapToItem={(index) => console.log('current index:', index)}
                renderItem={({ item,index }) => (
                    // console.log(item)
                    <TouchableOpacity 
                        activeOpacity={1}
                        onPress={() =>Click(item)}
                        style={{
                            flex: 1,
                            //borderWidth: 0.5,
                            justifyContent: 'center',
                        }}
                        >
                        <Cell
                            item={item}
                        />
                   
                        {/* <Text style={{ textAlign: 'center', fontSize: 30 }}>
                            {index}
                        </Text> */}
                    </TouchableOpacity>
                )}
            />
        </View>
    );
}

export default Index;