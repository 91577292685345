import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { View ,Text,TouchableOpacity} from 'react-native';

import { AntDesign,MaterialIcons } from '@expo/vector-icons';
const propTypes = {
  player: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.string,
};

const defaultProps = {
  position: 'left'
};

export default class RenderIsVIP extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.onPress()
  }

  render() {
    const {data, isShow , onPress,player, className, position} = this.props;

    if (!isShow) return null

    return (
      <a
        ref={c => {
          this.button = c;
        }}
        className={classNames(
          className,
        )}

        type="button"
        aria-live="polite"
        onClick={this.handleClick}
        style={{
          position:'absolute',
          right:16,
          top:16,
          backgroundColor:'#ff7500',
          borderRadius:16,
          alignItems:'center',justifyContent:'center',
          paddingLeft:10,
          paddingRight:10,
        }}
        tabIndex="0"
      >
         <h3 >{data.Type== 0 ? '开通会员观看完整版' : '支付金币观看完整版'} </h3>
      </a>
    );
  }
}

RenderIsVIP.propTypes = propTypes;
RenderIsVIP.defaultProps = defaultProps;