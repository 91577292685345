import React,{useEffect, useState} from 'react';
import {
  Image as RnImage,
  Pressable,
  TouchableOpacity,
  StyleProp, ViewStyle,
   View, Text
  }
  from 'react-native';

import axios from 'axios';
import { CachedImage} from './Image-Cache';
import { Image } from 'expo-image'
import { Buffer } from 'buffer'
import {useSelector} from 'react-redux';

function toBase64(input:any) {
  return Buffer.from(input, 'utf-8').toString('base64')
}




export const Cell = ({item,Click}:any) => {
  const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);     //后台配置

  const DecryptIMG =(array:any)=>{
    const bytes = new Uint8Array(array);
    for (let index = 0; index < 100; index++) {
      bytes[index] = bytes[index] ^ (settingInfo.DecryptKey || 968542557)
    }
    return bytes
  }
  
  const [ImageSource ,setImageSource]:any = useState(null);

  let TypeTxt = ''
  if(item.Type == 0){
    TypeTxt="VIP"
  }else if(item.Type == 1){
    TypeTxt = item.Monney
  }else{
    TypeTxt="免费"
  }

  function getExtension(filename:any) {
    return filename.split('.').pop()
  }

  const ImageDecrypt = async (source:any)=> {
    if(source ){
      try {
        
        const ext = getExtension(source)
        if(ext == 'web' || ext == 'ceb'){
          const response = await axios.get(source,{responseType:'arraybuffer'})
          const data = DecryptIMG(response.data)
          const path = 'data:image/jpeg;base64,'+toBase64(data)
          if (path) {
            item.VideoCover= path
            setImageSource(path)
            return
          }
        }else{
          return
        }
      } catch (error) {
      }
    }

  }
  useEffect(() => {
    //console.log(item)
    if(item.VideoCover.indexOf("data:image/jpeg;base64")>-1){
      setImageSource(item.VideoCover)
    }else{
      ImageDecrypt(item.VideoCover)
    }
    
  }, [item])

  //console.log(ImageSource)
  


  return (
    <Pressable
      onPress={()=>Click(item)}
      style={{
        flex: 1,
      }}>
        <View style={{flex:1,borderRadius:8,borderColor:'#000',borderWidth:1}}>
          {
            <Image
            recyclingKey={item.Id}
              style={{
                flex:1,
                width: null as any,
                height: null as any,
                backgroundColor: '#eee',
                borderRadius:8
              }}
              placeholder={require('../../assets/images/prepare.png')}
              placeholderContentFit='fill' //图片会花屏
              source={item.VideoCover}
              contentFit='fill'
            />
          }
          
        </View>
        <View style={{height:38}}>
          <Text numberOfLines={2} >{item.VideoTitle}</Text>
        </View>
      
      <View style={{
        position:'absolute',right:0,top:0,
        paddingVertical:1,
        paddingHorizontal:4,
        alignItems:'center',
        justifyContent:'center',
        borderBottomLeftRadius:6,
        borderTopRightRadius:6,
        backgroundColor:'#ff7500',
        flexDirection:'row',
      }}>
      <Text style={{fontSize:10, color:'#fff'}}>{TypeTxt}</Text>
      </View>

    </Pressable>
  );
};