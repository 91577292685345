import React, { useState, useRef, useEffect,useContext } from 'react'
import { Text, View,StyleSheet,Image,TouchableOpacity } from 'react-native';
import { showToast } from '@/Components/Toast';
import { Store } from '@/Utils/store'
import {useSelector} from 'react-redux';
import { AntDesign,Ionicons,EvilIcons,Entypo,FontAwesome,Feather } from '@expo/vector-icons';
import Ad from './Ad';

function TabBarIcon(props:any) {
  return <AntDesign size={24} style={{ marginBottom: -3 }} {...props} />;
}

export default function Info() {
    const data = useSelector((state) => state.user.VideoInfo);
    const {findVideoFollow,setVideoFollow,VideoFllowList}:any = useContext(Store);
    const txtcolor = data.Type==0&&'red' || data.Type==1&&'#ff7000'||data.Type==2&&'green'
    const hasFollow = findVideoFollow(data.Id)

    const _onPress = (data) => {
        if(setVideoFollow(data)){
          showToast("ヾ(ｏ･ω･)ﾉ 收藏成功!")
        }else{
          showToast("╮(╯﹏╰）╭ 已取消收藏!")
        }
    }
    
    return (
      <View style={styles.page}>
        <View style={styles.authorWarp}>
          <View style={styles.author}>
            <Text style={{color:txtcolor}}>{data.Type==0&&'【会员】' || data.Type==1&&'【金币】'||data.Type==2&&'【免费】'}</Text>
          </View>

          <View style={{flex:1}}>
            <Text numberOfLines={2} style={{color:'#000'}}>{data.VideoTitle}</Text>
          </View>
              
          <TouchableOpacity onPress={() => _onPress(data)}  style={{
              marginHorizontal:10,
              flexDirection:'row',
              }}>
                <TabBarIcon name="staro" color={hasFollow?'red':'#000'} />
                <Text style={{marginLeft:1,marginTop:4, color:'#000'}}>收藏</Text>
          </TouchableOpacity>

        </View>
          
      <View style={{flex:1}}>
        <Ad/>
      </View>

    </View>

    )
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    page: {
        flex:1,
        backgroundColor: '#fff'
      },
      authorWarp: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 7,
      },
      author: {
        display: 'flex',
        flexDirection: 'row'
      },
      authorAvatar: {
        width: 31,
        height: 31,
        borderRadius: 18
      },
      authorInfo: {
        marginLeft: 9
      },
      authorName: {
        fontSize: 13,
        fontWeight: '700',
        color: '#777'
      },
      authorCount: {
        marginTop: 1,
        fontSize: 9,
        color: '#999'
      },
      reportBtn: {
        fontSize: 11.5,
        color: '#999'
      },
      title: {
        paddingHorizontal: 15,
        marginVertical: 12,
        fontSize: 14.5,
        fontWeight: '700',
        color: '#303133'
      },
      otherWarp: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      },
      otherText: {
        paddingHorizontal: 15,
        fontSize: 10.5,
        color: '#999'
      },
      movie: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 10,
        marginHorizontal: 15,
        padding: 10,
        backgroundColor: '#f5f5f5'
      },
      movieImage: {
        width: 37,
        height: 53,
        borderRadius: 2
      },
      movieInfo: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10
      },
      infoTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 5
      },
      titleText: {
        fontSize: 12.5,
        fontWeight: '700',
        color: '#444'
      },
      titleRating: {
        marginLeft: 5,
        fontSize: 9.5,
        fontWeight: '700',
        color: '#f16c00'
      },
      infoDesc: {
        fontSize: 10.5,
        color: '#555'
      }

});
