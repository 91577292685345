import React, { useState, useCallback,useImperativeHandle,useRef, useEffect,useContext } from 'react'
import {InteractionManager,Image,TouchableWithoutFeedback,TouchableOpacity , Dimensions,Text, View, StyleSheet, Button } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useFocusEffect } from "@react-navigation/native";
import { VideoPlayerProps,VideoPlayer } from '@videojs-player/react'
import 'video.js/dist/video-js.css'
import { AntDesign,MaterialIcons } from '@expo/vector-icons';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';

import RenderBuy from './RenderBuy';

const Video: React.FC = ({ IsPlay, item,Index,navigation }:any) => {
    const playerRef = useRef<any>()
    const dispatch = useDispatch();
    const User:any = useSelector<any>((state) => state.user.userdata);            //后台配置
    const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);  //后台配置
    const ServerTime = useSelector((state:any) => state.user.ServerTime);         //服务器时间
    const [CurrentTime,setCurrentTime] = useState(0)
    const [totalDuration, setTotalDuration] = useState(0);                        //总长度
    const [showModal, setShowModal] = useState(false);                            // 弹出购买
    const [IsLoad,setIsLoad] = useState(false) 
    const [IsError,setIsError] = useState(false) 
    const [State,setState] = useState(null) 
    const playerId = `player${(Index) * 1}`
    //const playerId = `player${item.Id}`

    const playerOptions =  {
        autoplay: false, // 自动播放，false
        bigPlayButton:true,//播放UI按钮
        controls: true, // 控制条
        preload: 'auto', // 预加载， 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        loop: true, // 导致视频一结束就重新开始。
        language: 'zh-CN',
        fluid: false,/// 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        //aspectRatio: '1:2', // 设置宽高比，fluid需为true，将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        //preferFullWindow:true, //设置ios播放器
        //width: width, // 视频框的宽度
        //height: height, // 视频框的高度
        notSupportedMessage: '此视频暂时无法播放，请稍后再试',
        muted: false, // 是否静音
        inactivityTimeout: false, //自动隐藏控制栏
        //playbackRates: [0.5, 1, 1.5, 2],  //播放速度
        playsinline: true,  //解决在iPhone中播放时自动全屏问题
        waiting:true,
        userActions: {
          click: true
          //doubleClick: myDoubleClickHandler
        },
        sources: [{
          src:item.Url,
        }], 

        // controlBar: {
        //   playToggle:true,//播放按钮
        //   volumePanel:false,//音量
        //   pictureInPictureToggle:false,//画中画按钮
        //   fullscreenToggle: false, // 全屏按钮
        //   subtitlesButton:false
        // }
  

        // controlBar: {
        //   playToggle: true,//播放按钮
        //   volumePanel:false,//音量
        //   remainingTimeDisplay: true,//剩余时间
        //   pictureInPictureToggle:false,//画中画按钮
        //   // currentTimeDisplay: true, // 当前时间
        //   // timeDivider: true, // 时间分割线
        //   // durationDisplay: true, // 总时间
        //   // progressControl: true, // 进度条
        //   //captionsButton: false,
        //   //chaptersButton: false,            
        //   //subtitlesButton: false,
        //   // progressControl: {
        //   //     seekBar: true
              
        //   // },
        //   progressControl: {
        //       seekBar: {
        //         vertical: true
        //       }
        //   },
        //   playbackRateMenuButton: true,
        //   descriptionsButton:false,
        //   subsCapsButton:false,// 字幕按钮
        //   audioTrackButton:false,
        //   fullscreenToggle:true, // 全屏按钮
          
        // },
        controlBar: {
          playToggle:true,//播放按钮
          remainingTimeDisplay:true,//剩余时间
          volumePanel:false,//音量
          chaptersButton:false, //倍数
          descriptionsButton:false,//倍数
          pictureInPictureToggle:false,//画中画按钮
          fullscreenToggle: false,//全屏按钮
          subsCapsButton:false,// 字幕按钮

        }
        
    }

    const IsVip = ()=>{
      if(User.VipTime)
      {
          const startTime= moment(ServerTime).format('YYYY-MM-DD HH:mm:ss')
          const endTime = moment(User.VipTime).format('YYYY-MM-DD HH:mm:ss')
          const code = moment(endTime).diff(moment(startTime), 'seconds')
          console.log(startTime,endTime,code)
          if(code >= 0)
          {
              return true
          }
      }
      return false
  }
    //是否会员
    const CheckVip = ()=>{
      if(item.Type == 0 && !User.IsVip){
          return true
      }
      
      if(IsVip() == false){
          return true
      }
    }

    useEffect(() => {
      if(IsVip() && showModal){
        setShowModal(false)
        playerRef.current.controlBar.show()
        playerRef.current?.play && playerRef.current?.play()
      }
    },[IsVip(),showModal])

    useEffect(() => {
      //console.log('Index:',Index, IsPlay)
      if(IsLoad && !IsError){
        if(IsPlay){
          {
            if(showModal) return
            playerRef.current?.play && playerRef.current?.play()
          }
        }else{
          {
            playerRef.current?.pause && playerRef.current?.pause()
          }
          //playerRef.current?.pause()
        }
      }
      
    }, [playerRef,IsPlay])

  
    const handlePlayerMounted: VideoPlayerProps['onMounted'] = (payload) => {
      const {player} = payload
      playerRef.current=player
      player.handleTechTap_ = function () { 
        if(!IsError){
          if(player.paused()){
            player.play()
          }else{
              player.pause()
          }
        }
      }
    }
  
    const onReady=(event)=>{
      setIsLoad(true)
    }

    const onStateChange =(State:any)=>{
      //console.log("State:",State)
      setCurrentTime(State.currentTime)
      setTotalDuration(State.duration)
      setState(State)
      if(State.currentTime>=10){
        
      }
    }

    const onPlay=(p)=>{
      //console.log(p)
    }

    const onEvent=(a)=>{
      //console.log(Player)
      //Player.bigPlayButton.show()
      
    }

    const onError=(a)=>{
      //console.log(Player)
      //Player.bigPlayButton.show()
      setIsError(true)
      console.log("错误：",Index,item)
    }

    const onPress = ()=>{
      playerRef.current?.pause()
      navigation.navigate("Pay",{title:"VIP"}) 
    }

    

    useEffect(() => {
      if(CurrentTime >= settingInfo.ShortVideoByLintTime){
        if(CheckVip()){
          playerRef.current.controlBar.hide()
          playerRef.current?.pause && playerRef.current?.pause()
          setShowModal(true)
          return
        }
      }
    }, [CurrentTime])

    const Styles = createGlobalStyle`
      .video-js {
        position: relative !important;
        width: 100% !important;
        height: 100% !important;
      }
      .vjs-control-bar{
        font-size: 1.2em;
        line-height: 1.2;
      }


      /* 暂停时显示播放按钮 */
      .vjs-paused .vjs-big-play-button,
      .vjs-paused.vjs-has-started .vjs-big-play-button {
          display: block;
      }

      /* 播放按钮变圆形 */
      .video-js .vjs-big-play-button{
    
        margin-top:-1em;
        margin-left:-1.2em;
        font-size:2.5em;
        line-height:2.3em;
        height:2.5em;
        width:2.5em;
        -webkit-border-radius:2.5em;
        -moz-border-radius:2.5em;
        border-radius:2.5em;
        background-color:#73859f;
        background-color:rgba(115,133,159,.5);
        border-width:0.15em;
      }



    `;

    return (
      <>
        {
          !IsError ?
          <VideoPlayer 
            id={playerId}
            options={playerOptions}
            //class="video-js vjs-big-play-centered1"
            onMounted={handlePlayerMounted}
            onReady={onReady}
            onpause={onEvent}
            onPlay={onPlay}
            onStateChange={onStateChange}
            onError={onError}
            //onTap={onTap}
            //onEvent={onEvent}
            //onPlay={onEvent}
            //ended="handleEvent($event)"
            >
              {()=>{
                return(
                  <RenderBuy Item={item} User={User} isShow={showModal} onPress={onPress}/>
                )
            }}
              
               
          </VideoPlayer>
          :
          <View style={{flex:1, position:'absolute',left:0,right:0,top:0,bottom:0,justifyContent:'center',alignItems:'center',backgroundColor:'#000',zIndex:1500}}>
              {/* <Text style={{color:'#fff'}}>Sorry , 播放失败 , 线路异常！{item.Title}</Text> */}
              <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <MaterialIcons name="error-outline" size={28} color="#ff7500" />
                <h5 style={{marginLeft:6,color:'#ff7500'}}>{item.Title}</h5>
              </View>
              <h5 style={{marginTop:-10, color:'#ff7500'}}>播放失败： 线路繁忙，请稍后再试!</h5>

          </View>
        
        }
           <Styles/>
      </>

    )
}

export default Video;
// const forwaredCounter = React.forwardRef(Video);

// export default forwaredCounter;