import React, { useEffect,useState } from "react";
import {View,Text,Pressable,SafeAreaView, Image,StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import QRCodeStyled from 'react-native-qrcode-styled';
import { Button, Modal } from 'native-base';
import Shadow from './Shadow';
import { updateQrCodeModal } from "@/Actions/userActions";
import AES from '../../../Utils/Aes';

export default function ({onPress}:any) {
    const dispatch = useDispatch();
    const INFO = useSelector((state:any) => (state.user.userdata))     //后台配置
    const [modalVisible, setModalVisible] = useState(true);
    const QrCodeModal = useSelector((state:any) => state.user.QrCodeModal);
    //console.log(INFO)
    const str = AES.Encrypt(JSON.stringify({Id:INFO.Id,MechineCode:INFO.MechineCode}))

    const click = () =>{
        dispatch(updateQrCodeModal(false))
    }

    useEffect(() => {
        //generateQRCode()
    }, []);
    
    return (
        <Modal isOpen={QrCodeModal} >
            <Shadow>
                <View style={styles.root}>
                    <View style={{margin:18,alignItems:'center'}}>
                        <Text style={{ fontSize:20,
                            fontWeight:'bold',
                            marginVertical:10}
                        }>截图保存账号(凭证)</Text>
                        <QRCodeStyled
                            data={str}
                            style={{backgroundColor: 'white'}}
                            padding={20}
                            pieceSize={4}
                        />
                        <Text style={{fontSize:13}}>扫一扫二维码，找回账号</Text>
                        <Text style={{fontSize:13,color:'red'}}>若丢失账号，请到个人中心找回</Text>
                        <Text style={{fontSize:13}}>凭证仅用于找回账号，请勿外传</Text>
                    </View>
                </View>
                <Pressable
                    onPress={()=>click()}
                    style={{
                        padding:10,
                        position:'absolute',right:0,paddingRight:12,paddingTop:6
                    }}>
                    <Text style={{fontSize:20,fontWeight:'bold'}}>x</Text>
                </Pressable>
            </Shadow>
        </Modal> 
    );
  
};

const styles = StyleSheet.create({
    root: {
      backgroundColor: 'white',
      borderRadius: 16,
      overflow: 'hidden',
      alignItems:'center',
      justifyContent:'center'
    },
  });