import React, {useEffect} from 'react';
import {TouchableWithoutFeedback,Image,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';


function Cell_Monney({Item,Select, navigation,route}:any) {
    //console.log(Item)
  return (  
    <View style={{width:'100%' ,height:'100%',flexDirection:'row' }}>
        <Image resizeMode="stretch" source={require("./imgs/icon_vip_price_item_bg.png")} style={{
            borderRadius:8,
            position:'absolute',
            height:"100%",width:"100%"
            }}/>
        <View style={{flex:0.6,}}>
            <Text style={{marginLeft:14,marginTop:10, textAlign:'left',color:'#000',fontWeight:'bold',fontSize:16}}>{Item.ChannelTitle}</Text>
            <Text style={{marginBottom:6, marginLeft:14,marginTop:6, textAlign:'left',color:'#696969',fontWeight:'bold',fontSize:10}}>{Item.Desc}</Text>
        </View>

        <View style={{flex:0.4,flexDirection:'row',alignItems:'center',marginBottom:20}}>
            <View style={{flex:0.5}}>
                <Text style={{marginLeft:12,marginTop:10,color:'#ff7500',fontWeight:'bold',fontSize:18}}>¥{Item.Price}</Text>
            </View>

            <View style={{flex:0.5}}>
                <Text style={{marginTop:10,color:'#696969',fontSize:9,textDecorationLine:'line-through'}}>原价¥{Item.Price*2}</Text>
        </View>
    </View>
   {
    Select &&(
        <Image resizeMode="stretch" source={require("./imgs/icon_recharge_price_press.png")} style={{
            //borderRadius:8,
            bottom:0,
            right:0,
            position:'absolute',
            height:40,width:40
        }}/>
    )
   }

   
    </View>
  );
}

export default Cell_Monney;
