import axios, { AxiosResponse } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
//import deviceInfo from 'react-native-device-info';
//import store from '@/store';
//import CommonConfig from './config';
//import { Toast } from '@ant-design/react-native';
import { getToken,getApiBase } from './storage';

//import {updateUser,updateNotific,updateSearchInfo,updateSearch, updatePayList,updateMonneyList,updateMonneyIndex,updateMonneyItem, updateRunAd,updateAdvertInfo} from '../actions/userActions';
 


const responseLog = (res) => {
  const randomColor = `rgba(${Math.round(Math.random() * 255)},${Math.round(
    Math.random() * 255,
  )},${Math.round(Math.random() * 255)})`;

  console.log(
    '%c┍------------------------------------------------------------------┑',
    `color:${randomColor};`,
  );
  console.log('| 请求地址：', res.request._url);
  console.log('| 请求方式：', res.config.method);
  console.log('| 请求头：', res.config.headers);
  console.log('| 请求时间：', res.headers.EndTime);
  console.log('| 请求参数：', res.config);
  console.log('| 返回数据：', res.data);
  console.log(
    '%c┕------------------------------------------------------------------┙',
    `color:${randomColor};`,
  );
};

const GetApiBase = async ()=>{
  return await getApiBase()
}

const instance = axios.create({
  //baseURL: CommonConfig.BASE_URL,
  timeout: 1200,
  //withCredentials: true,
  storage: false, // 是否开启缓存
  headers: {
    platform: Platform.OS,
    version: "1.0",

    //"text/plain"
     //'Cache-Control': 'no-cache',
     //'Expires':'0'
    //version: deviceInfo.getVersion(),
  },
});
instance.defaults.headers['Content-Type'] = 'application/json';
//instance.defaults.headers.get = {"Accept": "application/dns-json"};

// Add a request interceptor
instance.interceptors.request.use(
  async function(config) {
    const { headers,baseURL } = config;
    //console.log(await getApiBase())
    //config.baseURL = await GetApiBase();
    //config.Cache-Control = 'no-cache'
    //console.log(config)
    //headers.Authorization = "Bearer "+await getToken();
    //console.log(config)
    config.headers['request-startTime'] = new Date().getTime();
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);





instance.interceptors.response.use(
  function(response) {
    //console.log(response)
    const currentTime = new Date().getTime()      
    const startTime = response.config.headers['request-startTime']      
    response.headers['EndTime'] = currentTime - startTime      
    //response && responseLog(response); // release to remove
    //console.log('response.data:',response.data)
    if (response && response.status === 200) {
      const { Success, Data, Msg, ErrorCode } = response.data;
      //console.log(response)
      if (ErrorCode == 401)
      {
        return Promise.reject('token过期,请退出app重进!');
      }

      if (Success == false)
      {
        return Promise.reject(Msg);
      }
      return Data;
    }
    return response.data;
  },
  function(error) {
    //console.warn('error', error);
    if (error.code === 'ECONNABORTED') {
      return Promise.reject({ msg: '网络超时' });
    }
    switch (error.response.status) {
      case 401:
        return Promise.reject({ msg: '用户未登录' });
      case 404:
        return Promise.reject({ msg: '请求接口异常 404 not found' });
      case 400:
      case 500:
        return Promise.reject({ msg: '服务端异常' });
      case 502:
        return Promise.reject({ msg: '服务器异常' });
      default:
        return Promise.reject(error.response);
    }
  },
);

export default instance;
