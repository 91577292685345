import React, {useEffect} from 'react';
import {TouchableWithoutFeedback,Image,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import Animated from 'react-native-reanimated';
import {useDispatch} from 'react-redux';

//模块
import VIP from './VIP';
import Monney from './Monney';


function Index({navigation,route}:any) {
  const InitTitle = route.params ? (route.params.title ? route.params.title : 'Monney' ) : 'Monney'
  const dispatch = useDispatch();
  const Tab = createMaterialTopTabNavigator();

  // React.useLayoutEffect(() => {
  //      navigation.setOptions({
  //        headerBackTitleVisible:false,
  //        title:"充值"
  //      });
  //    }, [navigation]);


 const onPress = () => {
   navigation.goBack()
 }
  return (
    <SafeAreaView style={{flex:1,flexDirection:'row'}}>
      <View style={{flex:1,width:'100%'}}>
        <Tab.Navigator initialRouteName={InitTitle}
        screenOptions={{
          tabBarActiveTintColor: '#ff7500', //Colors[colorScheme].tint,
          tabBarStyle: { 
         },
      }}>
        <Tab.Screen name="VIP" 
          component={VIP}
          options={{
            title:"会员套餐",
            // tabBarStyle:{
            //   backgroundColor:'rgba(255,255,255,1)'
            // }
            //head
            //headerTransparent: true,
        }}
          />
        <Tab.Screen name="Monney" component={Monney}
          options={{
            title:"金币套餐",
            // tabBarStyle:{
            //   backgroundColor:'transparent'
            // }
            //head
            //headerTransparent: true,
        }}
        />
        </Tab.Navigator>
      </View>
    </SafeAreaView>
  );
}

export default Index;
