import axios, { AxiosResponse } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
//import deviceInfo from 'react-native-device-info';

import { getToken,getApiBase } from './storage';
import CryptoJS from './CryptoJS';

import {updateUser,updateNotific,updateSearchInfo,updateSearch, updatePayList,updateMonneyList,updateMonneyIndex,updateMonneyItem, updateRunAd,updateAdvertInfo} from '../Actions/userActions';
 


const responseLog = (res) => {
  //
  const randomColor = `rgba(${Math.round(Math.random() * 255)},${Math.round(
    Math.random() * 255,
  )},${Math.round(Math.random() * 255)})`;

  console.log(
    '%c┍------------------------------------------------------------------┑',
    `color:${randomColor};`,
  );
  console.log('| 请求地址：', res.request._url);
  console.log('| 请求方式：', res.config.method);
  console.log('| 请求头：', res.config.headers);
  console.log('| 请求时间：', res.headers.date);
  console.log('| 请求参数：', res.config);
  if(res.config.data != undefined){
    const txt = JSON.parse(res.config.data)
    console.log('| 加密前参数：', JSON.parse(Decrypt(txt.data)));
    console.log('| 加密后参数：', JSON.parse(res.config.data));
  }
  
  console.log('| 返回数据：', res.data);
  if(res.data.Data) console.log('| 返回数据解密后：', JSON.parse(Decrypt(res.data.Data)));
  console.log(
    '%c┕------------------------------------------------------------------┙',
    `color:${randomColor};`,
  );
};

const GetApiBase = async ()=>{
  return await getApiBase()
}

//加密方法
const Encrypt = (word) => {
  //console.log(22222)
  var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
  var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.ciphertext.toString().toUpperCase();
}

//解密方法
const Decrypt = (word) => {
  var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
  var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

const instance = axios.create({
  //baseURL: 'http://192.168.1.247',
  timeout: 3000 * 5,
  //withCredentials: true,
  headers: {
    platform: Platform.OS,
    version: "1.0",
    //version: deviceInfo.getVersion(),
  },
});
instance.defaults.headers['Content-Type'] = 'application/json';

// Add a request interceptor
instance.interceptors.request.use(
  async function(config) {
    const { headers,data,baseURL } = config;
    config.baseURL = await getApiBase();
    //console.log(config.baseURL)
    //config.baseURL = 'http://47.106.243.212:5000' //await getApiBase();
    //config.baseURL = 'http://192.168.3.10:5000' //await getApiBase();
    headers.Authorization = "Bearer " +await getToken();
    //console.log(config.baseURL)
    //console.log(config)
    //alert(config.baseURL)
   
    if(data != undefined){
      //console.log(JSON.stringify(data))
      const EncryptTxt = Encrypt(JSON.stringify(data))
      //console.log(EncryptTxt)
      config.data ={data:EncryptTxt}
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function(response) {
    //console.log(response)
    //response && responseLog(response);1 // release to remove
    
    if (response && response.status === 200) {
      const { Success, Data, Msg, ErrorCode } = response.data;
      if (ErrorCode == 401)
      {
        const dispatch = useDispatch();
        //AsyncStorage.removeItem('data');
        //AsyncStorage.removeItem('noticeInfos');
        dispatch(updateUser(null));
        dispatch(updateNotific(null));
        dispatch(updateSearchInfo(null));
        dispatch(updateSearch(null));//搜索内容
        
        dispatch(updatePayList(null));
        dispatch(updateMonneyList(null));
        dispatch(updateMonneyIndex(null));
        dispatch(updateMonneyItem(null));
        dispatch(updateRunAd(null));
        dispatch(updateAdvertInfo(null,null))
        return Promise.reject('token过期,请退出app重进!');
      }

      if (Success == false)
      {
        return Promise.reject(Msg);
      }

      //console.log(response.data)
      // if (code === 0) {
      //   return data;
      // }
      // if (code === 2001) {
      //   //store.dispatch({ type: 'user/logout' });
      //   //Toast.info(message);
      //   return Promise.reject('用户未登录');
      // }
      // if (hasError) {
      //   //Toast.info(message);
      //   return data;
      // }
      //console.log(Data)
      return JSON.parse(Decrypt(Data));
    }
    return response.data;
  },
  function(error) {
    console.warn('error', error);
    if (error.code === 'ECONNABORTED') {
      return Promise.reject({ msg: '网络超时' });
    }
    switch (error.response.status) {
      case 401:
        return Promise.reject({ msg: '用户未登录' });
      case 404:
        return Promise.reject({ msg: '请求接口异常 404 not found' });
      case 400:
      case 500:
        return Promise.reject({ msg: '服务端异常' });
      case 502:
        return Promise.reject({ msg: '服务器异常' });
      default:
        return Promise.reject(error.response);
    }
  },
);

export default instance;
