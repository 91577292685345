import CryptoJS from './CryptoJS';

const AES = {
    Encrypt: (word:any) => { //加密方法
        //console.log(22222)
        var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
        var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
        let srcs = CryptoJS.enc.Utf8.parse(word);
        let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.ciphertext.toString().toUpperCase();
    },
    Decrypt: (word:any) => { //解密方法
        var key = CryptoJS.enc.Utf8.parse('12345678900000001234567890000000'); 
        var iv = CryptoJS.enc.Utf8.parse('1234567890000000');
        let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }
}
export default AES;