import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View 
} from 'react-native';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';

import { RefreshControl } from 'react-native-web-refresh-control'
import {updateVideo} from '@/Actions/userActions';
import {Recycler} from '@/Components/Recycler/Index';

//api
import {API_Video_GetMain} from '@/API/Index';
 //Redux
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

import Ad from './Ad'
import Ad2 from './Ad2'
import {Cell} from '@/Components/Cell'

import axios from 'axios';
import { Buffer } from 'buffer'

function toBase64(input:any) {
  return Buffer.from(input, 'utf-8').toString('base64')
}

const DecryptIMG =(array:any)=>{
  const bytes = new Uint8Array(array);
  for (let index = 0; index < 100; index++) {
    bytes[index] = bytes[index] ^ 968542557
  }
  return bytes
}

export default function ({navigation,route}:any){
  const dispatch = useDispatch();
  const [Data, setData] = useState([]);
  const [ListData, setListData] = useState([]);
  const [page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [IsEnd, setIsEnd] = useState(false);
  const AdList = useSelector((state:any) => state.user.HomeAd);
  const AdList2 = useSelector((state:any) => state.user.HomeAd2);
  const [AdListHeiget, setAdListHeigt] = useState(0);

  const ImageDecrypt = async (source:any)=> {
    if(source){
      try {
        const filename = source.substring(source.lastIndexOf("/"), source.indexOf("?") === -1 ? source.length : source.indexOf("?"));
        const ext = filename.indexOf(".") === -1 ? ".jpg" : filename.substring(filename.lastIndexOf("."));
        if(ext == '.web' || ext == '.ceb'){
          const response = await axios.get(source,{responseType:'arraybuffer'})
          const data = DecryptIMG(response.data)
          const path = 'data:image/jpeg;base64,'+toBase64(data)
          if (path) {
            return(path)
          }
        }else{
          return({uri: source})
        }
      } catch (error) {
        
      }

    }
    
    return null

  }

  const fetchData = async () => {
    try {
        const result:any = await API_Video_GetMain({Page:page})
        
        //console.log(result)
        if(page==1){
            const Init:any =[{Type:"HEADER"}]
            setData(Init.concat(result));
        }else{
            setData(Data.concat(result));
        }
        if(result.length <= 0){
          setIsEnd(true)
        }
        setLoading(true)
    } catch (error) {
        setTimeout(() => {
            fetchData();
        }, 5000);
    }
  };

  const renderHeader = () => {
    return (
      <View style={{flex:1, marginTop:10}}>
        <Ad/>
        {
          AdList2.Data&&(
            <Ad2/>
          )
        }
      </View>
    );
  };

  useEffect(() => {
    if(AdList2.Data){
      const heiget = Math.ceil(AdList2.Data.length / 4)
      if(heiget >0){
        setAdListHeigt(heiget* 80)
      }
    }
  }, [AdList2])

 


  useEffect(() => {
    fetchData()
  }, [page])

  const Next =()=>{
    if(!IsEnd){
      setPage(page+1)
    }
  }

  const onPress = (data:any) => {
    dispatch(updateVideo(data))
    navigation.navigate('Player', {Data: data});
  }

  const items = useMemo(() => {
    return Data;
  }, [page,Data]);

  const reloadLines = React.useCallback(() => {
    setRefreshing(true)
    setData([])
    setPage(1)
    setIsEnd(false)
    setTimeout(() => {
      fetchData()
      setRefreshing(false)
  }, 10);
    
  }, [])

  const renderItem=({data}:any)=>{
    return <Cell item={data} Click={onPress} />
  }

  const renderFooter = () => {
    if(IsEnd==true){
      return(
        <View style={{alignItems:'center',marginBottom:10}}>
          <Text style={{margin:10}}>到底部啦！</Text>
        </View>
      )
    }
      return (<Spinner size="lg" color="warning.500" />);
    };

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      {
        Data.length>0?
        <Recycler items={Data} Next={Next} Click={onPress}
        //headerHeight={AdList.Data ? 200: 0}
        headerHeight={AdList.Data ? (200+AdListHeiget): 0}
        itemHeight={160}
        renderHeader={renderHeader}
        renderItem={renderItem}
        renderFooter={renderFooter}
        scrollViewProps={{
          refreshControl: (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                reloadLines()
              }}
            />
          )
        }}
         />
        :<View style={{flex:1,justifyContent:'center',alignItems:'center'}}>
          <Spinner size="lg" color="warning.500" />
          <Text>正在加载中...</Text>
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
