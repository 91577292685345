import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { AntDesign,MaterialIcons } from '@expo/vector-icons';
const propTypes = {
  player: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.string,
};

const defaultProps = {
  position: 'left'
};

export default class BackButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    //alert('123')
    this.props.onBack()
  }

  render() {
    const { player, className, position} = this.props;


    return (
      <a
        ref={c => {
          this.button = c;
        }}
        className={classNames(
          className,
          'video-react-control video-react-button video-react-icon'
        )}
        //href={'alert(1)'}
        //download
        type="button"
        aria-live="polite"
        //tabIndex="0"
        onClick={this.handleClick}

        style={{
          position:'absolute',
          left:20,
          top:40,
          width:40,
          height:40,
          //zIndex:9999
          //right:0,
          //background:'red',

            //backgroundRepeat: 'no-repeat',
          //backgroundPosition: 'center'
          

        }}
        tabIndex="0"
        //onClick={this.handleClick}
      >
        <MaterialIcons name={'arrow-back-ios'}  size={32} color='#FFF'/>
      </a>
    );
  }
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;