import React, { useEffect,useState } from 'react';
import { Button, Text, View, StyleSheet } from 'react-native';
import * as WebBrowser from 'expo-web-browser';


export default function() {
  const [result, setResult] = useState(null);

  const _handlePressButtonAsync = async () => {
    let result = await WebBrowser.openBrowserAsync('https://gopay.xiangcangzhuan.top/mod/s/66666304107389949072');
    //let result = await WebBrowser.openAuthSessionAsync('https://expo.dev');
    setResult(result);
  };

  useEffect(() => {
     _handlePressButtonAsync()
   },[])

   
  return (
    <View style={styles.container}>
      <Button title="Open WebBrowser" onPress={_handlePressButtonAsync} />
      <Text>{result && JSON.stringify(result)}</Text>
    </View>
  );
}
var styles = StyleSheet.create({
    container: {
      flex:1
    },
});