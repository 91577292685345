import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View,
  Platform,
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control'
import {updateVideo} from '@/Actions/userActions';
import {Recycler} from '@/Components/Recycler/Index';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';
//api
import {API_Video_List} from '@/API/Index';
 //Redux
import {useDispatch} from 'react-redux';

import Ad from './Ad'
import {Cell} from '@/Components/Cell'
import { viewHeight } from '@/Utils/Index';

import {useSelector} from 'react-redux';


export default function ({navigation,route}:any){
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [IsEnd, setIsEnd] = useState(false);
  const AdList = useSelector((state:any) => state.user.ZhuanTiAd);
  //console.log(AdList.Data)


  const fetchData = async () => {
    try {
        const result:any = await API_Video_List({Id:route.params.Data.Id,Next:page,Count:12})
        //console.log(result)
        if(page==1){
            const Init:any =[{Type:"HEADER"}]
            setData(Init.concat(result));
        }else{
            setData(data.concat(result));
        }
        //console.log(result.length)
        if(result.length <= 0){
          setIsEnd(true)
        }
    } catch (error) {
        setTimeout(() => {
            fetchData();
        }, 5000);
    }
  };

  const renderHeader = () => {
    return (
      <View style={{flex:1, marginTop:10}}>
        <Ad/>
      </View>
    );
  };


  useEffect(() => {
    fetchData()
  }, [page])

  const Next =()=>{
    if(!IsEnd){
      setPage(page+1)
    }
    console.log(page)
  }



  const onPress = (data:any) => {
    dispatch(updateVideo(data))
    navigation.navigate('Player', {Data: data});
    //navigation.navigate("VideoList",{Data:data})
    //navigation.push("VideoList",{Data:data})

  }

  const items = useMemo(() => {
    return data;
  }, [page,data]);

  const reloadLines = React.useCallback(() => {
    setRefreshing(true)
    setIsEnd(false)
    setData([])
    setPage(1)
    setTimeout(() => {
      fetchData()
      setRefreshing(false)
  }, 10);
    
  }, [])

  const renderItem=({data})=>{
    return <Cell item={data} Click={onPress} />
  }

  const renderFooter = () => {
    //console.log('IsEnd:',IsEnd)
    if(IsEnd==true){
      return(
        <View style={{alignItems:'center',marginBottom:30}}>
          <Text style={{margin:10}}>到底部啦！</Text>
        </View>
      )
    }
      return (<Spinner size="lg" color="warning.500" />);
    };

  return (
    <View style={{
      //height:viewHeight
      paddingBottom: Platform.OS !== 'web' ? 10 : 0,
      // web端需要减去标题高度
      height: Platform.OS === 'web' ? viewHeight - 42 : viewHeight,
    }}>
      <StatusBar style="auto" />
      {
        items.length>0?
        <Recycler items={items} Next={Next} Click={onPress}
        numColumns={2}
        headerHeight={AdList.Data ? 160:0}
        itemHeight={160}
        renderHeader={renderHeader}
        renderItem={renderItem}
        renderFooter={renderFooter}
        gap={6}
        scrollViewProps={{
          refreshControl: (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                reloadLines()
              }}
            />
          )
        }}
         />
        :null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    //flex: 1,
    height:viewHeight
    // backgroundColor: '#fff',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});
