import React, {useState,useEffect,useRef} from 'react';
import { Ionicons,FontAwesome,MaterialIcons } from '@expo/vector-icons';
import { StyleSheet, 
  Text, 
  View,
  TouchableOpacity,
} from 'react-native';
import { SafeAreaView } from '../../../../components/Themed';

import {
  Button,
  Input
} from 'native-base';

import {useDispatch} from 'react-redux';
import {updateSearch} from '@/Actions/userActions';
import List from './List';
import Info from './Info'


export default function ({navigation,route}:any){

  const dispatch = useDispatch();
  const [value, onChangeText] =  useState('');
  const [SearhTxt,SetSearhTxt] = useState('搜索');
  const Ref =useRef(null)

  useEffect(() => {
    if(value.length == 0){
      SetSearhTxt('搜索')
    }
  }, [value])

  const goBack=()=>{
    dispatch(updateSearch(null))
    navigation.goBack()
}

  useEffect(() => {
      Ref.current?.focus()
    }, [Ref])

  const bOk =(text)=>{
    if(text){
        dispatch(updateSearch({
            type:'video',
            text:text
        }));
    }
    else{
        dispatch(updateSearch(null))
    }
  }

  const _onPressOk = () => {
    if(SearhTxt =="搜索"){   
        if(value.length >0)
        {
            SetSearhTxt('取消')
            bOk(value)
        }else
        {
          alert('请输入关键字!')
        }
    }else{
        SetSearhTxt('搜索')
        bOk(false)
    }
  } 

    

  const onSubmitEditing=(e)=>{
    if(value.length >0){
      SetSearhTxt('取消')
      bOk(value)
    }else{
      alert('请输入关键字!')
    }
  }

  const onPress =(data)=>{
    if(data.length >0){
      onChangeText(data);
      SetSearhTxt('取消')
      bOk(data)
    }else{
      alert('请输入关键字!')
    }
  }
  

  return(
    <SafeAreaView edges={['top']} style={{ flex: 1,backgroundColor:'#ff7500'}}>
      <View style={styles.page}>
        <View style={styles.authorWarp}>
        <TouchableOpacity onPress={()=>
            goBack()
          }>
            <Ionicons name="chevron-back" size={38} color="#fff" />
        </TouchableOpacity>
        <View style={{flex:1,flexDirection:'row',alignItems:'center', backgroundColor:'#eee',
            borderRadius:20,}}>
              <TabBarIcon name="search" color={'#ff8550' }/>
        <Input
          w='5/6'
          ref={Ref}
          clearTextOnFocus={true}
          value={value}
          onChangeText={(text) => {
            onChangeText(text);
            //props.onChangeText(text)
            }
        }
          onSubmitEditing={onSubmitEditing}
          placeholderTextColor={'#a5a5a5'}
          underlineColorAndroid={'transparent'}
          placeholder="输入影片番号或片名" 
          //my={1}
          borderRadius={20}
          //----------------背景颜色-----------------------
              borderColor={'#transparent'}
              borderWidth={0}
              focusOutlineColor={'#transparent'}
              backgroundColor={'#transparent'}
          //---------------------------------------
          color={'#000'}
          />

          </View>
          <Button borderRadius={10} style={{backgroundColor:'green',marginLeft:6,borderWidth:0}} size="sm"  w="1/6" h="full"  onPress={() => _onPressOk()}>{SearhTxt}</Button>
        </View>
        {
        SearhTxt=='搜索' ?
          <Info navigation={navigation} onChangeText={onChangeText} onPress={onPress}/>
            :
          <List navigation={navigation}/>
        }
      </View>
    </SafeAreaView>
    )
}

function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={20} style={{marginLeft:10, marginBottom:0}} {...props} />;
}

const styles = StyleSheet.create({
  page: {
    flex:1,
    backgroundColor: '#fff'
  },
  authorWarp: {
    //marginTop:100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 15,
    backgroundColor: '#ff7500'
  },
  author: {
    display: 'flex',
    flexDirection: 'row'
  },
  authorAvatar: {
    width: 31,
    height: 31,
    borderRadius: 18
  },
  authorInfo: {
    marginLeft: 9
  },
  authorName: {
    fontSize: 13,
    fontWeight: '700',
    color: '#777'
  },
  authorCount: {
    marginTop: 1,
    fontSize: 9,
    color: '#999'
  },
  reportBtn: {
    fontSize: 11.5,
    color: '#999'
  },
  title: {
    paddingHorizontal: 15,
    marginVertical: 12,
    fontSize: 14.5,
    fontWeight: '700',
    color: '#303133'
  },
  otherWarp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  otherText: {
    paddingHorizontal: 15,
    fontSize: 10.5,
    color: '#999'
  },
  movie: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
    marginHorizontal: 15,
    padding: 10,
    backgroundColor: '#f5f5f5'
  },
  movieImage: {
    width: 37,
    height: 53,
    borderRadius: 2
  },
  movieInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10
  },
  infoTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 5
  },
  titleText: {
    fontSize: 12.5,
    fontWeight: '700',
    color: '#444'
  },
  titleRating: {
    marginLeft: 5,
    fontSize: 9.5,
    fontWeight: '700',
    color: '#f16c00'
  },
  infoDesc: {
    fontSize: 10.5,
    color: '#555'
  }
});