import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View 
} from 'react-native';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';

import {API_User_GetUserData,API_GetServerTime,API_User_GetServerTime} from '../../API/Index';
import {updateServerIP_Object,addDnsServer,updateDnsServer} from '../../Actions/userActions';

//api
//import {API_Video_GetAreaVideo} from '@/API/Index';
 //Redux
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import axios from 'axios';



export default function ({data,index}:any){
  const dispatch = useDispatch();
  const [Data, setData] = useState(data);
  const [Error, setError] = useState(false);
  const [Loading, setLoading] = useState(false);
 

  const fetchData = async (tmp:any) => {
    try {
        setLoading(true)
        const startTime = new Date().getTime()      
        //const ret = await API_GetServerTime(tmp.Addr)
        const ret = await axios.post(tmp.Addr+'/User/GetServerTime', {
          // data: {
          //   name: 'John'
          // }
        }, {
          timeout:1000,
          headers: {
            'Content-Type': 'application/json',
            // 'Author11ization': 'Bearer xxxxx1xxx'
          }
        })
        //console.log(ret)
        const EndTime = new Date().getTime() - startTime     
        tmp.State = 2
        tmp.Time = EndTime
        tmp.Link = true
        dispatch(updateServerIP_Object(tmp));
        setData(tmp)
    } catch (error) {
        //console.log('error:',error)
        setError(true)
        tmp.State = 1
        tmp.Link = true
        dispatch(updateServerIP_Object(tmp));
    }
    setLoading(false)
  };



  useEffect(() => {
    fetchData(data)
  }, [])

  
  return (
    <View style={styles.container}>
        {
            Data?
            <>
            <Text style={styles.txt}>服务器{index+1} ----> </Text>
            {/* <Text style={styles.txt}>服务器{index+1}{Data.Addr} ----> </Text> */}
            {
            Loading ?

            <Spinner style={{marginLeft:4}}  color="#fff" />
            :
                Error == true?
                <Text style={styles.txt}> 异常 </Text>
                : <Text style={styles.txt}> 正常{Data.Time}Ms</Text>
            }
            </>
            :null
        }
        
          
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection:'row'
  },
  txt:{
    fontSize:13,
    fontWeight:'bold',
    color:'#fff'
  }
});
