import React, {createContext, PureComponent} from 'react';
import Storage from './storage';
import moment from "moment";

export const initialStore = {
    UserInfo:{
        meChineCode:null
    },
    VideoHistoryList:[],        //视频浏览记录
    VideoFllowList:[],          //视频收藏记录

    ManHuaHistoryList:[],       //漫画浏览记录
    ManHuaFllowList:[],         //漫画收藏记录

    ShortVideoHistoryList:[],   //短视频浏览记录
    ShortVideoFllowList:[],     //短视频收藏记录

    XiaoShuoHistoryList:[],     //小说浏览记录
    XiaoShuoFllowList:[],       //小说收藏记录




    MonneyHistory:[],       //消费明细列表

    PayList_Video:[],       //购买视频列表
    PayList_ManHua:[],      //购买漫画列表
    PayList_XiaoShuo:[],    //购买小说列表
    PayList_ShortVideo:[],  //购买短视频列表

    SearchList_Video:[],    //搜索视频列表

    settings:{
        allowMoblieNetwork:true,
        preLoad:false,
        autoPlayNext:true,
    }
};

export const Store = createContext(initialStore);

export class StoreProvider extends PureComponent {
    state = {
        ...initialStore
    }

    //设置User
    setUserInfo = (type,value) => {
        const _UserInfo = Object.assign({}, this.state.UserInfo, { [type]: value });
        this.setState({
            UserInfo:_UserInfo
        })
    }

    //-----------------------------------------------------------------
    //-------------------------浏览模块---------------------------------
    //-----------------------------------------------------------------

    //初始化
    initHistory = (list) => {
        this.setState({VideoHistoryList:list});
    }

    //添加视频历史记录
    addVideoHistory = (item) => {
        const {VideoHistoryList} = this.state;
        //console.log(VideoHistoryList)
        const index = VideoHistoryList.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            VideoHistoryList.splice(index, 1);
        }
        this.setState({VideoHistoryList:[item,...VideoHistoryList]});
    }

    //删除视频历史记录
    removeVideoHistory = (idList) => {
        const {VideoHistoryList} = this.state;
        const _VideoHistoryList = VideoHistoryList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({VideoHistoryList:_VideoHistoryList});
    }

    //查找视频历史记录
    findVideoHistory = (Id) => {
        const {VideoHistoryList} = this.state;
        return VideoHistoryList.find(el=>el.Id===Id);
    }
    
    //移除全部视频记录
    removeVideoAllHistory = () => {
        this.setState({VideoHistoryList:[]});
    }


    //-----------------------------------------------------------------
    //添加短视频历史记录
    addShortVideoHistory = (item) => {
        const {ShortVideoHistoryList} = this.state;
        //console.log(VideoHistoryList)
        const index = ShortVideoHistoryList.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            ShortVideoHistoryList.splice(index, 1);
        }
        this.setState({ShortVideoHistoryList:[item,...ShortVideoHistoryList]});
    }

    //删除短视频历史记录
    removeShortVideoHistory = (idList) => {
        const {ShortVideoHistoryList} = this.state;
        const _ShortVideoHistoryList = ShortVideoHistoryList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({ShortVideoHistoryList:_ShortVideoHistoryList});
    }

    //查找短视频历史记录
    findShortVideoHistory = (Id) => {
        const {ShortVideoHistoryList} = this.state;
        return ShortVideoHistoryList.find(el=>el.Id===Id);
    }

    //移除全部短视频记录
    removeShortVideoAllHistory = () => {
        this.setState({ShortVideoHistoryList:[]});
    }


    //-----------------------------------------------------------------
    //添加漫画历史记录
    addManHuaHistory = (item) => {
        const {ManHuaHistoryList} = this.state;
        const index = ManHuaHistoryList.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            ManHuaHistoryList.splice(index, 1);
        }
        this.setState({ManHuaHistoryList:[item,...ManHuaHistoryList]});
    }

    //删除漫画历史记录
    removeManHuaHistory = (idList) => {
        const {ManHuaHistoryList} = this.state;
        const _ManHuaHistoryList = ManHuaHistoryList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({ManHuaHistoryList:_ManHuaHistoryList});
    }

    //查找漫画历史记录
    findManHuaHistory = (Id) => {
        const {ManHuaHistoryList} = this.state;
        return ManHuaHistoryList.find(el=>el.Id===Id);
    }

    //移除全部漫画记录
    removeManHuaAllHistory = () => {
        this.setState({ManHuaHistoryList:[]});
    }
    //-----------------------------------------------------------------
    //添加小说历史记录
    addXiaoShuoHistory = (item) => {
        const {XiaoShuoHistoryList} = this.state;
        const index = XiaoShuoHistoryList.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            XiaoShuoHistoryList.splice(index, 1);
        }
        this.setState({XiaoShuoHistoryList:[item,...XiaoShuoHistoryList]});
    }

    //删除小说历史记录
    removeXiaoShuoHistory = (idList) => {
        const {XiaoShuoHistoryList} = this.state;
        const _XiaoShuoHistoryList = XiaoShuoHistoryList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({XiaoShuoHistoryList:_XiaoShuoHistoryList});
    }

    //查找小说历史记录
    findXiaoShuoHistory = (Id) => {
        const {XiaoShuoHistoryList} = this.state;
        return XiaoShuoHistoryList.find(el=>el.Id===Id);
    }

    //移除小说漫画记录
    removeXiaoShuoAllHistory = () => {
        this.setState({XiaoShuoHistoryList:[]});
    }








    //-----------------------------------------------------------------
    //-------------------------购买模块---------------------------------
    //-----------------------------------------------------------------
    //添加购买视频记录
    addPayList_Video = (item) => {
        //购买时间
        //console.log( moment())
        item.BuyTime = moment().format('YYYY-MM-DD HH:mm:ss');
        const {PayList_Video} = this.state;
        const index = PayList_Video.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            PayList_Video.splice(index, 1);
        }
        this.setState({PayList_Video:[item,...PayList_Video]});
    }

    //删除购买视频记录
    removePayList_Video = (idList) => {
        //console.log('idList:',idList)
        const {PayList_Video} = this.state;
        console.log('当前:',PayList_Video)
        const tmp = PayList_Video.filter(el=>!idList.find(d=>d===el.Id));
        console.log('修改:',tmp)
        this.setState({PayList_Video:tmp});
        console.log('OK:',PayList_Video)
        //console.log('tmp:',tmp)
        //this.setState({PayList_XiaoShuo:_PayList_XiaoShuo});


        //const {PayList_Video} = this.state;
        //const _PayList_Video = PayList_Video.filter(el=>!idList.find(d=>d===el.Id));
        //this.setState({PayList_Video:_PayList_Video});
        //this.setState({VideoFllowList:_VideoFllowList});
        
    }

    //查找购买视频记录
    findPayList_Video = (Id) => {
        const {PayList_Video} = this.state;
        return PayList_Video.find(el=>el.Id===Id);
    }

    //移除全部购买视频记录
    removeAllPayList_Video = () => {
        this.setState({PayList_Video:[]});
    }

    //我的购买--视频
    setPayList_Video =(data)=>{
        this.setState({PayList_Video:data});
    }

    //---------------------------------------------------
    //添加购买短视频记录
    addPayList_ShortVideo = (item) => {
        const {PayList_ShortVideo} = this.state;
        const index = PayList_ShortVideo.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            PayList_ShortVideo.splice(index, 1);
        }
        this.setState({PayList_ShortVideo:[item,...PayList_ShortVideo]});
    }

    //删除购买短视频记录
    removePayList_ShortVideo = (idList) => {
        const {PayList_ShortVideo} = this.state;
        const _PayList_ShortVideo = PayList_ShortVideo.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({PayList_ShortVideo:_PayList_ShortVideo});
    }

    //查找购买短视频记录
    findPayList_ShortVideo = (Id) => {
        const {PayList_ShortVideo} = this.state;
        return PayList_ShortVideo.find(el=>el.Id===Id);
    }

    //移除全部购买短视频记录
    removeAllPayList_ShortVideo = () => {
        this.setState({PayList_ShortVideo:[]});
    }

    //我的购买--短视频
    setPayList_ShortVideo =(data)=>{
        this.setState({PayList_ShortVideo:data});
    }
    //---------------------------------------------------
    //添加购买漫画记录
    addPayList_ManHua = (item) => {
        const {PayList_ManHua} = this.state;
        const index = PayList_ManHua.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            PayList_ManHua.splice(index, 1);
            console.log(PayList_ManHua)
        }
        this.setState({PayList_ManHua:[item,...PayList_ManHua]});
    }

    //添加购买漫画章节记录
    addPayList_ManHua_List = (data,item) => {
        const {PayList_ManHua} = this.state;
        const index = PayList_ManHua.findIndex(el=>el.Id===data.Id);
        console.log('参数:')
        console.log(index,data,item)
        console.log('内存:')
        console.log(PayList_ManHua)
        if(index>=0){
            //PayList_ManHua.splice(index, 1);
            const listindex = PayList_ManHua[index].List.findIndex(sl=>sl.Id===item.Id);
            console.log('listindex:'+listindex)
            if(listindex>=0){
                console.log('存在该章节:')
                console.log(item)
                return
            }
            //PayList_ManHua[index].List.push(item)
            //return
            //PayList_ManHua[index].List.concat(item)
            //Storage.save('PayList_ManHua',PayList_ManHua);
        }else
        {
            console.log('写入内存:')
            this.setState({PayList_ManHua:[data,...PayList_ManHua]});
        }
        
    }

    //获取购买漫画所有章节记录
    GetPayList_ManHua_List = (data) => {
        const {PayList_ManHua} = this.state;
        const index = PayList_ManHua.findIndex(el=>el.Id===data.Id);
        if(index>=0){
            return PayList_ManHua[index].List;
        }
        return [] 
    }
    

    //删除购买漫画记录
    removePayList_ManHua = (idList) => {
        const {PayList_ManHua} = this.state;
        const _PayList_ManHua = PayList_ManHua.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({PayList_ManHua:_PayList_ManHua});
    }

    //查找购买漫画记录
    findPayList_ManHua = (Id) => {
        const {PayList_ManHua} = this.state;
        return PayList_ManHua.find(el=>el.Id===Id);
    }

    //查找购买漫画章节记录
    findPayList_ManHua_List = (data,item) => {
        const {PayList_ManHua} = this.state;
        const index = PayList_ManHua.findIndex(el=>el.Id===data.Id);
        if(index>=0){
            return PayList_ManHua[index].List.find(el=>el.Id===item.Id);
        }
    }


    //移除全部购买漫画记录
    removeAllPayList_ManHua = () => {
        this.setState({PayList_ManHua:[]});
    }

    //我的购买--漫画
    setPayList_ManHua =(data)=>{
        this.setState({PayList_ManHua:data});
    }

    //---------------------------------------------------
    //添加购买小说记录
    addPayList_XiaoShuo = (item) => {
        const {PayList_XiaoShuo} = this.state;
        const index = PayList_XiaoShuo.findIndex(el=>el.Id===item.Id);
        if(index>=0){
            PayList_XiaoShuo.splice(index, 1);
        }
        this.setState({PayList_XiaoShuo:[item,...PayList_XiaoShuo]});
    }

    //删除购买小说记录
    removePayList_XiaoShuo = (idList) => {
        const {PayList_XiaoShuo} = this.state;
        const _PayList_XiaoShuo = PayList_XiaoShuo.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({PayList_XiaoShuo:_PayList_XiaoShuo});
    }

    //查找购买小说记录
    findPayList_XiaoShuo = (Id) => {
        const {PayList_XiaoShuo} = this.state;
        return PayList_XiaoShuo.find(el=>el.Id===Id);
    }

    //移除全部购买小说记录
    removeAllPayList_XiaoShuo = () => {
        this.setState({PayList_XiaoShuo:[]});
    }

    //我的购买--小说
    setPayList_XiaoShuo =(data)=>{
        this.setState({PayList_XiaoShuo:data});
    }











    setMonneyHistory =(data)=>{
        //console.log(data)
        //alert(data)
        this.setState({MonneyHistory:data});
    }

    
    //-----------------------------------------------------------------
    //-------------------------购买模块---------------------------------
    //-----------------------------------------------------------------










    //-----------------------------------------------------------------
    //-------------------------收藏模块---------------------------------
    //-----------------------------------------------------------------

    //初始化
    initVideoFollow = (list) => {
        this.setState({VideoFllowList:list});
    }

    //添加视频收藏
    addVideoFollow = (item) => {
        const {VideoFllowList} = this.state;
        this.setState({VideoFllowList:[item,...VideoFllowList]});
    }

    //取消视频收藏
    removeVideoFollow = (idList) => {
        const {VideoFllowList} = this.state;
        const _VideoFllowList = VideoFllowList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({VideoFllowList:_VideoFllowList});
    }

    //移除全部视频收藏
    removeVideoAllFollow = () => {
        this.setState({VideoFllowList:[]});
    }

    //查找收藏
    findVideoFollow = (Id) => {
        const {VideoFllowList} = this.state;
        return !!VideoFllowList.find(el=>el.Id===Id);
    }
    
    //设置收藏
    setVideoFollow = (item) => {
        if(this.findVideoFollow(item.Id)){
            this.removeVideoFollow([item.Id]);
            return false
        }else{
            this.addVideoFollow(item);
            return true
        }
    }

    //添加短视频收藏
    addShortVideoFollow = (item) => {
        const {ShortVideoFllowList} = this.state;
        this.setState({ShortVideoFllowList:[item,...ShortVideoFllowList]});
    }

    //取消短视频收藏
    removeShortVideoFollow = (idList) => {
        const {ShortVideoFllowList} = this.state;
        const _ShortVideoFllowList = ShortVideoFllowList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({ShortVideoFllowList:_ShortVideoFllowList});
    }

    //移除全部短视频收藏
    removeShortVideoAllFollow = () => {
        this.setState({ShortVideoFllowList:[]});
    }

    //查找短视频收藏
    findShortVideoFollow = (Id) => {
        const {ShortVideoFllowList} = this.state;
        return !!ShortVideoFllowList.find(el=>el.Id===Id);
    }
    
    //设置短视频收藏
    setShortVideoFollow = (item) => {
        if(this.findShortVideoFollow(item.Id)){
            this.removeShortVideoFollow([item.Id]);
        }else{
            this.addShortVideoFollow(item);
        }
    }

    //-----------------------------------------------------------------

    //添加漫画收藏
    addManHuaFollow = (item) => {
        const {ManHuaFllowList} = this.state;
        this.setState({ManHuaFllowList:[item,...ManHuaFllowList]});
    }

    //取消漫画收藏
    removeManHuaFollow = (idList) => {
        const {ManHuaFllowList} = this.state;
        const _ManHuaFllowList = ManHuaFllowList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({ManHuaFllowList:_ManHuaFllowList});
    }

    //移除全部漫画收藏
    removeManHuaAllFollow = () => {
        this.setState({ManHuaFllowList:[]});
    }

    //查找漫画收藏
    findManHuaFollow = (Id) => {
        const {ManHuaFllowList} = this.state;
        return !!ManHuaFllowList.find(el=>el.Id===Id);
    }
    
    //设置漫画收藏
    setManHuaFollow = (item) => {
        if(this.findManHuaFollow(item.Id)){
            this.removeManHuaFollow([item.Id]);
        }else{
            this.addManHuaFollow(item);
        }
    }

    //-----------------------------------------------------------------

    //添加小说收藏
    addXiaoShuoFollow = (item) => {
        const {XiaoShuoFllowList} = this.state;
        this.setState({XiaoShuoFllowList:[item,...XiaoShuoFllowList]});
    }

    //取消小说收藏
    removeXiaoShuoFollow = (idList) => {
        const {XiaoShuoFllowList} = this.state;
        const _XiaoShuoFllowList = XiaoShuoFllowList.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({XiaoShuoFllowList:_XiaoShuoFllowList});
    }

    //移除全部小说收藏
    removeXiaoShuoAllFollow = () => {
        this.setState({XiaoShuoFllowList:[]});
    }

    //查找小说收藏
    findXiaoShuoFollow = (Id) => {
        const {XiaoShuoFllowList} = this.state;
        return !!XiaoShuoFllowList.find(el=>el.Id===Id);
    }
    
    //设置小说收藏
    setXiaoShuoFollow = (item) => {
        if(this.findXiaoShuoFollow(item.Id)){
            this.removeXiaoShuoFollow([item.Id]);
        }else{
            this.addXiaoShuoFollow(item);
        }
    }
    //-----------------------------------------------------------------


    //添加搜索历史记录
    addSearchHistory = (item) => {
        //alert(123)
        const {SearchList_Video} = this.state;
        //console.log(SearchList_Video)
        const index = SearchList_Video.findIndex(el=>el===item);
        if(index>=0){
            SearchList_Video.splice(index, 1);
        }
        this.setState({SearchList_Video:[item,...SearchList_Video]});
    }

    //删除搜索历史记录
    removeSearchHistory = (idList) => {
        const {SearchList_Video} = this.state;
        const _SearchList_Video = SearchList_Video.filter(el=>!idList.find(d=>d===el.Id));
        this.setState({SearchList_Video:_SearchList_Video});
    }

    //查找搜索历史记录
    findSearchHistory = (Id) => {
        const {SearchList_Video} = this.state;
        return SearchList_Video.find(el=>el===Id);
    }

    //移除全部搜索记录
    removeSearchAllHistory = () => {
        this.setState({SearchList_Video:[]});
    }
    //-----------------------------------------------------------------

    //设置
    setSettings = (type,value) => {
        const _settings = Object.assign({}, this.state.settings, { [type]: value });
        this.setState({
            settings:_settings
        })
    }

    //设置初始化
    initSettings = (settings) => {
        this.setState({settings:settings});
    }


    componentWillUnmount() {
        //应用关闭时保存设置
        const { settings } = this.state;
        Storage.save('settings',settings);
    }

    async componentDidMount() {
        //-----------------浏览记录--------------------
        const data_video_history = await Storage.get('VideoHistoryList')||[];
        this.setState({VideoHistoryList:data_video_history});
        const data_short_video_history = await Storage.get('ShortVideoHistoryList')||[];
        this.setState({ShortVideoHistoryList:data_short_video_history});
        const data_manhua_history = await Storage.get('ManHuaHistoryList')||[];
        this.setState({ManHuaHistoryList:data_manhua_history});
        const data_xiaoshuo_history = await Storage.get('XiaoShuoHistoryList')||[];
        this.setState({XiaoShuoHistoryList:data_xiaoshuo_history});

        //-----------------收藏记录--------------------
        const data_video_fllow = await Storage.get('VideoFllowList')||[];
        this.setState({VideoFllowList:data_video_fllow});
        const data_Short_video_fllow = await Storage.get('ShortVideoFllowList')||[];
        this.setState({ShortVideoFllowList:data_Short_video_fllow});
        const data_manhua_fllow = await Storage.get('ManHuaFllowList')||[];
        this.setState({ManHuaFllowList:data_manhua_fllow});
        const data_xiaoshuo_fllow = await Storage.get('XiaoShuoFllowList')||[];
        this.setState({XiaoShuoFllowList:data_xiaoshuo_fllow});


        //-----------------购买记录--------------------
        const data_PayList_Video = await Storage.get('PayList_Video')||[];
        this.setState({PayList_Video:data_PayList_Video});
        const data_PayList_ShortVideo = await Storage.get('PayList_ShortVideo')||[];
        this.setState({PayList_ShortVideo:data_PayList_ShortVideo});
        const data_PayList_ManHua = await Storage.get('PayList_ManHua')||[];
        this.setState({PayList_ManHua:data_PayList_ManHua});
        const data_PayList_XiaoShuo = await Storage.get('PayList_XiaoShuo')||[];
        this.setState({PayList_XiaoShuo:data_PayList_XiaoShuo});



        const data_MonneyHistory = await Storage.get('MonneyHistory')||[];
        this.setState({MonneyHistory:data_MonneyHistory});
        
        //console.log(data_PayList_Video)

        //const data_fllow = await Storage.get('VideoFllowList')||[];
        //this.setState({VideoFllowList:data_fllow});


        const data_SearchList_Video = await Storage.get('SearchList_Video')||[];
        this.setState({SearchList_Video:data_SearchList_Video});

        const data_settings = await Storage.get('settings')||[];
        this.setState({settings:data_settings});


        

        const data_UserInfo = await Storage.get('UserInfo')||[];
        //console.log(data_UserInfo)
        this.setState({UserInfo:data_UserInfo});

    }
    

    componentDidUpdate(prevProps, prevState) {
        //改变时保存
        //-----------------浏览记录--------------------
        if (prevState.VideoHistoryList !== this.state.VideoHistoryList) {
            Storage.save('VideoHistoryList',this.state.VideoHistoryList);
        }
        if (prevState.ShortVideoHistoryList !== this.state.ShortVideoHistoryList) {
            Storage.save('ShortVideoHistoryList',this.state.ShortVideoHistoryList);
        }
        if (prevState.ManHuaHistoryList !== this.state.ManHuaHistoryList) {
            Storage.save('ManHuaHistoryList',this.state.ManHuaHistoryList);
        }
        if (prevState.XiaoShuoHistoryList !== this.state.XiaoShuoHistoryList) {
            Storage.save('XiaoShuoHistoryList',this.state.XiaoShuoHistoryList);
        }

        //-----------------收藏记录--------------------
        if (prevState.VideoFllowList !== this.state.VideoFllowList) {
            Storage.save('VideoFllowList',this.state.VideoFllowList);
        }
        if (prevState.ShortVideoFllowList !== this.state.ShortVideoFllowList) {
            Storage.save('ShortVideoFllowList',this.state.ShortVideoFllowList);
        }
        if (prevState.ManHuaFllowList !== this.state.ManHuaFllowList) {
            Storage.save('ManHuaFllowList',this.state.ManHuaFllowList);
        }
        if (prevState.XiaoShuoFllowList !== this.state.XiaoShuoFllowList) {
            Storage.save('XiaoShuoFllowList',this.state.XiaoShuoFllowList);
        }


        //-----------------购买记录--------------------
        if (prevState.PayList_Video !== this.state.PayList_Video) {
            Storage.save('PayList_Video',this.state.PayList_Video);
        }
        if (prevState.PayList_ShortVideo !== this.state.PayList_ShortVideo) {
            Storage.save('PayList_ShortVideo',this.state.PayList_ShortVideo);
        }
        if (prevState.PayList_ManHua !== this.state.PayList_ManHua) {
            Storage.save('PayList_ManHua',this.state.PayList_ManHua);
        }
        if (prevState.PayList_XiaoShuo !== this.state.PayList_XiaoShuo) {
            Storage.save('PayList_XiaoShuo',this.state.PayList_XiaoShuo);
        }



        if (prevState.MonneyHistory !== this.state.MonneyHistory) {
            Storage.save('MonneyHistory',this.state.MonneyHistory);
        }
        if (prevState.SearchList_Video !== this.state.SearchList_Video) {
            Storage.save('SearchList_Video',this.state.SearchList_Video);
        }
        

        if (prevState.settings !== this.state.settings) {
            Storage.save('settings',this.state.settings);
            //console.warn('settings save');
        }

        if (prevState.UserInfo !== this.state.UserInfo) {
            Storage.save('UserInfo',this.state.UserInfo);
            //console.warn('UserInfo save');
        }
    }

    render(){
        const {UserInfo,
            VideoHistoryList,
            ShortVideoHistoryList,
            ManHuaHistoryList,
            XiaoShuoHistoryList,


            VideoFllowList,
            ShortVideoFllowList,
            ManHuaFllowList,
            XiaoShuoFllowList,

            //搜索关键字
            SearchList_Video,
            settings,

            //购买视频记录部分
            PayList_Video,
            PayList_ShortVideo,
            PayList_ManHua,
            PayList_XiaoShuo,

            //消费明细,
            MonneyHistory


        } = this.state;
        return(
            <Store.Provider value={{

                //浏览历史
                VideoHistoryList:VideoHistoryList,
                ShortVideoHistoryList:ShortVideoHistoryList,
                ManHuaHistoryList:ManHuaHistoryList,
                XiaoShuoHistoryList:XiaoShuoHistoryList,
                
                //收藏
                VideoFllowList:VideoFllowList,
                ShortVideoFllowList:ShortVideoFllowList,
                ManHuaFllowList:ManHuaFllowList,
                XiaoShuoFllowList:XiaoShuoFllowList,

                //购买视频记录部分
                PayList_Video:PayList_Video,
                PayList_ShortVideo:PayList_ShortVideo,
                PayList_ManHua:PayList_ManHua,
                PayList_XiaoShuo:PayList_XiaoShuo,

                //消费明细
                MonneyHistory:MonneyHistory,

                //initHistory:this.initHistory,
                UserInfo:UserInfo,
                setUserInfo:this.setUserInfo,

                addVideoHistory:this.addVideoHistory,
                removeVideoHistory:this.removeVideoHistory,
                findVideoHistory:this.findVideoHistory,
                removeVideoAllHistory:this.removeVideoAllHistory,

                addShortVideoHistory:this.addShortVideoHistory,
                removeShortVideoHistory:this.removeShortVideoHistory,
                findShortVideoHistory:this.findShortVideoHistory,
                removeShortVideoAllHistory:this.removeShortVideoAllHistory,


                addManHuaHistory:this.addManHuaHistory,
                removeManHuaHistory:this.removeManHuaHistory,
                findManHuaHistory:this.findManHuaHistory,
                removeManHuaAllHistory:this.removeManHuaAllHistory,

                addXiaoShuoHistory:this.addXiaoShuoHistory,
                removeXiaoShuoHistory:this.removeXiaoShuoHistory,
                findXiaoShuoHistory:this.findXiaoShuoHistory,
                removeXiaoShuoAllHistory:this.removeXiaoShuoAllHistory,

                

                //initFollow:this.initFollow,
                addVideoFollow:this.addVideoFollow,
                removeVideoFollow:this.removeVideoFollow,
                findVideoFollow:this.findVideoFollow,
                setVideoFollow:this.setVideoFollow,
                removeVideoAllFollow:this.removeVideoAllFollow,

                addShortVideoFollow:this.addShortVideoFollow,
                removeShortVideoFollow:this.removeShortVideoFollow,
                findShortVideoFollow:this.findShortVideoFollow,
                setShortVideoFollow:this.setShortVideoFollow,
                removeShortVideoAllFollow:this.removeShortVideoAllFollow,


                addManHuaFollow:this.addManHuaFollow,
                removeManHuaFollow:this.removeManHuaFollow,
                findManHuaFollow:this.findManHuaFollow,
                setManHuaFollow:this.setManHuaFollow,
                removeManHuaAllFollow:this.removeManHuaAllFollow,

                addXiaoShuoFollow:this.addXiaoShuoFollow,
                removeXiaoShuoFollow:this.removeXiaoShuoFollow,
                findXiaoShuoFollow:this.findXiaoShuoFollow,
                setXiaoShuoFollow:this.setXiaoShuoFollow,
                removeXiaoShuoAllFollow:this.removeXiaoShuoAllFollow,
                

                addPayList_Video:this.addPayList_Video,
                removePayList_Video:this.removePayList_Video,
                findPayList_Video:this.findPayList_Video,
                removeAllPayList_Video:this.removeAllPayList_Video,
                setPayList_Video:this.setPayList_Video,

                addPayList_ShortVideo:this.addPayList_ShortVideo,
                removePayList_ShortVideo:this.removePayList_ShortVideo,
                findPayList_ShortVideo:this.findPayList_ShortVideo,
                removeAllPayList_ShortVideo:this.removeAllPayList_ShortVideo,
                setPayList_ShortVideo:this.setPayList_ShortVideo,


                addPayList_ManHua:this.addPayList_ManHua,
                addPayList_ManHua_List:this.addPayList_ManHua_List,
                removePayList_ManHua:this.removePayList_ManHua,
                findPayList_ManHua:this.findPayList_ManHua,
                findPayList_ManHua_List:this.findPayList_ManHua_List,
                removeAllPayList_ManHua:this.removeAllPayList_ManHua,
                GetPayList_ManHua_List:this.GetPayList_ManHua_List,
                setPayList_ManHua:this.setPayList_ManHua,

                addPayList_XiaoShuo:this.addPayList_XiaoShuo,
                removePayList_XiaoShuo:this.removePayList_XiaoShuo,
                findPayList_XiaoShuo:this.findPayList_XiaoShuo,
                removeAllPayList_XiaoShuo:this.removeAllPayList_XiaoShuo,
                setPayList_XiaoShuo:this.setPayList_XiaoShuo,

                addSearchHistory:this.addSearchHistory,
                removeSearchHistory:this.removeSearchHistory,
                findSearchHistory:this.findSearchHistory,
                removeSearchAllHistory:this.removeSearchAllHistory,

                //消费明细
                setMonneyHistory:this.setMonneyHistory,

                SearchList_Video:SearchList_Video,
                settings:settings,
                initSettings:this.initSettings,
                setSettings:this.setSettings,
            }}>
                {this.props.children}
            </Store.Provider>
        )
    }
}