import { Toast } from 'native-base';

export function showToast(message: string) {
    Toast.closeAll()
    Toast.show({
        title: message,
        duration: 800,
        // style: {
        //   backgroundColor: Colors.red
        // }
    });
}