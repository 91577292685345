import React, {useEffect,useState} from 'react';
import {RefreshControl,BackHandler,Platform,ImageBackground,Image,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';
//
//import { WebView } from './WebView/index';
import { WebView } from 'react-native-webview';
import { NativeWebView } from './native-webview';

export default function ({navigation,route}:any) {

  React.useLayoutEffect(() => {
      navigation.setOptions({
          headerBackTitleVisible:false,
          title:route.params.title,
      });
  }, [route.params.title]);



  return(
     <View style={{flex:1}}>
        <NativeWebView target={route.params.url}/>
     </View>
   );
}
