import "react-native-gesture-handler";
import React,{useState,useEffect} from 'react';
import { AppState, Text } from 'react-native';
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { NativeBaseProvider } from 'native-base';
//import { useLoadedAssets } from "./hooks/useLoadedAssets";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { useColorScheme } from "react-native";
import {Provider} from 'react-redux';
import {store} from './src/Store/index';
import { StoreProvider } from './src/Utils/store';
import * as Device from 'expo-device';

import { BaseTheme } from './src/theme';
//import config from './nativebase.config';
import Navigation from "./navigation/index";
import Update from './navigation/Update_Test';  
import {isWeb} from './src/Utils/Index'

export default function App() {
  //const isLoadingComplete = useLoadedAssets();
  const [startTime, seStartTime] = useState(new Date().getTime());
  const colorScheme = useColorScheme();

  useEffect(() => {
    const handleChange = (nextState) => {
      //(window.navigator.standalone 判断苹果APP图标全屏启动
      //console.log('nextState:',window.navigator)
      if(isWeb && window.navigator.standalone == true) { 
        //应用已经安装而且为全屏模式 ,超过60秒后，APP刷新
        if(Device.osName =="iOS" || Device.osName =="IOS" || Device.osName =="apple" || Device.osName =="Mac OS" || Device.osName =="MacOS"){
          if(nextState == "background"){
            seStartTime(new Date().getTime())
          }
          else if(nextState == "active"){
            var curTime = (new Date().getTime() - startTime) / 1000
            if(curTime >= 60){
              window.location.reload();
            }
          } 
        }
      } 
    };

    const subscription = AppState.addEventListener('change', handleChange);
    return () => {
      subscription.remove();
    };
  }, []);


  
 

  return (
    <GestureHandlerRootView style={{flex:1}}>
      <SafeAreaProvider>
        <NativeBaseProvider theme={BaseTheme}>
        <Provider store={store}>
          <StoreProvider>
                <Navigation colorScheme={colorScheme} />
          </StoreProvider>
        </Provider>
        {/* <StatusBar 1232 /> */}
        <StatusBar backgroundColor="transparent" translucent={true} />
        <Update/>
      </NativeBaseProvider>
   
      </SafeAreaProvider>

    </GestureHandlerRootView>
  );

}