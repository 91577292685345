 import React, {useEffect,useState} from 'react';
 import { showToast } from '@/Components/Toast';

 import moment from "moment";
 import {NativeModules,Platform,Linking,Alert,RefreshControl,ImageBackground,Image,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';
 //import Clipboard from '@react-native-clipboard/clipboard'; //剪贴板
 import * as Clipboard from 'expo-clipboard';
 import { MaterialCommunityIcons,AntDesign,MaterialIcons,Ionicons,EvilIcons,Entypo,FontAwesome,Feather } from '@expo/vector-icons';
 import AsyncStorage from '@react-native-async-storage/async-storage';

 //Redux
 import {updateUser,updateNotific,updateSearchInfo,updateSearch, updatePayList,updateMonneyList,updateMonneyIndex,updateMonneyItem, updateRunAd,updateAdvertInfo,updateLoginStatus, updateQrCodeModal} from '../../Actions/userActions';
 import {useDispatch} from 'react-redux';
 import {useSelector} from 'react-redux';
 import MoreCommonItem from "./MoreCommonItem";
 import UserInfo from "./ErWeiMa/qrcode";

 //api
 import {API_User_GetUserData,API_User_Login} from '../../API/Index';


 function MeIndex({navigation}:any) {
    const dispatch = useDispatch();
    const User:any = useSelector<any>((state) => state.user.userdata);            //后台配置
    const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);     //后台配置


    const [AppVersion,setAppVersion]=useState('')
    const [Refreshing,setRefreshing] = useState(false)
   

    React.useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
          // do something
          //console.log(888888)
          onConfirm()
        });
    
        return unsubscribe;
    }, [navigation]);
      
    const _onPressLogout = () => {
        AsyncStorage.removeItem('data');
        AsyncStorage.removeItem('noticeInfos');
        dispatch(updateUser(null));
        dispatch(updateNotific(null));
        dispatch(updateSearchInfo(null));
        dispatch(updateSearch(null));//搜索内容
        
        dispatch(updatePayList(null));
        dispatch(updateMonneyList(null));
        dispatch(updateMonneyIndex(null));
        dispatch(updateMonneyItem(null));
        dispatch(updateRunAd(null));
        dispatch(updateAdvertInfo(null,null))
        dispatch(updateLoginStatus(-1))
   };


    const _onPress5 = () => {
        //转跳外部浏览器
        if(settingInfo.CustomerType==true){
            //navigation.navigate("WebView",{title:'我的客服', url:settingInfo.CustomerServiceUrl})
            Linking.openURL(settingInfo.CustomerServiceUrl)
            .catch(err =>{
                if(err.message)
                {
                    //Toast.add(err.message);
                }   
            });
        }else{
            //内置浏览器
            navigation.navigate("WebView",{title:'我的客服', url:settingInfo.CustomerServiceUrl})
        }
    }

 
    const _onPressWebBrowser = () => {
        navigation.navigate("WebBrowser","1")
    }


    const _onPress8 = async () => {
        navigation.navigate("DeviceInfo")
    }

    async function Copy() {
        const str = User.ExtensionCode
        await Clipboard.setStringAsync(str)
        showToast("复制成功!")
      }
    
    
    const onConfirm = async (show = false) => {
        try {
            const ret = await API_User_GetUserData(
                {
                    Id:User.Id,
                }
            )
            dispatch(updateUser(ret))
            if(show){
                showToast('刷新成功!')
                //Toast.add('刷新成功!')
                // toast.show({
                //     description: "刷新成功!",
                //     duration:1000
                //   })
            }  
        }
        catch(e){
            if(show){
                showToast("刷新失败!"+e)
            }
        }
    }

    function TabBarIcon1(props:any) {
        return <AntDesign size={24} style={{ marginBottom: -3 }} {...props} />;
    }

      

   return(
      <View style={{
        //backgroundColor:'#fff'
        flex:1
        }}>
            <ScrollView>
                <View style={{
                    backgroundColor:'#fff',
                    marginBottom:6,
                    borderBottomLeftRadius:30,
                    borderBottomRightRadius:30,
                    shadowOffset: {width: 0, height: 5},
                    shadowOpacity: 0.3,
                    shadowRadius: 5,
                    //让安卓拥有灰色阴影
                    elevation: 4,}}>
                        <View style={{marginHorizontal:12, marginTop:30,marginBottom:30, flexDirection:'row',alignItems:'center'}}>
                            <Image source={require("./assets/icon_header.png")} style={styles.avatar} />
                            <View style={{flex:1,marginLeft:20, marginTop:0,}}>
                            <View style={{ flexDirection:'row'}}>
                                <Text style ={{
                                    fontWeight:'bold',
                                    fontSize: 16 ,
                                    color:'#000'}}>ID:{User.ExtensionCode}</Text>
                                <TouchableOpacity style={{}} onPress={() => {
                                        Copy();
                                    }}>
                                    <Text style ={{
                                        fontSize: 14,
                                        color:'#000',textDecorationLine:'underline',textDecorationStyle:'dotted'}}> 复制 </Text>
                                </TouchableOpacity>
                            </View>
                            {
                                User.IsVip?
                                <Text style ={{color:'#ff7500',
                                marginTop:6,
                                }}>会员时间: {moment((User.VipTime)).format('YYYY-MM-DD')}</Text>
                                :
                                <Text style ={{color:'#000',
                                marginTop:6,
                                }}>您还不是VIP会员</Text>
                            }
            
                            {
                                User.Money>0?
                                <Text style ={{color:'#ff7500',
                                marginTop:6,
                                }}>金币余额: {User.Money}</Text>
                                :
                                <Text style ={{color:'#000',
                                marginTop:6,
                                }}>您还不是金币玩家</Text>
                            }


                        </View>
                    </View>

            {/* 刷新信息按钮 */}

            <TouchableOpacity onPress={() => {
                onConfirm(true);
                }}
                style={{
                    height:36,
                        width:36,
                        position:'absolute',
                        top:30,
                        right:16
                }}>
                <Image source={require("./assets/icon_person_refresh.png")} style={{height:36,width:36,}}/>
            </TouchableOpacity>

                </View>

                <ScrollView>

                <TouchableOpacity onPress={()=>navigation.navigate("Pay",{title:"vips"})}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="wallet" color={'#000'} />}
                            leftTitle='会员中心'
                        />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=>dispatch(updateQrCodeModal(true))}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="qrcode" color={'#000'} />}
                            leftTitle='账号凭证'
                        />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=>navigation.navigate("RetrieveAccount")}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="scan1" color={'#000'} />}
                            leftTitle='找回账号'
                        />
                    </TouchableOpacity>


                    <TouchableOpacity onPress={()=>navigation.navigate("Task")}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="gift" color={'#000'} />}
                            leftTitle='邀请好友'
                        />
                    </TouchableOpacity>
                    {/* <TouchableOpacity onPress={()=>navigation.navigate("MonneyHistory")}>
                        <MoreCommonItem
                            leftIconName={require('./assets/icon_bill_btn.png')}
                            leftTitle='消费明细'
                        />
                    </TouchableOpacity> */}

                    <TouchableOpacity onPress={()=>navigation.navigate("PayHistory")}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="shoppingcart" color={'#000'} />}
                            leftTitle='我的购买'
                        />
                    </TouchableOpacity>


                    <TouchableOpacity onPress={()=>navigation.navigate("History")}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="clockcircleo" color={'#000'} />}
                            leftTitle='观看历史'
                        />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=>navigation.navigate("Fllow")}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="staro" color={'#000'} />}
                            leftTitle='我的收藏'
                        />
                    </TouchableOpacity>

                    <TouchableOpacity onPress={_onPress5}>
                        <MoreCommonItem
                            icon ={<TabBarIcon1 name="customerservice" color={'#000'} />}
                            leftTitle='在线客服'
                        />
                    </TouchableOpacity>

                    {/* <TouchableOpacity onPress={_onPressWebBrowser}>
                        <MoreCommonItem
                            leftIconName={require('./assets/icon_version_btn.png')}
                            leftTitle={"浏览器"}
                            //rightTitle={App.appVersion+"-"+App.buildVersion}
                        />
                    </TouchableOpacity> */}

                    <TouchableOpacity onPress={_onPress8}>
                        <MoreCommonItem
                            icon ={<MaterialIcons name="error-outline" size={24} color="#000" />}
                            leftTitle={"系统信息"}
                        />
                    </TouchableOpacity>



                    {/* { <TouchableOpacity onPress={_onPressLogout}>
                        <MoreCommonItem
                            leftTitle={"退出登录"}
                        />
                    </TouchableOpacity> } */}

                    <View style={{height:200}}></View>
                </ScrollView>
            </ScrollView>
            <UserInfo/>
      </View>
    );
 }
 
 const styles = StyleSheet.create({
    myPage: {
        backgroundColor: '#f5f5f5',
    },
    photo: {
        height: 200,
    },
    person_bg: {
        flex:1,
        height: 60,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    topIconLeft: {
        fontSize: 20,
        color: "#FFFFFF",
        paddingLeft: 20,
        marginTop: -20,
        transform: [{ rotate: "90deg" }]
    },
    topIconRight: {
        fontSize: 20,
        color: "#FFFFFF",
        paddingRight: 6
    },
    avatar: {
        width: 70,
        height: 70,
        borderColor:'#fff',
        marginLeft:10,

    },
});

 
 export default MeIndex;
 