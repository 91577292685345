import React,{useEffect, useState} from 'react';
import {
    Text,
    View,
    Platform,
} from 'react-native';
import * as Device from 'expo-device';
import DeviceInfo from 'react-native-device-info';
import { getUniqueId, syncUniqueId,getManufacturer } from 'react-native-device-info';

export default function(){
    const [DeviceInfo ,setDeviceInfo]:any = useState(null);

    const getDeviceTypeAsync= async()=>{
        return await Device.getDeviceTypeAsync()
    }

    useEffect(() => {
        const GetDeviceInfoSyn= async()=>{
            var data= await getUniqueId()
            //var getManufacturer = await syncUniqueId();
            console.log(data)
            setDeviceInfo(data)
        }
        GetDeviceInfoSyn()
      }, [])
    
    //  console.log(Device.manufacturer)
    return (
        <View style={{flex:1,alignItems:'center',justifyContent:'center'}}>
            <Text>版本:{Device.osVersion}</Text> 
            <Text>型号:{Device.modelName}</Text>
            <Text>系统:{Device.osName}</Text>
            <Text>Os:{Platform.OS}</Text>
            <Text>Os2:{DeviceInfo}</Text>
        </View>
    );
}
