import { StatusBar } from 'expo-status-bar';
import React, { FC, useState,useEffect,useRef } from 'react'
import { InteractionManager,Button,StyleSheet, Text, View } from 'react-native';
import {API_ShortVideo_GetDataListByNext} from '@/API/Index';
import { useFocusEffect } from "@react-navigation/native";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import type { Swiper as SwiperType } from "swiper";
import { EffectFade,Pagination } from "swiper";



import Video from './Video';
import './index.css'
//import './style.css'

export default function App({navigation}:any) {

    const swiperRef = useRef(null);
    const [tab, setTab] = useState(1);
    const [active, setActive] = useState(0);
    const ref = useRef(null);
    const [isVertical, setIsVertical] = React.useState(true);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);

    const [IsEnd, setIsEnd] = useState(false);
    const [isActive,setisActive] = useState(false);

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const updateIndex = (swiperInstance: SwiperType) => {
        if (swiperInstance === null) return;
        const currentSlide = swiperInstance?.activeIndex;
        //console.log("swiperInstance:",swiperInstance.el.style.zIndex=2000)
        
        setCurrentIndex(currentSlide)

        if(currentSlide >= (data.length -3)){
            setPage(page + 1);
        }
    }
    
    useFocusEffect(
        React.useCallback(() => {
          const task = InteractionManager.runAfterInteractions(() => {
            setisActive(true)
          });
      
          return () => {
            setisActive(false)
            task.cancel();
          }
        }, [])
      );
      
    const fetchData = async () => {
        try {
            const result:any = await API_ShortVideo_GetDataListByNext({Next:page})
            //console.log(result)
            if(page==1){
                await setData(result);
            }else{
                setData(data.concat(result));
            }
            if(result.length <= 0){
              setIsEnd(true)
            }
        } catch (error) {
            setTimeout(() => {
                fetchData();
            }, 5000);
        }
    };
    
    useEffect(() => {
        fetchData()
    }, [page])




  return ( 
    <Swiper
        //loop
        speed={0}
        direction={"vertical"}
        initialSlide={currentIndex}
        onActiveIndexChange={updateIndex}
        // pagination={{
        //   clickable: true,
        // }}
        // modules={[Pagination]}
    
        className="mySwiper"
        // onClick={function(e){
        //     console.log("e:",e)
        // }}
      >
        {data.map((item, index) =>{
          //console.log(item)
          return(
                  <SwiperSlide key={index} virtualIndex={index}>
                     {( state ) => {
                      //console.log(index,state)
                      return(
                        (index == currentIndex)||(index == currentIndex+1)||(index == currentIndex-1)||(state.isNext == true)||(index == currentIndex+2)||(index == currentIndex-2)||(state.isPrev == true)  ?
                        <Video item={item}  IsPlay={currentIndex==index && isActive} Index={index} navigation={navigation} />
                        :null
                    )}}
                  </SwiperSlide>
          )
          })}
      </Swiper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
