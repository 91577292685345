import React,{useEffect,useState,useContext} from 'react';
import { useFocusEffect } from "@react-navigation/native";
import { Button, View,InteractionManager,AppState } from 'react-native';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useInterval} from '../src/Hooks'
import {API_User_GetUserData,API_User_GetServerTime} from '../src/API/Index';

import {updateServerTime,updateToken, updateNotific,updateSearchInfo ,updateUser,updateSettingInfo,updateRunAd, updateLoginStatus,updateAdvertInfo,updateNavigatorListInfo} from '../src/Actions/userActions';
import { useNavigation } from '@react-navigation/native';

export default function() {
    const dispatch = useDispatch();
    const navigation:any = useNavigation();
    const CurRoute:any = navigation.getCurrentRoute();
    //console.log(navigation.getCurrentRoute())
    const User:any = useSelector<any>((state) => state.user.userdata);            //后台配置
    const ServerTime = useSelector((state:any) => state.user.ServerTime);

    // useEffect(() => {
    //   if(CurRoute){
    //     console.log(CurRoute)
    //     if(window.ReactNativeWebView){
    //       window.ReactNativeWebView.postMessage(JSON.stringify({route:CurRoute}));
    //     }

    //   }
    // },[CurRoute])

    const GetUserInfo = async () => {
      try {
          const ret = await API_User_GetUserData(
              {
                  Id:User.Id,
              }
          )
          dispatch(updateUser(ret))
          return ret
 
      }
      catch(e){

      }
    }

    const AppChange = (state:any)=>{
        if(state == "active"){
            API_User_GetServerTime()
            .then(res=>{
                //console.log("服务器时间:",res)
                dispatch(updateServerTime(res.toString()))//服务器时间
            })
        }
    }

    useEffect(() => {
      AppState.addEventListener('change',AppChange)
    },[])

    useInterval(() => {
        //console.log(ServerTime)
        const time = moment(ServerTime).format('YYYY-MM-DD HH:mm:ss')
        const add = moment(time).add(1, 'seconds').format('YYYY-MM-DD HH:mm:ss')
        dispatch(updateServerTime(add.toString()))//服务器时间
    }, 1000);

    useInterval(() => {
      GetUserInfo()
    }, 60000);


    return (
      null
    );


  
}