export const DnsServerList = [
  {
    url:'https://dns.alidns.com/resolve?type=16&name=asdjkas.com',    //阿里云
    data:[],
    link:false
  },
  {
    //url:'https://1.12.12.12/dns-query?type=16&name=asdjkas.com',       //腾讯云国密
    url:'https://sm2.doh.pub/resolve?type=16&name=asdjkas.com',       //腾讯云国密
    data:[],
    link:false
  },
  {
    url:'https://101.101.101.101/dns-query?type=16&name=asdjkas.com', //台湾Quad
    data:[],
    link:false
  },
  // {
  //   url:'https://101.102.103.104/dns-query?type=16&name=asdjkas.com', //台湾Quad
  //   data:[],
  //   link:false
  // },
  {
    url:'https://hk-hkg.doh.sb/dns-query?type=16&name=asdjkas.com',   //香港SB
    data:[],
    link:false
  },
  {
    url:'https://sg-sin.doh.sb/dns-query?type=16&name=asdjkas.com',   //新加坡SB
    data:[],
    link:false
  },
]
