import React,{useEffect,useRef,useState} from 'react';
import { Image, TouchableOpacity,Platform, View,StatusBar,Dimensions } from 'react-native';
import UUID from './uuid'
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import { getUniqueId} from 'react-native-device-info';
export const GetPlatform = {
    isAndRoid: Platform.OS === 'android',
    isIOS: Platform.OS === 'ios',
    isWeb: Platform.OS === 'web',
}
  
export const isAndRoid = Platform.OS === 'android';
export const isIOS = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';

export async function GetUID() {
  var memdata = await AsyncStorage.getItem('UUID');
  //console.log("1",memdata)
  if(memdata == null || memdata == "unknown"){
    var date = new Date().getTime();
    var uuidFirst:any = UUID.fromTime(date, false);
    //console.log(uuidFirst)
    await AsyncStorage.setItem('UUID', uuidFirst.hex);
    return uuidFirst.hex
  }else{
    return memdata
    
  }
}

export async function GetUniqueId() {
  return await getUniqueId();
}

// export async function GetUID() {
//   var memdata = await AsyncStorage.getItem('UUID');
//   return memdata
// }

export async function SetUID(str:any) {
    await AsyncStorage.setItem('UUID', str);
}


  // 状态栏高度
const StatusBarHeight = StatusBar.currentHeight || 0;
// 屏幕宽度
const deviceWidth = Dimensions.get('window').width;
// 屏幕高度
const deviceHeight = Dimensions.get('window').height;
// 获取屏幕内容高度
const viewHeight = deviceHeight - StatusBarHeight;

const screen = {
  StatusBarHeight,
  deviceWidth,
  deviceHeight,
  viewHeight
};

export default screen;
export { StatusBarHeight, deviceWidth, deviceHeight, viewHeight };
