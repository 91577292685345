import React, {useEffect} from 'react';
import {Modal,TouchableWithoutFeedback,Image,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';


function ModalIndex({Item,isShow, setisShow,onConfirm}:any) {

   

    return(
        <Modal
        //淡入淡出动画
        animationType={"fade"}
        //设置有背景层
        transparent={true}
        // 设置是否显示(只要修改这个值，就可以显示和消失了)
        visible={isShow}
        // 按后退键，去掉对话框
        onRequestClose={() => {
            setisShow(false)
        }}
        >

        <TouchableOpacity style={{ justifyContent: 'flex-end',flex:1,backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                onPress={() => {
                    setisShow(false);
                }}
            >
        

            <View style={{backgroundColor:'#fff'}}>
                <View style={{backgroundColor:"#fff",flexDirection:'row'}}>  
                    <View style={{marginTop:12,marginBottom:12, backgroundColor:'#fff',flex:0.97,alignItems:'center'}} >
                        <Text style={{marginLeft:20, color:'#000',fontSize:16}} >确认支付</Text> 
                    </View>

                    <View style={{marginTop:10,flexDirection:'row',alignItems:'center'}} >
                        <TouchableOpacity
                            activeOpacity={1}
                            underlayColor="transparent"
                            onPress={() => setisShow(false)}>
                            <Image source={require("./imgs/icon_close_gray.png")} style={{marginRight:12, width:16,height:16}}></Image>
                        </TouchableOpacity>
                    </View>
                </View>  
                
                <View style={{backgroundColor:"#fff",flexDirection:'row',alignItems:'center',justifyContent:'center'}}>  
                    <Text style ={{color:'#000',fontSize:23}}>¥</Text>
                    <Text style ={{color:'#ff7500',fontSize:38}}>{Item?.Price}.00</Text>
                </View>
                
                <View style={{marginTop:30,marginHorizontal: 20,flexDirection:'row',alignItems: "center",justifyContent: "space-between"}}>  
                    <Text style={{fontSize:16}}>订单详情</Text>  
                    <Text style={{fontSize:16,color:'#000'}}>充值</Text>  
                </View> 
                <View style={{marginTop:25,marginHorizontal: 20,flexDirection:'row',alignItems: "center",justifyContent: "space-between"}}>  
                    <Text style={{fontSize:16}}>商品名称</Text>  
                    <Text style={{fontSize:16,color:'#000'}}>{Item?.ChannelTitle}</Text>  
                </View>
                
                
    
                <TouchableOpacity onPress={() => {
                    setisShow(false);
                    onConfirm();
                }}
            style={{backgroundColor:"#ff7500",justifyContent:'center',alignItems:'center',height:78,marginTop:30}}>  
                    <Text style={{fontSize:20,marginTop:10, marginBottom:40, color:'#fff'}}>立即付款</Text>  
                </TouchableOpacity> 
            </View> 
        </TouchableOpacity>   
    </Modal>
    )
}

export default ModalIndex;
