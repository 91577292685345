import React,{useEffect, useState} from 'react';
import {
  Image as RnImage,
  Pressable,
  TouchableOpacity,
  StyleProp, ViewStyle,
   View, Text
  }
  from 'react-native';

import axios from 'axios';
import { CachedImage} from './Image-Cache';
import { Image } from 'expo-image'
import { Buffer } from 'buffer'
import {useSelector} from 'react-redux';

function toBase64(input:any) {
  return Buffer.from(input, 'utf-8').toString('base64')
}




export const Cell = ({item}:any) => {
  const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);     //后台配置

  const DecryptIMG =(array:any)=>{
    const bytes = new Uint8Array(array);
    for (let index = 0; index < 100; index++) {
      bytes[index] = bytes[index] ^ (settingInfo.DecryptKey || 968542557)
    }
    return bytes
  }
  
  const [ImageSource ,setImageSource]:any = useState(null);



  function getExtension(filename:any) {
    return filename.split('.').pop()
  }

  const ImageDecrypt = async (source:any)=> {
    if(source ){
      try {
        
        const ext = getExtension(source)
        if(ext == 'web' || ext == 'ceb'){
          const response = await axios.get(source,{responseType:'arraybuffer'})
          const data = DecryptIMG(response.data)
          const path = 'data:image/jpeg;base64,'+toBase64(data)
          if (path) {
            item.AdvertCover= path
            setImageSource(path)
            return
          }
        }else{
          return
        }
      } catch (error) {
      }
    }

  }
  useEffect(() => {
    if(item.AdvertCover.indexOf("data:image/jpeg;base64")>-1){
      setImageSource(item.AdvertCover)
    }else{
      ImageDecrypt(item.AdvertCover)
    }
    
  }, [item])

  //console.log(ImageSource)
  


  return (
    <View style={{flex:1}}>
      <Image
        recyclingKey={item.Id}
          style={{
            flex:1,
            width: null as any,
            height: null as any,
            backgroundColor: '#eee',
            borderRadius:8
          }}
        placeholder={require('../../assets/images/prepare.png')}
        placeholderContentFit='fill' //图片会花屏
        source={item.AdvertCover}
        contentFit='fill'
      />
    </View>
  );
};