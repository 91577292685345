 import React, {useEffect,useState,useCallback,useRef} from 'react';
 import {PermissionsAndroid, Platform,Image,RefreshControl,ActivityIndicator,Dimensions,Button,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity, ImageBackground, Alert} from 'react-native';
 //import Shadow from './Shadow';
 //import FxSave from './FxSave';
 import Qrcode from './Qrcode';

 function Index({navigation}) {
   

  return (
      <View style={{flex:1, backgroundColor: '#ECEFF1',justifyContent:'center',alignItems:'center'}}>
         {/* <ImageBackground source={require('./images/fx.jpg')} style={{flex:1,flexDirection:'column-reverse'}}>  */}
         <Qrcode/>
          {/* <FxSave/> */}
          
        {/* </ImageBackground>  */}
      </View>
    )
 }

export default Index;

const styles = StyleSheet.create({
  root: {
    //backgroundColor: '#ECEFF1',
    backgroundColor: '#ECEFF1',
  },
  container: {
    paddingVertical: 80,
    alignItems: 'center',
  },
});
