import React, {useEffect,useState,useContext} from 'react';
import {StatusBar,
  //Image,
  Linking,StyleSheet, View, Text, TouchableOpacity, SafeAreaView} from 'react-native';

//Redux
import {updateRunAd,updateLoginStatus} from '../src/Actions/userActions';
import {useDispatch,useSelector} from 'react-redux';

import axios from 'axios';
import {Image} from 'expo-image'
import { Buffer } from 'buffer'
import { CachedImage, CacheManager } from '../src/Components/Image-Cache';
//Time
import {useInterval} from '../src/Hooks'

function toBase64(input:any) {
  return Buffer.from(input, 'utf-8').toString('base64')
}

function AdScreen() {
  const dispatch = useDispatch();
  const [modalVisible,setmodalVisible] = useState(false)
  const RunAd = useSelector((state:any) => state.user.RunAd);
  const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);     //后台配置
  const [ImageSource ,setImageSource]:any = useState(null);
  console.log("state.user.RunAd:",RunAd)

  if(!RunAd.Data){
    dispatch(updateLoginStatus(1))
    return null
  }

  
  var Num =Math.floor(Math.random()*RunAd.Data.length);
  //console.log(Num)
  //随机数 所得到的的是0-DataCount之间的随机数，每次刷新都不同
  const [data,setdata] = useState(RunAd.Data[Num])
  const [Count,setCount] = useState(RunAd.Space ? RunAd.Space : 0)

  //console.log("AD:",RunAd.Data,Count)

  useInterval(() => {
    if (Count >= 1) {
      setCount(Count - 1);
    } else {
      if(!RunAd.IsClose){
        onPress()
      }
    }
  }, 1000);
  

  const DecryptIMG =(array:any)=>{
    const bytes = new Uint8Array(array);
    for (let index = 0; index < 100; index++) {
      bytes[index] = bytes[index] ^ (settingInfo.DecryptKey || 968542557)
    }
    return bytes
  }

  function getExtension(filename:any) {
    return filename.split('.').pop()
  }

  const ImageDecrypt = async (source:any)=> {
    if(source ){
      try {
        
        const ext = getExtension(source)
        if(ext == 'web' || ext == 'ceb'){
          const response = await axios.get(source,{responseType:'arraybuffer'})
          const Decryptdata = DecryptIMG(response.data)
          const path = 'data:image/jpeg;base64,'+toBase64(Decryptdata)
          if (path) {
            data.AdvertCover = path
            setImageSource(path)
            return
          }
        }else{
          return
        }
      } catch (error) {
      }
    }

  }

  useEffect(() => {
    console.log(data.AdvertCover)
    if(data.AdvertCover.indexOf("data:image/jpeg;base64")>-1){
      setImageSource(data.AdvertCover)
    }else{
      ImageDecrypt(data.AdvertCover)
    }
    
  }, [data])


  const onPress=()=>{
    //dispatch(updateRunAd(null))
    dispatch(updateLoginStatus(1))
  }

  async function onPressImages() {
    try {
      Linking.openURL(data.AdvertUrl) 
    } catch (error) {
      
    }
  }
  if(!data){
    //等待进入app
    return <View style={{flex:1,
      backgroundColor:'#ff7500'
      }}>
    </View>
  }



  return (
    <View style={{flex:1}}>
      <StatusBar backgroundColor="transparent" translucent={true} />
      {
        data.AdvertCover?
        <TouchableOpacity onPress={onPressImages} style={{flex:1}}>
          <Image
            recyclingKey={"Run_AD"}
              style={{
                flex:1,
                width: null as any,
                height: null as any,
                //backgroundColor: '#eee',
                ///borderRadius:8
              }}
              // placeholder={require('../../assets/images/prepare.png')}
              placeholderContentFit='fill' //图片会花屏
              source={data.AdvertCover}
              contentFit='fill'
          />

          {/* <CachedImage
            source={data.AdvertCover}
          /> */}

        </TouchableOpacity>
      :null
      }
      {
        Count >= 1 ?
          <View style={{position:'absolute',top:40,right:0,
            backgroundColor:'rgba(0,0,0,0.3)',
            borderBottomLeftRadius:18,borderTopLeftRadius:18}}>
            <Text style={{marginVertical:8,marginHorizontal:30,color:'#fff'}}>{Count}</Text>
        </View>
      : 
        <TouchableOpacity onPress={onPress} style={{position:'absolute',top:40,right:0,
          backgroundColor:'rgba(0,0,0,0.3)',
          borderBottomLeftRadius:18,borderTopLeftRadius:18}}>
          <Text style={{marginVertical:8,marginHorizontal:30,color:'#fff'}}>跳过</Text>
        </TouchableOpacity>
      }
    </View>
   
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default AdScreen;
