import { useEffect, useState } from 'react';
import { Html5Qrcode,Html5QrcodeScanner } from 'html5-qrcode';
import {View,Text,Pressable,SafeAreaView, Image,StyleSheet} from 'react-native';


export const Html5QrcodePlugin = ({modalVisible,setModalVisible,onScanSuccess,onScanFailure}:any ) => {
    const [html5Qrcode,setHtml5Qrcode]:any = useState(null)
    const [scan,setScan] = useState(false)

    function init() {
        //html5Qrcode = new Html5Qrcode('reader')
        const a:any = new Html5Qrcode('reader')
        setHtml5Qrcode(a)
        
    }

    async function start() {
        await html5Qrcode.start(
            { facingMode: 'environment' },
            {
                fps: 10,
                qrbox: { width: 250, height: 250 },
            },
            ScanSuccess,
            ScanFailure
        )
        setScan(true)
    }

    async function stop() {
        setScan(false)
        await html5Qrcode.stop()
        setModalVisible(false)
       
    }

    function ScanSuccess(decodedText:any, decodedResult:any) {
        onScanSuccess(decodedText)
    }

    function ScanFailure(error:any) {
        //console.warn(`Code scan error = ${error}`)
    }

    useEffect(() => {
        if(modalVisible== true){
            //alert('123')
            init()
        }
    }, [modalVisible]);

    useEffect(() => {
        if(html5Qrcode){
            start()
        }
    }, [html5Qrcode]);
 

  return <View style={{flex:1}}>
    <div  id="reader"/>
    {
        scan
        ?
        <>
        <Pressable onPress={()=>stop()}
        style={{
          marginTop:20,
          backgroundColor:'#ff7500',
          shadowOpacity: 0.3,
          shadowRadius: 5,
          shadowColor:'#000',
          //让安卓拥有灰色阴影
          elevation: 4,
          height:30,borderRadius:10,justifyContent: 'center',alignItems: 'center',}}>
          <Text style={{margin:10,color:'#fff',fontSize:14,fontWeight:'bold'}}>停止</Text>
      </Pressable>
      </>
        :
        <>
        <Pressable onPress={()=>start()}
        style={{
          marginTop:20,
          backgroundColor:'#ff7500',
          shadowOpacity: 0.3,
          shadowRadius: 5,
          shadowColor:'#000',
          //让安卓拥有灰色阴影
          elevation: 4,
          height:30,borderRadius:10,justifyContent: 'center',alignItems: 'center',}}>
          <Text style={{margin:10,color:'#fff',fontSize:14,fontWeight:'bold'}}>扫一扫</Text>
      </Pressable>
      </>
    }
  </View>
};
export default Html5QrcodePlugin;

