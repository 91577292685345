import React,{ useState, useRef,useEffect,useMemo,useContext } from 'react';
import { 
  //SafeAreaView, 
  PanResponder,
  Image,
  Text,View,Dimensions,Pressable } from "react-native"
import { SafeAreaView } from 'react-native-safe-area-context';
import moment from "moment";
import {useSelector} from 'react-redux';
import List from './List'
import VideoPlayer from './VideoPlayer/VideoPlayer.web'
import { useWindowSize } from './VideoPlayer/screen';
import { Store } from '@/Utils/store';

export default function Index({navigation,route}:any){
  const {addVideoHistory,removePayList_Video,PayList_Video}:any = useContext(Store);
  const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);  //后台配置

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [width, height] = useWindowSize()

  const DelBuyVideo=()=>{
    if(settingInfo.BuyTimeOut){
      PayList_Video.forEach((V:any) => {
        if(V.BuyTime){
          const Time =moment(V.BuyTime).add(settingInfo.BuyTimeOut, 'minute').format('YYYY-MM-DD HH:mm:ss'); 
          if(moment().format('YYYY-MM-DD HH:mm:ss') >Time){
            removePayList_Video([V.Id])
          }
        }
      });
    }
  }
  function deviceOrientation() {
    var body = document.body;
    switch(window.orientation) {
      case 90:
        setIsFullscreen(true)
        break;
      case -90:
        setIsFullscreen(true)
        break;
      default:
        setIsFullscreen(false)
        break;
    }
  }

  useEffect(() => {
    DelBuyVideo()
    addVideoHistory(route.params.Data)
    window.addEventListener('orientationchange', deviceOrientation);
  },[])

  const [videoWidth, videoHeight] = useMemo(() => {
    if (isFullscreen) {
        return [
            height,
            width
        ]
    }
    else return [
        width,
        height * 0.3
    ]
}, [width, height, isFullscreen])

  return (
    <SafeAreaView edges={isFullscreen ? ['left','right']:['top']} style={{flex:1,backgroundColor:'#000'}}>
       <VideoPlayer 
        width={videoWidth}
        height={videoHeight}
        navigation={navigation}
      />
    </SafeAreaView>
     
  );
}


