import React, {useEffect,useState,useContext} from 'react';
import {Image,ScrollView,ImageBackground,Dimensions,TouchableOpacity,Text, View, StyleSheet } from 'react-native';
import {useSelector} from 'react-redux';
//import Modal from "react-native-modal";
const themeColor = '#ff7500'
import { Button, Modal } from 'native-base';
import { openBrowserAsync } from 'expo-web-browser';
import { Cell} from './Cell.Notice';

export default function({Item}) {
    const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);     //后台配置
    const [modalVisible, setModalVisible] = useState(true);
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    if(Item.Type == 1){
      return(
        <Modal
          initialFocusRef={initialRef} finalFocusRef={finalRef}
          //backdropVisible={false}
          isOpen={modalVisible} 
          //onClose={() => setModalVisible(!modalVisible)}
          //style={{flex:1,flexDirection:'row'}}
          >
            <View style={styles.centeredView}>
              <TouchableOpacity style={{flex:1,}} onPress={()=>{
                  openBrowserAsync(Item.NoticeUrl)
                }}>
                <Cell item={Item} settingInfo={settingInfo}/>
            </TouchableOpacity>
            <View style={{alignItems:'center'}}>
                <TouchableOpacity onPress={()=>setModalVisible(!modalVisible)}
                  style={{
                    marginTop:30,
                    backgroundColor:themeColor,
                    shadowOpacity: 0.3,
                    shadowRadius: 5,
                    shadowColor:'#000',
                    //让安卓拥有灰色阴影
                    elevation: 4,
                    height:30,width:80,borderRadius:10,justifyContent: 'center',alignItems: 'center',}}>
                    <Text style={{color:'#fff',fontSize:14,fontWeight:'bold'}}>关闭</Text>
                  </TouchableOpacity>
              </View> 
            </View>
        </Modal>
      )
    }else if(Item.Type == 0){
      return(
        <Modal
        initialFocusRef={initialRef} finalFocusRef={finalRef}
        //backdropVisible={false}
        isOpen={modalVisible} 
        //onClose={() => setModalVisible(!modalVisible)}
          >
        <View style={styles.centeredView}>
          <View style={styles.top}>
              <Text style={{
                  marginTop:20,
                  color:'#fff',
                  fontSize:20,
                  fontWeight:'bold'
              }}>公告</Text>
              <Image style={{
                  width:'100%',
                  height:62,
              }} resizeMode="stretch" source={require('../../assets/images/updata.png')} />
          </View>
        
          <View style={{flex:1,
              backgroundColor:'#fff',
              borderBottomLeftRadius:10,
              borderBottomRightRadius:10
            }}>
                <ScrollView style={{
                  marginBottom:10
                  }}>
                  <Text style={{
                    margin:12,
                    marginVertical:20,
                      fontSize:16,
                      color:'#666',
                      lineHeight:24
                  }}>{Item.NoticeContent}</Text>
                </ScrollView>
          </View>

          <View style={{alignItems:'center'}}>
            <TouchableOpacity onPress={()=>setModalVisible(!modalVisible)}
              style={{
                marginTop:30,
                backgroundColor:themeColor,
                shadowOpacity: 0.3,
                shadowRadius: 5,
                shadowColor:'#000',
                //让安卓拥有灰色阴影
                elevation: 4,
                height:30,width:80,borderRadius:10,justifyContent: 'center',alignItems: 'center',}}>
                <Text style={{color:'#fff',fontSize:14,fontWeight:'bold'}}>关闭</Text>
              </TouchableOpacity>
          </View>
        </View>
      </Modal>
      )
    }


    return(
      null
    )
}


const styles = StyleSheet.create({
  centeredView: {
    width:'80%',height:'70%', 
  },
  top:{
    alignItems:'center',
    backgroundColor:themeColor,
    borderRadius:10
  }

});