import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View 
} from 'react-native';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';
import {Image} from 'expo-image'

import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {DnsServerList} from '../../Utils/Dns';
import {addDnsServer,updateLoginStatus,updateDnsServer,updateServerIP} from '../../Actions/userActions';
import Dns_Info from './Dns_Info';
import AES from '../../Utils/Aes';

const LinkSleep = 0;

export default function (){
  const [SplashImage,setSplashImage] = useState(require('../../../assets/images/splash.png'))
  const dispatch = useDispatch();
  //const aaa = '6C96A21067080C0073737A992735E25324D49DEE25FD02682C5782F4D2B02B67'
  const aaa = 'A98F711F935A20A7629BE2E861D15DC236708EC61A127AE3248B8E33FE8BEBEC'
  const bbb = AES.Decrypt(aaa)
  //console.log('bbb:',bbb)
  //const ccc = '{"Server":"https://116.205.151.16"}'
  //const ccc = 'https://njndaq.com'
  //const ccc = 'https://axmbhsdw.com'
  const ccc = 'https://116.205.151.16'
  const ddd = AES.Encrypt(ccc)
  //console.log('ddd:',ddd)
  

  const DnsServer = useSelector((state:any) => state.user.DnsServer);


  const ListView = DnsServerList.map((data,index) =>{
  //console.log(1111, DnsServerList)
  return <View key={index}  style={{
    margin:4
  }}>
      <Dns_Info data={data} index={index}/>
    </View>
  })

    

  useEffect(() => {
    dispatch(updateDnsServer(DnsServerList))
  }, [DnsServerList])

    const Check = () =>{
      let dns:any = null;
      let netms = 99999;
      let arraylist:any = [];
      if(DnsServer.length > 0){

          let a = 0
          DnsServer.map((data:any,index:any)=>{
            if(data.link){
              a = a + 1
            }
            if(data.data.length > 0 && data.Time > 0 && data.Time <= netms){
              netms = data.Time;
              dns = data
            }
          })
          //console.log(a)
          if(a >= DnsServerList.length){
            if(dns){
              //console.log(a,netms,dns)1
              const Answer = dns.data
              Answer.forEach((Domain:any) => {
                const str = JSON.parse(Domain.data)
                let arr = str.split("-");
                const DomainString = AES.Decrypt(arr[0])
                //console.log(DomainString)
                if(DomainString){
                  let tmp:any = {}
                  tmp.Index = arraylist.length; //根据数组长度，添加索引
                  tmp.Addr = DomainString;
                  tmp.Time = -1
                  tmp.State = -1
                  arraylist.push(tmp)
                }
                
              })
              if(arraylist.length > 0){
                //console.log(new Date(), "域名:",arraylist,netms)
                dispatch(updateServerIP(arraylist))
                setTimeout(() => {
                  //SetState(1)
                  dispatch(updateLoginStatus(1));
                }, LinkSleep);
              }else{
                //SetState(3)
              }
              
            }else{
              //SetState(3)
            }
          }
          
        
      }
    }

    useEffect(() => {
      Check()
    }, [DnsServer])



    return (
      <View style={{flex:1,
        //backgroundColor:'#ff7500'
        }}>
          <Image
            recyclingKey={"RunImage"}
              style={{
                flex:1,
                width: null as any,
                height: null as any,
              }}
            source={SplashImage}
            contentFit='cover'
          />
           <View style={{position:'absolute',left:0,right:0,top:0,bottom:0,justifyContent:'center',alignItems:'center',}}>
            <View style={{backgroundColor:'rgba(0,0,0,0.6)',padding:22,borderRadius:8}}>
               <Spinner style={{marginBottom:12}} size="lg" color="#fff" />
               {ListView}
            </View>
        </View>
      </View>
    );
}

const styles = StyleSheet.create({
  container: {
    marginTop:10,
    flexDirection:'column',
  },
});
