import React, {useEffect,useState,useContext} from 'react';
import {Platform,Image,RefreshControl,ActivityIndicator,Dimensions,Button,SafeAreaView, StyleSheet, ScrollView, View, Text, StatusBar, TouchableOpacity} from 'react-native';
import { MasonryFlashList, MasonryListItem,FlashList } from "@shopify/flash-list"

import {Recycler} from '@/Components/Recycler/Index';
 //Redux
import {useDispatch} from 'react-redux';
import {updateVideo} from '@/Actions/userActions';

import { Store } from '@/Utils/store';
import {Cell} from '@/Components/Cell';

import { viewHeight } from '@/Utils/Index';

export default function ({navigation}) {
   const dispatch = useDispatch();
   const {PayList_Video} = useContext(Store);
   
   const renderHeader = () => {
       return (
           null
       );
     };


   const onPress = (data:any) => {
       dispatch(updateVideo(data))
       navigation.navigate('Player', {Data: data});
     }

   const renderItem=({data})=>{
       return <Cell item={data} Click={onPress} />
     }


   const renderFooter = () => {
       return(
       <View style={{alignItems:'center',marginBottom:30}}>
           <Text style={{margin:10}}>到底部啦！</Text>
       </View>
       )
   };

   const Init =[{Type:"HEADER"}]
 
   return (
       <View style={{ 
           paddingBottom: Platform.OS !== 'web' ? 10 : 0,
           // web端需要减去标题高度
           height: Platform.OS === 'web' ? viewHeight - 42 : viewHeight,
       }}>
       {
       PayList_Video.length > 0 ? 
           <Recycler 
               items={Init.concat(PayList_Video)} 
               // Next={Next} 
               // Click={onPress}
               numColumns={2}
               headerHeight={0}
               itemHeight={160}
               renderHeader={renderHeader}
               renderItem={renderItem}
               renderFooter={renderFooter}
               gap={6}
           />
       :
     
               <View style={{flex:1,flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                   <Image resizeMode={'stretch'} source={require('../../../../assets/images/icon_empty_data.png')} style={{width:140, height:140}}/>
                   <Text>暂无数据</Text>
               </View>
       }
     </View>
   )
}
