import React, {Profiler, useCallback, useMemo,ReactText} from 'react';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';

import {View,ActivityIndicator,Dimensions} from 'react-native';
import {DataProvider, LayoutProvider, RecyclerListView,ScrollViewProps,RecyclerListViewProps} from 'recyclerlistview';
import {Cell} from './Cell';
import {Data} from './Data';

const ViewTypes = {
  HEADER: 'HEADER',
  ITEM: 'ITEM',
};
export interface RecyclerFlatListProps
  extends Partial<
    Pick<
      RecyclerListViewProps,
      | 'renderFooter'
      | 'onEndReached'
      | 'onEndReachedThreshold'
      | 'onScroll'
      | 'renderAheadOffset'
      | 'initialOffset'
      | 'initialRenderIndex'
    >
  > {
  items: [];
  Next:any;
  Click:any;
  data: T[];
  itemHeight: number;
  headerHeight?: number;
  marginHorizontal?: number;
  gap?: number;
  numColumns?: number;
  //keyExtractor: (item: T) => string;
  renderHeader?: () => JSX.Element;
  renderItem?: () => JSX.Element;
  scrollViewProps?: ScrollViewProps;
}

interface RecyclerProps {
  items: [];
  Next:any;
  Click:any;
  headerHeight?: number;
  itemHeight: number;
  marginHorizontal?: number;
  gap?: number;
  numColumns?: number;
}

const getContainerWidth = (margin = 0) => {
    // To deal with precision issues on android
    // return Math.round(Dimensions.get('window').width * 1000) / 1000 - 6; //Adjustment for margin given to RLV;
    return Math.round(Dimensions.get('window').width * 1000) / 1000 - (margin * 2)
  }
  
//const {width: screenWidth, height: screenHeight} = Dimensions.get('screen');

export const Recycler = ({items,Next,Click,
  headerHeight=200,
  itemHeight=60,
  numColumns = 2,
  marginHorizontal = 10,
  gap = 6,
  renderAheadOffset = Dimensions.get('window').height,
  renderHeader,
  renderItem,
  renderFooter,
  scrollViewProps
}) => {

  const layoutProvider = useMemo(() => {
    return new LayoutProvider(
      (index) => {
        if (index === 0 && renderHeader) return ViewTypes.HEADER;
        return ViewTypes.ITEM;
      },
      (type, dim,index) => {
        const itemWidth = getItemWidth(type, false);
        switch (type) {
          case ViewTypes.HEADER:
              dim.width = itemWidth;
              dim.height = headerHeight;
              break;
          case ViewTypes.ITEM:
              dim.width = itemWidth;
              dim.height = itemHeight;
              break;
          default:
              break;
        }

        // dim.width = itemWidth;
        // dim.height = itemHeight;
      },
    );
  }, []);

  const provider = useMemo(() => {
    return new DataProvider(
      (left, right) => {
        return left !== right;
      },
      index => {
        // TODO: should be fetched from data
        return 'id:' + index;
      },
    );
  }, []);

  const dataProvider = useMemo(() => {
    return provider.cloneWithRows(items);
  }, [items, provider]);


  /**
   * 得到每个列表项的宽度
   * header默认返回屏幕宽度
   * 列表项根据每列展示数量确定宽度
   */
  const getItemWidth = (type: string | number, isCrossRow = false) => {
    const containerWidth = getContainerWidth(marginHorizontal);
    if (type === ViewTypes.HEADER || isCrossRow) {
      return containerWidth;
    }
    return containerWidth / numColumns;
  };

  /** 得到每一行的宽高 */
  const getRowDimensions = (type, index: number) => {
    const item = dataProvider.getDataForIndex(index);
    const isCrossRow = false;
    return {
      width: getItemWidth(type, isCrossRow),
      height: itemHeight,
    };
  };


  const rowRenderer = useCallback((type, data,index) => {
    if (type === ViewTypes.HEADER) return renderHeader ? renderHeader() : null;
    //const preData = dataProvider.getAllData().slice(0, index);
    //const preCrossCount = preData.filter(_ => _.isCrossRow).length || 0;

    // 解决裁切问题。Android 默认会裁切，但是 ios 不裁切会超出当前设定的宽高度
    const { width = 0, height = 0 } = getRowDimensions(type, index);
    let paddingLeft = gap / numColumns;
    let paddingRight = gap / numColumns;

    // 支持多行的同时，支持 gap 间距。需要分别找出 item 在 row 中的位置。最左边？最右边？中间？
    const curIndex = index //- preCrossCount;
    const isRowStart = curIndex % numColumns === 1;
    const isRowEnd = curIndex % numColumns === 0;
    const isRowMiddle = !isRowStart && !isRowEnd;

    if (isRowStart) {
      paddingLeft = 0;
      paddingRight = gap / 2;
    }

    if (isRowEnd) {
      paddingLeft = gap / 2;
      paddingRight = 0;
    }
    if (isRowMiddle) {
      paddingLeft = gap / 2;
      paddingRight = gap / 2;
    }

    if (data.isCrossRow) {
      paddingLeft = 0;
      paddingRight = 0;
    }
    return (
      <View
      style={{
        //flex:1,
        overflow: 'hidden',
         height,
         width,
      }}
    >
      <View
        style={{
          flex:1,
          paddingLeft,
          paddingRight,
          paddingTop: gap,
        }}
      >
         {renderItem({ type, data, index })}
      </View>
    </View>
    );
  }, []);

  // const renderFooter = () => {
  //    {/* <ActivityIndicator size={30} color="blue" /> */}
  //   return (
  //     <Spinner size="lg" color="warning.500" />
  //   );
  // };

  return (
    <RecyclerListView
      contentContainerStyle={{
      //backgroundColor:'red',
      marginHorizontal: marginHorizontal }}
      layoutProvider={layoutProvider}
      dataProvider={dataProvider}
      canChangeSize
      rowRenderer={rowRenderer}
      onEndReached={() => {
        if(Next){
          Next()
        }
        //console.log('onEndReached');
      }}
      renderFooter={renderFooter}
      scrollViewProps={{
        ...scrollViewProps,
        //contentContainerStyle,
      }}
    />
  );
};