import React, { Component } from 'react';
import {Platform, StyleSheet, Text, View,TextInput,Image,StatusBar, ImageBackground} from 'react-native';
import { AntDesign,Ionicons,EvilIcons,Entypo,FontAwesome,Feather } from '@expo/vector-icons';

export default class CommonItem extends Component {
    static defaultProps = {
        leftIconName:undefined,
        leftTitle: '',
        rightIconName:undefined,
        rightTitle: ''
    }
    rightSubView(){
        if (this.props.rightIconName == undefined) {
            return (
                <Text style={{color:'gray'}}>
                    {this.props.rightTitle}
                </Text>
            );
        } else {
            return (
                <Image source={this.props.rightIconName} style={{width: 24, height: 13}}/>
            )
        }
    }

    render() {

        return (
            <View style={styles.containStyle}>
                {/*左边*/}
                <View  style={styles.leftViewStyle}>
                    {
                        this.props.icon && this.props.icon
                    }
                    <Text style={{color:'#000', marginLeft:10}}>
                        {this.props.leftTitle}
                    </Text>
                </View>


                {/*右边*/}
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    {/* //右面的文字，有的item有，有的item没有，这就需要使用函数动态生成 */}
                    {this.rightSubView()}
                    <AntDesign name="right" size={24} color="#ddd" />
                </View>

             </View>
        );
    }
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props) {
    return <FontAwesome size={24} style={{ marginBottom: -3 }} {...props} />;
  }

  
const styles = StyleSheet.create({
    leftImgStyle:{
        width:24,
        height:24,
        marginLeft:6,
        //圆形图片
        //borderRadius:12
    }
    ,
    leftViewStyle:{
        flexDirection:'row',
        alignItems:'center',
        marginLeft:8,
    },

    containStyle:{
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        height:48,
        margin:6,
        backgroundColor:'#fff',
        marginHorizontal:12,
        shadowOffset: {width: 0, height: 5},
        shadowOpacity: 0.3,
        shadowRadius: 5,
        //shadowColor: '#ddd',
        //让安卓拥有灰色阴影
        elevation: 4,
    }
});
