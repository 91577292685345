import React, { useState } from 'react';
import {
  Image,
  StyleSheet,
  View,
  Platform,
  TouchableOpacity,
  Text,
  Pressable
} from 'react-native'; 
import * as Device from 'expo-device';
import {FontAwesome5,Feather,Zocial,Ionicons,Entypo,Fontisto,FontAwesome } from '@expo/vector-icons';
 //Redux
 import {useDispatch} from 'react-redux';
 import {useSelector} from 'react-redux';

import {API_User_Bind} from '@/API/Index'

export default function ({navigation,setIsDownload}:any){
  const User:any = useSelector<any>((state) => state.user.userdata);            //后台配置
  
  //const [IsDownload,setIsDownload] = useState(false)
  //console.log(User)
  // function isMobileSafari() {
  //   return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
  // }
  // const isIOSSafari = !!window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  // console.log(isIOSSafari)

  const syncSleep =(time:any) =>{
    const start = new Date().getTime();
    while (new Date().getTime() - start < time) {}
  }

  const fetchData = async () => {
    setIsDownload(true)
    try {
        const result = await API_User_Bind({MechineCode:User.MechineCode})
        syncSleep(1000)
        console.log(result)
        // toast.show({
        //   description: "开始下载",
        //   duration:1000
        // })
        
    } catch (error) {
        console.log("error:",error)
        // setTimeout(() => {
        //     fetchData();
        // }, 5000);
    }
    setIsDownload(false)
  };



  return (
    <View style={{flexDirection:'row',alignItems:'center',backgroundColor:'#ff7500',padding:10}}>
      {/* <Image source={require('../../../assets/images/favicon.png')} style={{resizeMode:'contain',width:36,height:28}}/> */}
      <TouchableOpacity style={{borderRadius:20,backgroundColor:'#eee',flex:1,height:28,flexDirection:'row',
        alignItems:'center',
        marginHorizontal:10
        }} 
        //activeOpacity={0.7}
        onPress={()=>
          navigation.navigate('Search')
        }>
        <Text style={{position:'absolute',paddingLeft:10,color:'#bbbaba',fontSize:14}}>输入您要搜索的影片番号或片名</Text>
        {/* <TabBarIcon name="search" color={'#ff7500'} /> */}
        <Image source={require('./Search-New/images/icon_search.png')} style={{position:'absolute',resizeMode:'contain',right:16,width:18,height:18}}/>
      </TouchableOpacity>
      {/* {
        (Platform.OS=='web' && Device.osName=='android') ?
          <Pressable 
          onPress={()=>fetchData()}>
          <Text style={{marginHorizontal:6, fontSize:11,color:'#fff'}}>下载APP</Text>
          </Pressable>
        :null
      } */}

      
 
    </View>
  );
}


function TabBarIcon(props:any) {
  return <FontAwesome5 size={20} style={{ marginLeft:8 }} {...props} />;
}