import React, {useState,useRef,useEffect} from 'react';
import {Alert,Animated, TouchableOpacity,
  StyleSheet, Text, Image,Pressable, View,ScrollView} from 'react-native';
import {useSelector} from 'react-redux';
import NoticeCell from './NoticeCell';

const Notice = () => {

    const List = useSelector((state) => (state.user.notificdata))     //后台配置
    const TabList = List.map(Item => (<NoticeCell key={Item.Id} Item={Item}/>));
    return (
        <View>
        {
            TabList
        }
        </View>
    );
  };

export default Notice;