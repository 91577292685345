import * as React from 'react';
import { Linking,
    //Image,
    Dimensions, Text, View,TouchableOpacity } from 'react-native';
import Carousel from 'react-native-reanimated-carousel';

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {Image} from 'expo-image'
import { Cell} from '@/Components/Cell.Ad';


function Index() {
    const width = Dimensions.get('window').width;
    const Ad = useSelector((state:any) => state.user.PlayerAd);

    const Click =  (data) => {
        try{
            Linking.openURL(data.AdvertUrl) 
        }catch
        {
            //Toast.add('未知错误：失败！')
        }
    }
    return (
        <View style={{ flex: 1 ,
        //backgroundColor:'red'
        }}>
            <Carousel
                loop
                width={width-20}
                autoPlay={true}
                data={Ad.Data}
                autoPlayInterval={Ad.Space*1000}
                scrollAnimationDuration={1000}
                renderItem={({ item,index }) => (
                    <TouchableOpacity 
                        activeOpacity={1}
                        onPress={() =>Click(item)}
                        style={{
                            flex: 1,
                            //borderWidth: 0.5,
                            justifyContent: 'center',
                        }}
                        >
                        <Cell
                            item={item}
                        />
                    </TouchableOpacity>
                )}
            />
        </View>
    );
}

export default Index;