// Learn more about Light and Dark modes:
// https://docs.expo.dev/guides/color-schemes/
import {
  Text as DefaultText,
  useColorScheme,
  View as DefaultView,
} from "react-native";
import { SafeAreaView as DefaultSafeAreaView } from 'react-native-safe-area-context';

import Colors from "../constants/Colors";



export const VideoThemeColor = {
  primary: '#fff',
  warn: '#e80e0e',
  transparent: 'transparent',
  whiteA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  blackA: (alpha = 1) => `rgba(0, 0, 0, ${alpha})`,
  grayA: (alpha = 1) => `rgba(128, 128, 128, ${alpha})`,
}

export function useThemeColor(props, colorName) {
  const theme = useColorScheme();
  const colorFromProps = props[theme];

  if (colorFromProps) {
    return colorFromProps;
  } else {
    return Colors[theme][colorName];
  }
}

export function Text(props) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const color = useThemeColor({ light: lightColor, dark: darkColor }, "text");

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background"
  );

  return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}

export function SafeAreaView(props) {
  const { style, lightColor, darkColor, ...otherProps } = props;
  const backgroundColor = useThemeColor(
    { light: lightColor, dark: darkColor },
    "background"
  );

  return <DefaultSafeAreaView style={[{ backgroundColor }, style]} {...otherProps} />;
}