import React,{useEffect, useState} from 'react';
import {
  Image as RnImage,
  Pressable,
  TouchableOpacity,
  StyleProp, ViewStyle,
   View, Text,StyleSheet
  }
  from 'react-native';

import axios from 'axios';

import { Image } from 'expo-image'
import { Buffer } from 'buffer'
import {useSelector} from 'react-redux';

function toBase64(input:any) {
  return Buffer.from(input, 'utf-8').toString('base64')
}




export const Cell = ({item,Click}:any) => {
  const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);     //后台配置

  const DecryptIMG =(array:any)=>{
    const bytes = new Uint8Array(array);
    for (let index = 0; index < 100; index++) {
      bytes[index] = bytes[index] ^ (settingInfo.DecryptKey || 968542557)
    }
    return bytes
  }

  const [ImageSource ,setImageSource]:any = useState(null);

  let TypeTxt = ''
  if(item.Type == 0){
    TypeTxt="VIP"
  }else if(item.Type == 1){
    TypeTxt = item.Monney
  }else{
    TypeTxt="免费"
  }

  function getExtension(filename:any) {
    return filename.split('.').pop()
  }

  const ImageDecrypt = async (source:any)=> {
    if(source ){
      try {
        
        const ext = getExtension(source)
        if(ext == 'web' || ext == 'ceb'){
          const response = await axios.get(source,{responseType:'arraybuffer'})
          const data = DecryptIMG(response.data)
          const path = 'data:image/jpeg;base64,'+toBase64(data)
          if (path) {
            item.TagCover= path
            setImageSource(path)
            return
          }
        }else{
          return
        }
      } catch (error) {
      }
    }

  }
  useEffect(() => {
    if(item.TagCover.indexOf("data:image/jpeg;base64")>-1){
      setImageSource(item.TagCover)
    }else{
      ImageDecrypt(item.TagCover)
    }
    
  }, [item])

  //console.log(ImageSource)
  


  return (
    <Pressable
      onPress={()=>Click(item)}
      style={{
        //backgroundColor:'red',
        flex: 1,
      }}>
        <View style={{flex:1,borderRadius:8,borderColor:'#000',borderWidth:1}}>
            <Image
            recyclingKey={item.Id}
              style={{
                flex:1,
                width: null as any,
                height: null as any,
                backgroundColor: '#eee',
                borderRadius:8
              }}
              placeholder={require('../../../assets/images/prepare.png')}
              placeholderContentFit='fill' //图片会花屏
              source={item.TagCover}
              contentFit='fill'
            />
  
          <View style={styles.control}>
              <Text numberOfLines={1} style={{color:'#fff',fontSize:14,fontWeight:'bold'}}>{item.VideoTag}</Text>
          </View>
        </View>
      
    </Pressable>
  );
};

const styles = StyleSheet.create({
  control: {
      //flex:1,
      flexDirection: 'row',
      height: 34,
      width:'100%',
      alignItems:'center',
      justifyContent:'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderBottomLeftRadius:10,
      borderBottomRightRadius:10
  },
});