import PropTypes from 'prop-types';
import React, { Component,useContext } from 'react';

import classNames from 'classnames';
import {Modal, View ,Text,TouchableOpacity} from 'react-native';

import { AntDesign,MaterialIcons } from '@expo/vector-icons';


 //模块
 import { Store } from '@/Utils/store'


const propTypes = {
  player: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.string,
};

const defaultProps = {
  position: 'left'
};

export default class RenderBuy extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    //alert('123')
    //this.props.onBack()
  }
  
  render() {
    const {player, className, position} = this.props;

    const {isShow,Item,User,showModal, setShowModal,onPress,navigation} = this.props;
    
    const Title = Item.Type == 0 ? '开通会员观看完整版' : '支付金币观看完整版'

    if (!isShow) return null
    return (
      <a
        ref={c => {
          this.button = c;
        }}
        className={classNames(
          className,
        )}
        type="button"
        aria-live="polite"
        onClick={this.handleClick}

        style={{
          flex:1,
          position:'absolute',
          left:0,
          top:0,
          right:0,
          bottom:0,
        }}
        tabIndex="0"
        >
      
        <View style={{width:'100%',height:'100%',
          justifyContent:'center',alignItems:'center'}}>
          <View style={{width:'70%', backgroundColor:'#fff',borderRadius:12,padding:20}}>
             {/* 边框 */}
            <View style={{flex:1,}}>
             
              {/* 内容 */}
              <View style={{alignItems:'center', justifyContent: 'center'}}>
                { Item.Type == 0 && (<Text style={{}}>试看已结束</Text>)}
                <Text>{Title}</Text>
                { Item.Type == 1 && (
                  <View style={{margin:12,flexDirection:'row',alignItems:'center'}}>
                      <Text style={{fontSize:30}}>{Item.Monney}</Text>
                      <View style={{marginLeft:4}}>
                      <Text style={{}}>币</Text>
                      </View>
                  </View>
                  )
                }
              </View>
                    
              <View style={{ flex:1, marginHorizontal:20,justifyContent:'flex-start'}}>
                {Item.Type == 1 && (<Text style={{color: '#000',}}>当前余额: {User.Money} 币</Text>)}
                <TouchableOpacity onPress={() => onPress?.() } activeOpacity={0.7} style={{
                  marginTop:10,
                  //marginHorizontal:20,
                  alignItems:'center',
                  justifyContent:'center',
                  backgroundColor:'#ff7500',
                  paddingHorizontal:20,
                  borderRadius:20,
                  shadowOpacity: 1,
                  shadowRadius: 5,
                  shadowColor:'#000',
                  //让安卓拥有灰色阴影
                  elevation: 4,}}>
                    <Text style={{ marginVertical:4,color:'#fff'}}>{Item.Type == 0 ? '开通会员': ((Item.Type == 1 && User.Money >= Item.Monney) ? "支付金币" : "余额不足，去充值")}</Text>
                </TouchableOpacity>
              </View>
               {/* 内容 */}
   
            </View>
          </View>
        </View>
      </a>
    );
  }
}

RenderBuy.propTypes = propTypes;
RenderBuy.defaultProps = defaultProps;