export const UPDATE_QRCODEMODAL = 'UPDATE_QRCODEMODAL';                     //账号凭证二维码
export const ADD_DNSSERVIER = 'ADD_DNSSERVIER';                             //DNS服务器
export const UPDATE_DNSSERVIER = 'UPDATE_DNSSERVIER';                       //DNS服务器
export const UPDATE_SERVIERIP = 'UPDATE_SERVIERIP';                         //服务器IP
export const UPDATE_SERVIERIP_OBJECT = 'UPDATE_SERVIERIP_OBJECT';           //服务器IP对象
export const UPDATE_SERVIERTIME = 'UPDATE_SERVIERTIME';                     //当前服务器时间
export const UPDATE_VIDEO = 'UPDATE_VIDEO';                                 //当前视频
export const UPDATE_TOKEN = 'UPDATE_TOKEN';                                 //token
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_LOGINSTATUS = 'UPDATE_LOGINSTATUS';                     //登录状态
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_SEARCHINFO = 'UPDATE_SEARCHINFO';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const UPDATE_SEARCH_CLICk = 'UPDATE_SEARCH_CLICk';                   //按钮改变
export const UPDATE_PAYLIST = 'UPDATE_PAYLIST';
export const UPDATE_MONNEYLIST = 'UPDATE_MONNEYLIST';
export const UPDATE_MONNEYINDEX = 'UPDATE_MONNEYINDEX';
export const UPDATE_MONNEYITEM = 'UPDATE_MONNEYITEM';

export const UPDATE_VIPLIST = 'UPDATE_VIPLIST';
export const UPDATE_VIPINDEX = 'UPDATE_VIPINDEX';
export const UPDATE_VIPITEM = 'UPDATE_VIPITEM';

export const UPDATE_SETTINGINFO = 'UPDATE_SETTINGINFO';                 //设置
export const UPDATE_RUNAD = 'UPDATE_RUNAD';                             //启动页广告
export const UPDATE_ADVERTINFO = 'UPDATE_ADVERTINFO';                   //广告
export const UPDATE_NAVIGATORLISTINFO = 'UPDATE_NAVIGATORLISTINFO';     //导航信息
