import React, {useCallback, useMemo, useState,useEffect,useContext,useImperativeHandle} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View,
  Platform,
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control'
import {updateVideo} from '@/Actions/userActions';
import {Recycler} from '@/Components/Recycler/Index';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';
//api
import {API_Video_Search} from '@/API/Index';
 //Redux
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

import {Cell} from '@/Components/Cell'
import { Store } from '@/Utils/store';
import { viewHeight } from '@/Utils/Index';

export default function ({navigation}:any){
  const dispatch = useDispatch();
  const {addSearchHistory}:any = useContext(Store);
  const search:any = useSelector((state:any) => state.user.search);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [IsEnd, setIsEnd] = useState(false);




  const fetchData = async () => {
    setLoading(true)
    try {
        const result:any = await API_Video_Search({VideoTitle:search.text,Page:page,Count:20})
        if(page==1){
            const Init:any =[{Type:"HEADER"}]
            setData(Init.concat(result));
        }else{
            setData(data.concat(result));
        }
        if(result.length <= 0){
          setIsEnd(true)
        }
        addSearchHistory(search.text)
    } catch (error) {
        setTimeout(() => {
            fetchData();
        }, 5000);
    }
    setLoading(false)
  };

  const renderHeader = () => {
    return (
      <View style={{flex:1, marginTop:10}}>
        {/* <Ad/> */}
      </View>
    );
  };


  useEffect(() => {
    if(search.type && search.type =="video")
    {
      fetchData()
    }else
    {
      setData([])
      setPage(1)
    }
  }, [page])

  useEffect(() => {
    if(search.type && search.type =="video")
    {
      fetchData()
    }else
    {
      setData([])
      setPage(1)
    }
},[search]);


  const Next =()=>{
    if(!IsEnd){
      setPage(page+1)
    }
  }



  const onPress = (data:any) => {
    dispatch(updateVideo(data))
    navigation.navigate('Player', {Data: data});
  }

  const items = useMemo(() => {
    return data;
  }, [page,data]);

  const reloadLines =() => {
    console.log(page,search)
    setRefreshing(true)
    setIsEnd(false)
    setData([])
    setPage(1)
    setTimeout(() => {
      fetchData()
      setRefreshing(false)
  }, 10);
    
  }

  const renderItem=({data})=>{
    return <Cell item={data} Click={onPress} />
  }

  const renderFooter = () => {
    //console.log('IsEnd:',IsEnd)
    if(IsEnd==true){
      return(
        <View style={{alignItems:'center',marginBottom:30}}>
          <Text style={{margin:10}}>到底部啦！</Text>
        </View>
      )
    }
      return (<Spinner size="lg" color="warning.500" />);
    };

  return (
    <View style={{
      //height:viewHeight
      backgroundColor: '#fff',
      paddingBottom: Platform.OS !== 'web' ? 10 : 0,
      // web端需要减去标题高度
      height: Platform.OS === 'web' ? viewHeight - 42 : viewHeight,
    }}>
      {
        items.length>0?
        <Recycler items={items} Next={Next} Click={onPress}
        numColumns={2}
        headerHeight={0}
        itemHeight={160}
        renderHeader={renderHeader}
        renderItem={renderItem}
        renderFooter={renderFooter}
        gap={6}
        scrollViewProps={{
          refreshControl: (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                reloadLines()
              }}
            />
          )
        }}
         />
        // :Loading == false ?
        // <Info navigation={navigation}/>
        :null
      }
    </View>
  );
}
