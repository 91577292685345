import React, {useCallback, useMemo, useState,useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, 
  Text, 
  View 
} from 'react-native';
import { RefreshControl } from 'react-native-web-refresh-control'
import {updateVideo} from '../../Actions/userActions';
import {Recycler} from '../../Components/Recycler/Index';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';
//api
import {API_Video_Tag} from '../../API/Index';
 //Redux
import {useDispatch} from 'react-redux';

import {Cell} from './Cell'

//import { Text,View } from '../../../components/Themed';

export default function ({navigation,route}:any){
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [IsEnd, setIsEnd] = useState(false);

  const fetchData = async () => {
    try {
        const result = await await API_Video_Tag({Area:'专题',Page:page,Count:20})
        //console.log(result)
        if(page==1){
            //const Init =[{Type:"HEADER"}]
            setData(result);
        }else{
            setData(data.concat(result));
        }
        //console.log(result.length)
        if(result.length <= 0){
          setIsEnd(true)
        }
    } catch (error) {
        setTimeout(() => {
            fetchData();
        }, 5000);
    }
  };

  const renderHeader = () => {
    return (
      <View style={{flex:1, marginTop:10}}>
        {/* <Ad/> */}
      </View>
    );
  };


  useEffect(() => {
    fetchData()
  }, [page])

  const Next =()=>{
    if(!IsEnd){
      setPage(page+1)
    }
    //console.log(page)
  }



  const onPress = (data:any) => {
    // dispatch(updateVideo(data))
    // navigation.navigate('Player', {Data: data});
    //navigation.navigate("VideoList",{Data:data})
    navigation.push("VideoList",{Data:data})

  }

  const items = useMemo(() => {
    return data;
  }, [page,data]);

  const reloadLines = React.useCallback(() => {
    setRefreshing(true)
    setIsEnd(false)
    setData([])
    setPage(1)
    setTimeout(() => {
      fetchData()
      setRefreshing(false)
  }, 10);
    
  }, [])

  const renderItem=({data})=>{
    return <Cell item={data} Click={onPress} />
  }

  const renderFooter = () => {
    //console.log('IsEnd:',IsEnd)
    if(IsEnd==true){
      return(
        <View style={{alignItems:'center',marginBottom:10}}>
          <Text style={{margin:10}}>到底部啦！</Text>
        </View>
      )
    }
      return (<Spinner size="lg" color="warning.500" />);
    };

  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      {
        items.length>0?
        <Recycler items={items} Next={Next} Click={onPress}
        numColumns={1}
        //headerHeight={200}
        itemHeight={200}
        //renderHeader={renderHeader}
        renderItem={renderItem}
        renderFooter={renderFooter}
        gap={6}
        scrollViewProps={{
          refreshControl: (
            <RefreshControl
              refreshing={refreshing}
              onRefresh={async () => {
                reloadLines()
              }}
            />
          )
        }}
         />
        :null
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: '#fff',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
});
