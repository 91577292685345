import React,{useState} from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useColorScheme } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { Box,Radio,Button,Center,HStack,VStack,Skeleton,CircularProgress,Spinner,Heading } from 'native-base';

import Main from "./Main";
import New from "./New";
import Monney from "./Monney";
import Other from "./Other";
import {useSelector} from 'react-redux';
import SearchBar from "./SearchBar";
import { StyleSheet, 
  Text, 
  View,
  Animated
} from 'react-native';

const Tab = createMaterialTopTabNavigator();

export default function TabNavigator({navigation}:any) {
  //console.log(a)
  const [IsDownload,setIsDownload] = useState(false)
  const colorScheme = useColorScheme();
  const Color ='#e0eee8'
  const List:any = useSelector((state:any) => state.user.NavigatorListInfo);

  const TabList = List.map(menuItem =>{
      return(
        <Tab.Screen key={menuItem.Area} name={menuItem.Area} initialParams={{ Area: menuItem.Area }} component={
          Other
        }
        options={({ navigation, route }) => {
          const state = navigation.getState();
          const isFocused = state.routes[state.index].key === route.key;
          return {
            tabBarLabelStyle: { 
              color: isFocused ? Color : Color,
              fontSize:isFocused?16:14,
              marginBottom:isFocused?4:0,
            }
          };
         }
        }
        />
      )
    }
    );
    
  //console.log(List)

  const renderDoadload =()=>{
    if(IsDownload)
    return(
      <View style={{position:'absolute',zIndex:100000, flex:1, left:0,right:0,top:0,bottom:0,justifyContent:'center',alignItems:'center'}}>
        <Spinner size="lg" color="warning.500" />
        <Text>正在下载中...</Text>
      </View>
    )
    
  }

  return (
   <SafeAreaView  edges={['top']} style={{flex:1,backgroundColor:'#ff7500'}}>
    <SearchBar navigation={navigation} setIsDownload={setIsDownload}/>
    <Tab.Navigator
      screenOptions={{
        lazy:true,                //赖加载
        lazyPreloadDistance:1,    //加载几页
        tabBarScrollEnabled:true, //导航滚动显示
        animationEnabled: false,//滚动显示滑动动画
        swipeEnabled:false,//手势切换导航
        tabBarItemStyle: { width: 'auto' },
        tabBarStyle: { 
          backgroundColor: '#ff7500' 
        },
        tabBarIndicatorStyle: {
          backgroundColor: Color, //下划线颜色
          marginBottom:8,
        },
      }} 
    >
      <Tab.Screen
        key={'Main'} 
        name={'推荐'}
        component={Main}
        options={({navigation,route}) => {
          const state = navigation.getState();
          const isFocused = state.routes[state.index].key === route.key;
          return {
            tabBarLabelStyle: { 
              color: isFocused ? Color : Color,
              fontSize:isFocused?16:14,
              marginBottom:isFocused?4:0,
            }
          };
        }}
      />

      <Tab.Screen
        key={'New'} 
        name={'最新'}
        component={New}
        options={({navigation,route}) => {
          const state = navigation.getState();
          const isFocused = state.routes[state.index].key === route.key;
          return {
            tabBarLabelStyle: { 
              color: isFocused ? Color : Color,
              fontSize:isFocused?16:14,
              marginBottom:isFocused?4:0,
            }
          };
        }}
      />

      <Tab.Screen
        key={'Monney'} 
        name={'金币'}
        component={Monney}
        options={({navigation,route}) => {
          const state = navigation.getState();
          const isFocused = state.routes[state.index].key === route.key;
          return {
            tabBarLabelStyle: { 
              color: isFocused ? Color : Color,
              fontSize:isFocused?16:14,
              marginBottom:isFocused?4:0,
            }
          };
        }}
      />

    {TabList}
    </Tab.Navigator>
      {renderDoadload()}
    </SafeAreaView>
  );
}

