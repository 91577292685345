import React, {useCallback, useMemo, useState,useEffect,useContext} from 'react';
import { StyleSheet, 
  Text, 
  View ,
  Platform,
  TouchableOpacity,
} from 'react-native';
import { Store } from '@/Utils/store';
 //Redux
import {useSelector} from 'react-redux';
import { viewHeight } from '@/Utils/Index';


export default function ({navigation,onChangeText,onPress}:any){
    const {searchInfo} = useSelector((state:any) => state.user);
    const {SearchList_Video,addSearchHistory,removeSearchAllHistory}:any = useContext(Store);


    const _onPress = (data) => {
        onPress(data)
    }

    const renderSearchInfo1 = (item , index) => {
        //console.log(item)
        return(
        <TouchableOpacity key={'renderSearchInfo1'+index} onPress={() =>_onPress(item)} style={ {borderRadius:6,backgroundColor:"darkgray",margin:5}}>
            <Text style={{marginHorizontal:20,marginVertical:4, fontSize:16,color:'#fff'}}>{item}</Text>
        </TouchableOpacity>
        )
    }
    const renderSearchInfo2 = (item , index) => {
        return(
        <TouchableOpacity key={'renderSearchInfo2'+index} onPress={() =>_onPress(item.Title)} style={ {borderRadius:6,backgroundColor:"darkgray",margin:5}}>
            <Text style={{marginHorizontal:20,marginVertical:4, fontSize:16,color:'#fff'}}>{item.Title}</Text>
        </TouchableOpacity>
        )
    }


    const renderSearchList_Video = SearchList_Video.map((item, index) => renderSearchInfo1(item , index))
    const rendersearchInfo = searchInfo.map((item, index) => renderSearchInfo2(item , index))

    return (
        <View style={{
            //height:viewHeight
            backgroundColor: '#fff',
            paddingBottom: Platform.OS !== 'web' ? 10 : 0,
            // web端需要减去标题高度
            height: Platform.OS === 'web' ? viewHeight - 42 : viewHeight,
        }}>
            {
                SearchList_Video.length > 0 ?
                <View>
                    <View style={{
                        flexDirection:'row',
                        justifyContent:'space-between',
                        //backgroundColor:'white',
                        alignItems:'center',
                        marginHorizontal:12,
                        marginTop:18
                    }}>
                        <Text>最近搜索</Text>

                    <TouchableOpacity onPress={() =>removeSearchAllHistory()} >
                        <Text>清空</Text>
                    </TouchableOpacity>

                        
                    </View>
                    <View style={ {flexDirection:'row',flexWrap:'wrap' ,marginTop:10}}>
                    {
                        renderSearchList_Video
                    }
                    </View>
                </View>
                :null
            }

            {
                searchInfo.length > 0 ?
                <View>
                    <View style={{
                        flexDirection:'row',
                        justifyContent:'space-between',
                        //backgroundColor:'white',
                        alignItems:'center',
                        marginHorizontal:12,
                        marginTop:18
                    }}>
                        <Text>热门搜索</Text>                    
                    </View>
                    <View style={ {flexDirection:'row',flexWrap:'wrap' ,marginTop:10}}>
                    {
                        rendersearchInfo
                    }
                    </View>
                </View>
                :null
            }


        </View>
    );
}

const styles = StyleSheet.create({
  container: {
    height:viewHeight,
    backgroundColor: '#fff',
  },
});
