import React ,{ useState, useEffect,useRef, useMemo,useContext } from 'react';
//import { Player,ControlBar,LoadingSpinner,BigPlayButton } from 'video-react';
import { AntDesign,MaterialIcons } from '@expo/vector-icons';
import { VideoPlayerProps,VideoPlayer } from '@videojs-player/react'
import * as Device from 'expo-device';
import 'video.js/dist/video-js.css'
import { VideoJsPlayer } from 'video.js'

import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import { showToast } from '@/Components/Toast';
import {updateUser} from '@/Actions/userActions';

//模块
import { Store } from '@/Utils/store';
import List from '../List'
import ConrrolTime from './Conrtol/ConrrolTime';
import BackButton from './VideoJsConrtol/BackButton';
import RenderIsVIP from './VideoJsConrtol/RenderIsVIP';
import RenderBuy from './Conrtol/RenderBuy';
import RenderError from './Conrtol/RenderError';
import moment from 'moment';

//api
import {API_Buy_BuyOK,API_User_GetUserData} from '@/API/Index';

//import 'video-react/dist/video-react.css'; // import css
import './video-react.css'; // import css
import { StyleSheet,PanResponder,Dimensions,View ,Text,TouchableOpacity} from 'react-native';
import styled, { createGlobalStyle } from 'styled-components';

import videojs from 'video.js';

videojs.registerComponent(
  'CurrentTimeDisplay',
  class extends videojs.getComponent('CurrentTimeDisplay') {
    constructor(player, options) {
      super(player, options);
      this.on(player, 'seeking', (e) => {
        this.updateContent(e);
      });
    }
  }
);

//RemainingTimeDisplay Component
videojs.registerComponent(
  'RemainingTimeDisplay',
  class extends videojs.getComponent('RemainingTimeDisplay') {
    constructor(player, options) {
      super(player, options);
      this.on(player, 'seeking', (e) => {
        this.updateContent(e);
      });
    }
  }
);

//SeekBar Component
videojs.registerComponent(
  'SeekBar',
  class extends videojs.getComponent('SeekBar') {
    getCurrentTime_() {
      //console.log(88888888)
      return this.player_.currentTime();
    }

    handleMouseMove(event, mouseDown = false) {
      super.handleMouseMove(event, mouseDown);
      this.update();
    }
  }
);

export default function App({width,height,navigation}:any) {

  const self:any = useRef(null);
  const dispatch = useDispatch();
  const data:any = useSelector((state:any) => state.user.VideoInfo);
  const User:any = useSelector<any>((state) => state.user.userdata);            //后台配置
  const ServerTime = useSelector((state:any) => state.user.ServerTime);         //服务器时间
  const settingInfo:any = useSelector<any>((state) => state.user.settingInfo);  //后台配置


  const {addPayList_Video,findPayList_Video} = useContext<any>(Store);
  const IsPay = findPayList_Video(data.Id)                                      // 是否购买

  
  const [showModal, setShowModal] = useState(false);                            // 弹出购买

  const [layoutWeight, setLayoutWeight] = useState(0)
  const [layoutHeight, setLayoutHeight] = useState(0)
  const [CurrentTime,setCurrentTime] = useState(0)
  const [totalDuration, setTotalDuration] = useState(0);                        //总长度
  const [IsError, setIsError] = useState(false);                                //是否错误

  const [touchOffset, setTouchOffset] = useState(0)
  const [isTouching, setIsTouching] = useState(false)
  const [isFullscreen,setisFullscreen] = useState(false)
  const releaseTouching = () => setIsTouching(false)
  const [clientWidth,setclientWidth] = useState(0)
  const [clientHeight,setclientHeight] = useState(0)
  const [isLeftOrRight, setisLeftOrRight] = useState(false)

  const [ControlBarBottom, setControlBarBottom] = useState(0)

  const panResponder = 
    PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => {
        //onTouchStart?.(evt);
        setTouchOffset(evt.nativeEvent.locationX)
        return true
      },
      //移动中
      onPanResponderMove:(evt, gestureState) => {
        const width = Dimensions.get('window').width;
        const offset = evt.nativeEvent.locationX - touchOffset;
        if (Math.abs(offset) > 2) {
            setIsTouching(true)
        }
        if (isTouching && !showModal) {
          onTouchUpdate?.(offset / width);
        }
        setTouchOffset(evt.nativeEvent.locationX);
      },

        //移动完成
      onPanResponderRelease: (evt, gestureState) => {
        releaseTouching()
      }
    }
  )

  const onTouchUpdate=(Num:any)=>{
    const Time = Math.min(
      totalDuration,
      Math.max(
          0,
          CurrentTime + Num * totalDuration
      )
    );
    //console.log(Time,CurrentTime,totalDuration,isTouching)
    setCurrentTime(Time)
    self.current.currentTime(Time)
    self.current.controlBar.progressControl.seekBar.update()//更新UI
  }




const Styles = createGlobalStyle`
  .video-js {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
  }
  .video-js .vjs-control-bar{
    background-color:rgba(0, 0, 0, .6);
    bottom:${ControlBarBottom}px;
    ${showModal? "display: none;" : null }
  }
  .vjs-control-bar{
    font-size: 1.4em;
    // line-height: 100.2;
    
  }


  /* 暂停时显示播放按钮 */
  .vjs-paused .vjs-big-play-button,
  .vjs-paused.vjs-has-started .vjs-big-play-button {
      display: block;
  }

  /* 播放按钮变圆形 */
  .video-js .vjs-big-play-button{

    margin-top:-1em;
    margin-left:-1.2em;
    font-size:2.5em;
    line-height:2.3em;
    height:2.5em;
    width:2.5em;
    -webkit-border-radius:2.5em;
    -moz-border-radius:2.5em;
    border-radius:2.5em;
    background-color:#73859f;
    background-color:rgba(115,133,159,.5);
    border-width:0.15em;
  }
`;


const BottomList = useMemo(()=>{
  return  (
    <List navigation={navigation}/>
  )
},[showModal])




const playerOptions =  {
  autoplay: true, // 自动播放，false
  bigPlayButton:true,//播放UI按钮
  controls: true, // 控制条
  preload: 'auto', // 预加载， 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
  loop: true, // 导致视频一结束就重新开始。
  language: 'zh-CN',
  fluid: true,/// 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
  //aspectRatio: '20:9', // 设置宽高比，fluid需为true，将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
  aspectRatio: '17:9', 
  //preferFullWindow:true, //设置ios播放器
  //width: width, // 视频框的宽度
  //height: height, // 视频框的高度
  errorDisplay:false,
  notSupportedMessage: '此视频暂时无法播放，请稍后再试',
  muted: false, // 是否静音
  //inactivityTimeout: true, //自动隐藏控制栏
  inactivityTimeout:10000,
  //playbackRates: [0.5, 1, 1.5, 2],  //播放速度
  playsinline: true,  //解决在iPhone中播放时自动全屏问题
  waiting:true,
  userActions: {
    click: true
    //doubleClick: myDoubleClickHandler
  },
  sources: [{
    src:data.VideoUrl,
  }], 
  poster:data.VideoCover,


  controlBar: {
    playToggle:true,//播放按钮
    remainingTimeDisplay:true,//剩余时间
    volumePanel:false,//音量
    chaptersButton:false, //倍数
    descriptionsButton:false,//倍数
    pictureInPictureToggle:false,//画中画按钮
    fullscreenToggle: true,//全屏按钮
    subsCapsButton:false,// 字幕按钮

  }
  
}

const onStateChange = (e) =>{
  //console.log("state:",e.currentTime,e.duration,isTouching,CurrentTime,totalDuration)
  //console.log(isTouching)
  if(isTouching==false){
    setCurrentTime(e.currentTime)
    setTotalDuration(e.duration)
  }
  //isFullscreen
  setisFullscreen(e.isFullscreen)
  
}
const onProgress =async (e)=>{
  //console.log(isTouching)
  console.log("onProgress:",e)
}

const onFullscreenChange =(e)=>{
  // console.log("full:",state)
  // //alert('123')
  // if(window.ReactNativeWebView){
  //   //alert('123')
  //   if(isFullscreen ==true){
  //     //setisFullscreen(false)
  //     //window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:false}}));
  //   }
     
  //   else{
  //     //setisFullscreen(true)
  //     window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:true,isFullscreen:isFullscreen}}));
  //   }  
  // }

}

useEffect(() => {

  if(window.ReactNativeWebView){
    if(isFullscreen){
      window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:true,isFullscreen:isFullscreen}}));
    }else{
      window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:false,isFullscreen:isFullscreen}}));
    }
  }

},[isFullscreen])

const onBack = (player:any) =>{

    if(window.ReactNativeWebView){
      if(isFullscreen){
        window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:false}}));
        //console.log(player)
        player.exitFullscreen()
      }else{
        navigation.goBack()
      }
    }else{
      navigation.goBack()
    }

}

function deviceOrientation() {
  switch(window.orientation) {
    case 90:
      setisLeftOrRight(true)
      break;
    case -90:
      setisLeftOrRight(true)
      break;
    default:
      setisLeftOrRight(false)
      break;
  }
}
useEffect(() => {
  if(self) {
    window.addEventListener('orientationchange', deviceOrientation);
    //window.ReactNativeWebView.postMessage(JSON.stringify({full:true}));
    //window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:true}}));
  }
},[self])

useEffect(() => {
  // if(isFullscreen){
  //   setControlBarBottom(10)
  // }else{
  //   // if(Device.osName=="iOS"){
  //   //   setControlBarBottom(30)
  //   // }else{
  //   //   setControlBarBottom(10)
  //   // }

  //   if(Device.osName=="iOS"){
  //     if(isLeftOrRight==true){
  //       setControlBarBottom(0)
  //     }else{
  //       setControlBarBottom(20)
  //     }
      
  //   }else{
  //     setControlBarBottom(0)
  //   }
   

  // }

  if(isLeftOrRight==true){
    setControlBarBottom(20)
  }else{
    setControlBarBottom(0)
  }
},[isLeftOrRight])

// const onPlayerResize=(e)=>{
//   if(window.orientation == 0){
//     if(Device.osName=="iOS"){
//       setControlBarBottom(0)
//     }
//   }else{
//     if(Device.osName=="iOS"){
//       setControlBarBottom(20)
//     }
//   }
// }

const IsVip = ()=>{
  if(User.VipTime)
  {
      const startTime= moment(ServerTime).format('YYYY-MM-DD HH:mm:ss')
      const endTime = moment(User.VipTime).format('YYYY-MM-DD HH:mm:ss')
      const code = moment(endTime).diff(moment(startTime), 'seconds')
      //console.log(startTime,endTime,code)
      if(code >= 0)
      {
          return true
      }
  }
  return false
}

//是否会员
const CheckVip = ()=>{
  if(data.Type == 0 && !User.IsVip){
    return true
  }
  
  if(IsVip() == false){
      return true
  }
}

//是否金币
const CheckMonney = ()=> {
    if(data.Type == 1 && !IsPay){
        return true
    }
}

useEffect(() => {
  if(CurrentTime >= settingInfo.TrialDuration){
    if(data.Type == 0 && CheckVip()){
      self.current.exitFullscreen()
      self.current.pause()
      setShowModal(true)
      return
    }
    if(data.Type == 1 && !IsPay && CheckMonney()){
      self.current.exitFullscreen()
      self.current.pause()
      setShowModal(true)
      return 
    }
  }
}, [CurrentTime])


useEffect(() => {
  if(IsPay && showModal){
    setShowModal(false)
    showToast("ヾ(ｏ･ω･)ﾉ 支付成功!")
    self.current.play()
  }
},[IsPay,showModal])


const ResUser= async ()=>{
  try {
    const ret = await API_User_GetUserData(
        {
            Id:User.Id,
        }
    )
    dispatch(updateUser(ret))
    addPayList_Video(data)
  }
  catch(e){
    showToast("╮(╯﹏╰）╭ 刷新支付失败!"+e)
  }
}

const Pay_Video = async () =>{
  try{
    const ret:any =  await API_Buy_BuyOK({UserId:User.Id,Idx:data.Id,Type:'video'})
    if(ret == true){
      ResUser()
    }
  }catch(e){
    showToast("╮(╯﹏╰）╭ 支付失败!"+e)
  }
}

const GotoPayCheck =(player:any)=>{
  if(window.ReactNativeWebView){
    if(isFullscreen){
      player.exitFullscreen()
      window.ReactNativeWebView.postMessage(JSON.stringify({full:{LANDSCAPE:false}}));
    }
  }
  data.Type==0 ? navigation.navigate("Pay",{title:"VIP"}) : navigation.navigate("Pay",{title:"Monney"})
}

const onPress = (player:any)=>{
  if (data.Type == 1 && User.Money >= data.Monney){
    return Pay_Video();
  }
  return GotoPayCheck(player);
}

const RendeConrtol =(player:any,state:any)=>{

  return(
    <>
       <RenderIsVIP order={5.2} data={data} isShow={(data.Type == 0 && !IsVip() )||(data.Type == 1 && !IsPay)} onPress={()=>
          {
            //alert(1)
            player.pause()
            setShowModal(true)
          }
           
            } order={1.1}/>
        <RenderBuy Item={data} User={User} isShow={showModal} onPress={()=>onPress(player)} order={11.2}/>
        <RanderError/>
       <BackButton onBack={()=>onBack(player)} order={11.4} />
    </>
  )
}

const handlePlayerMounted=(payload)=>{
  self.current = payload.player
  console.log(payload)
}
function RanderError(){
  if(IsError){
    return(
      <View style={[styles.controls.error]}>
          <MaterialIcons name="error-outline" size={28} color="red" />
          <Text style={{color:'red'}}> 播放失败 ，请稍后再试!</Text>
      </View>
   )
  }else{
    return null
  }
}
const onError=(e:any)=>{
  //self.current.errorDisplay.dispose();
  console.log("视频错误:",e)
  const error = self.current.error();
  console.log("视频错误2:",error)
  setIsError(true)
}



//console.log(state)
  return (  
    <View 
    {...panResponder.panHandlers} 
      style={{flex:1}}>
      
      
            <VideoPlayer 
              //id={playerId}
              //ref={self}
              options={playerOptions}
              onFullscreenChange={onFullscreenChange}
    
              onMounted={handlePlayerMounted}
              // onReady={onReady}
              // onpause={onEvent}
              // onPlay={onPlay}
              //onStateChange={onStateChange}
              //onProgress={onProgress}
              //onStateChange={setState}
              //onPlayerResize={onPlayerResize}
              onError={onError}

              >
                {({player, state})=>{
                  onStateChange(state)
                  return(RendeConrtol(player, state)
                  )
              }}
                
                
            </VideoPlayer>
          
       
        
          {/* <TouchableOpacity style={{backgroundColor:'#fff'}} onPress={()=>onFullscreenChange(null)}>
            <Text>全屏{isFullscreen}</Text>
          </TouchableOpacity> */}
          <Styles/>
      {BottomList}
    </View>
  );
};
const styles = {
  controls: StyleSheet.create(
      {
          control: {
              //每个控件之间间距
              //marginHorizontal:4
          },
          top: {
              position:'absolute',
              left:0,
              top:'10%',
              marginLeft:'4%',
              //margin:10
          },
          top_Back:{
              flex:1,
              backgroundColor:'rgba(0, 0, 0, 0.06)',
              
              borderRadius:16,
              width:32,
              height:32, 
              paddingLeft:4,
              justifyContent:'center',
              alignItems:'center'
              //borderRadius:30
          },
          error: {
              position:'absolute',
              left:0,
              right:0,
              top:0,
              bottom:0,
              flexDirection:'row',
              justifyContent:'center',
              alignItems:'center',
              backgroundColor:'#000'
              
          },
          playPause: {
              position: 'relative',
              zIndex: 0
          },
          timer: {
              fontSize: 12,
              color: 'white',
              marginLeft: 2,
              marginRight: 2,
              //textAlign:'center',
              width:44
          }
      }
  )
}